import NumberFormat from "react-number-format";
import "../../app/App.css";
import {Link} from 'react-router-dom';
import pdfIcon from "../../images/pdf.svg";
import fileIcon from "../../images/txt.svg";
import {useSelector} from "react-redux";
import SkeletonCell from "../components/SkeletonCell";
import DatePicker from "react-date-picker";
import moment from "moment";
import SearchSelectWrapper from "../components/SearchSelectWrapper";
import _ from "lodash";
import {store} from "../../app/stores/store";
import {PageControl} from "../enums/Enums";
import {addNewDefaultSelectorValue, applyEditorSort, computeOrderResultSortedKeyValue,} from "./constants";
import {showErrorToast} from "../components/Toast";
import axios from "axios";
import Select from "react-select";


export function getPrimitiveArray(array, key) {

    if (!array || array.length === 0) {
        return [];
    }
    return _.map(array, key);

}

export function getObjectiveArray(array) {
    if (!array || array.length === 0) {
        return [];
    }

    return _.map(array, (curValue) => ({label: curValue, value: curValue}));
}


/*****************Jurisdiction common function starts *************************************/
//addTaxRate
    // TODO: All methods like this need to be refactored - passing setters into utility methods is a code smell.
    //  On this one, the year/rate should be part of a state for a particular row instead of being shared for all rows
export const addTaxRate = (setTaxError, taxRates, setTaxRates, year, setYear, rate, setRate) => {
        setTaxError({})

        let taxRateData = {year, rate};
        let validationResult = validateTaxRateRecord(taxRateData, taxRates);

        setTaxError(validationResult);

        if (!validationResult.success) {
            return;
        }

        setTaxRates((oldItem) => {
            return [...oldItem, taxRateData];
        });

        setYear("");
        setRate("");
    }

export const validateCountyCertificate = (year, certifications) => {
    let validationResult = {
        year: validateTaxYear(year, certifications),
        success: null
    }

    validationResult.success = validationResult.year == null;
    return validationResult;
}

export const addCountyCertification = (countyId, certifications, setCertifications, editYear) => {
    let validationResult = validateCountyCertificate(editYear, certifications);
    if (!validationResult.success) {
        return validationResult;
    }

    let data = {countyId: countyId, taxYear: editYear};

    setCertifications([...certifications ?? [], data])

    return validationResult;
}

export const deleteCountyCertification = (index, setCertifications) => {
    setCertifications((oldItem) => {
        return oldItem.filter((item, i) => {
            return i !== index;
        })
    })
}

export const addJurisdictionCode = (i, {
    jurisdictionCodes, setJurisdictionCodes, setCodeError, type, setType, code, setCode
}) => {
    let JurisdictionData = {type, code};
    const error = {};
    if (code === "") {
        error.code = "*Code is required!";
        setCodeError(error);
    } else if (searchJurisdiction(type, code, jurisdictionCodes)) {
        error.code = "Combination already exist!";
        setCodeError(error);
    } else {
        setJurisdictionCodes((oldItem) => {
            return [...oldItem, JurisdictionData];
        });
        setType("APPRAISAL_DISTRICT");
        setCode("");
        setCodeError(error);
    }

}

export const validateTaxYear = (year, records, isRequired = true) => {


    if ((year === null || year === "") && isRequired === false) {
        return null;
    }


    if ((year === null || year === "") && isRequired === true) {
        return "Year is required!";
    }

    if (year.toString().startsWith("0")) {
        return "Year cannot start with zero!";
    }

    if (year.toString().includes(".")) {
        return "Year cannot contain decimal value!";
    }

    if (year < 1950) {
        return "Year must be greater than 1950!";
    }

    if (year > 2050) {
        return "Year must be smaller than 2050!";
    }

    if (containsRecordForYear(year, records)) {
        return "A record for " + year + " already exists!";
    }

    return null;
}

export const validateTaxRate = (rate) => {
    if (!rate) {
        return "Rate is required!";
    }

    if (rate > 100) {
        return "Rate cannot be greater than 100";
    }

    return null;
}

export const validateTaxRateRecord = (values, taxRates) => {
    const validationResult = {
        year: validateTaxYear(values.year, taxRates),
        rate: validateTaxRate(values.rate),
        success: null
    };

    validationResult.success = validationResult.year == null && validationResult.rate == null;
    return validationResult;
};


//validatePassword
export const validatePassword = (password, confirmPassword) => {
    let regexForUppercase = new RegExp("(?=.*[A-Z])");
    let regexForLowercase = new RegExp("(?=.*[a-z])");
    let regexForNum = new RegExp(/\d/);
    let regexForSpecialChar = new RegExp("(?=.*\\W)");
    let regexForLength = new RegExp(/^.{12,50}$/)

    let error = {};

    if (!regexForUppercase.test(password)) {
        error.uppercaseChar = "Contains at least one uppercase letter (A - Z)";
    } else {
        error.uppercaseChar = "Contains at least one uppercase letter (A - Z)";
        error.uppercaseCheck = "true";
    }

    if (!regexForLowercase.test(password)) {
        error.lowercaseChar = "Contains at least one lowercase letter (a - z)";
    } else {
        error.lowercaseChar = "Contains at least one lowercase letter (a - z)";
        error.lowercaseCheck = "true";
    }


    if (!regexForNum.test(password)) {
        error.number = "Contains 1 number (0 - 9)";
    } else {
        error.number = "Contains 1 number (0 - 9)";
        error.numberCheck = "true";
    }

    if (!regexForSpecialChar.test(password)) {
        error.specialChar = "Contains 1 special character (e.g. !, #, $, %)";
    } else {
        error.specialChar = "Contains 1 special character (e.g. !, #, $, %)";
        error.specialCharCheck = "true";
    }

    if (!regexForLength.test(password)) {
        error.length = "Minimum length 12";
    } else {
        error.length = "Minimum length 12";
        error.lengthCheck = "true";
    }

    if (password !== confirmPassword && confirmPassword !== null && confirmPassword !== "") {
        error.match = "Password and Confirm Password Doesn't Match!";
    } else {
        error.match = "";
    }

    error.isValidSubmission = regexForUppercase.test(password) && regexForLowercase.test(password) && regexForLength.test(password) && regexForSpecialChar.test(password) && regexForNum.test(password);

    if (error.isValidSubmission) {
        delete error.uppercaseChar;
        delete error.lowercaseChar;
        delete error.number;
        delete error.specialChar;
        delete error.length;

        delete error.uppercaseCheck;
        delete error.lowercaseCheck;
        delete error.numberCheck;
        delete error.specialCharCheck;
        delete error.lengthCheck;
    }

    return error;
};


//common method to check data exist in array or not
export function containsRecordForYear(nameKey, records) {
    if (!records || records.length === 0) {
        return false;
    }

    for (const element of records) {
        if (!element) continue;

        if (element.toString() === nameKey.toString() ||
            element.year?.toString() === nameKey.toString() ||
            element.taxYear?.toString() === nameKey.toString()) {
            return true;
        }
    }

    return false;
}

export function searchJurisdiction(type, code, myArray) {
    for (const element of myArray) {
        if (element.type.toUpperCase() === type.toUpperCase() && element.code.toUpperCase() === code.toUpperCase()) {
            return true;
        }
    }
}

//delete tax by id
export const deleteTaxRate = (id, {setTaxRates}) => {
    setTaxRates((oldItem) => {
        return oldItem.filter((eL, index) => {
            return index !== id;
        })
    })
}


export const deleteJurisdictionCodes = (id, {setJurisdictionCodes}) => {
    setJurisdictionCodes((oldItem) => {
        return oldItem.filter((eL, index) => {
            return index !== id;
        })
    })
}

export function getCurrentTaxYear() {
    return new Date().getMonth() < 9 ? new Date().getFullYear() - 1 : new Date().getFullYear()
}

export function doesCurrentTaxYearMatchCurrentYear() {
    return getCurrentTaxYear() === new Date().getFullYear();
}

/*****************Jurisdiction common function ends *******************************/

// Returns the given phoneNumber formatted as (###) ###-####. Passing a displayType of "input" will render it as an input
export function formatValueAsPhoneNumber(phoneNumber, displayType = "text") {
    return phoneNumber ? <>
        <NumberFormat value={phoneNumber} format="(###) ###-####" displayType={displayType}
                      allowEmptyFormatting
                      mask="_"/>
    </> : null;
}

// Returns the given value displayed as money
export function formatValueAsMoney(value, displayType = "text") {
    return value ? <>
        <NumberFormat decimalScale={2}
                      decimalSeparator="."
                      fixedDecimalScale
                      prefix={"$"}
                      thousandSeparator={true}
                      value={value}
                      displayType={displayType}
        />
    </> : null;
}

// Returns a clickable <a> that adds the given path to the history, and renders the given text
export const createClickableLink = (path, text, userSetting, isIcon = false, className = "") => {
    let target = '_self'
    if (userSetting?.items) {
        const linkOpeningStyleObject = userSetting?.items.find((item) => item.key === 'linkOpeningStyle');
        if (linkOpeningStyleObject && linkOpeningStyleObject.value === 'NEW TAB') {
            target = '_blank';
        }
    }
    return (
        <>
            {isIcon ? (
                <Link
                    className={className}
                    to={`${path}`}
                    target={target}
                    rel={target === '_blank' ? 'noopener noreferrer' : ''}
                />
            ) : (
                <Link
                    className="column-link active cursor"
                    to={`${path}`}
                    target={target}
                    rel={target === '_blank' ? 'noopener noreferrer' : ''}
                >
                    {text}
                </Link>
            )}
        </>
    );
}

// Returns formatted date
export const createFormattedDate = (text, dateFormat = "MM/DD/YY h:mm a", titleDateFormat = dateFormat) => {
    let formattedDate = formatValueAsDate(text, dateFormat);
    let titleFormattedDate = dateFormat !== titleDateFormat ? formatValueAsDate(text, titleDateFormat) : formattedDate;

    return (<span title={titleFormattedDate} className="formatted_date">{formattedDate} </span>)
}

export const getTruncatedDisplay = (text, length, includeEllipsis = true, includeTitle = true) => {
    if (!text || !length) {
        return null;
    }

    let truncatedText = text.substring(0, length);
    if (includeEllipsis && text.length > length) {
        truncatedText += '...'
    }

    return <>
        <span title={includeTitle ? text : ''}>
            {truncatedText}
        </span>
    </>
}

export const getShortProductName = (productType) => {

    if (productType.includes("TAX_") === true) {
        return "Tax";
    } else if (productType.includes("HOA_FULL") === true) {
        return "HOA F.";
    } else if (productType.includes("HOA_CONTACT") === true) {
        return "HOA C.";
    } else {
        return productType;
    }

}

export const getProductTypeForBarChartLabel = (label, productTypeEnum) => {

    switch (label) {
        case "Tax":
        case "Tax Update":
            return getSelectedValueFromEnum(productTypeEnum, "TAX_");

        case "NTP Update":
            return ["NTP"];

        case "HOA":
        case "HOA Update":
            return getSelectedValueFromEnum(productTypeEnum, "HOA_");

        default:
            return [label];
    }
}

export const getSelectedValueFromEnum = (enumList, includeValue) => {
    let selected = [];

    enumList !== undefined && enumList.length > 0 && enumList.forEach(el => {
        if (el.constant.includes(includeValue)) {
            selected.push(el.constant);
        }
    })

    return selected;
}

// Returns a clickable <a> for with window refresh.
export const createClickableLinkWindowRefresh = (path, text) => {
    return (<>
        <div className="cursor" onClick={() => window.location.href = path}>
            <a href={path} onClick={() => window.location.href = path}
               className="column-link active cursor">{text}
            </a>
        </div>

    </>)
}

// TODO: Maybe we should just change this to a <SearchButton> component?
export const createSearchButton = () => {
    return (<>
        <div className="table_head d-flex">
            <ul className="btn-radius cursor grid_search">
                <li className="add_users grid_search" onClick={() => showHideSearchForm()}>
                        <span className=" cursor" aria-hidden="true">
                            <i className="fa fa-search"/>
                        </span>
                </li>
            </ul>
        </div>
    </>)
}

// Returns a clickable button that navigates the user to the given path, and displays the given title. Button is a '+', intended for add operations.
export const createAddButton = (path, title) => {
    return (
        <>
            <Link to={path} title={title}>
                <div className="table_head d-flex">
                    <ul className="btn-radius cursor">
                        <li className="add_users">
                            <i className="fa fa-plus" aria-hidden="true"
                               style={{color: '#fff', textDecoration: 'none'}}/>
                        </li>
                    </ul>
                </div>
            </Link>
        </>
    );
};

// Returns a clickable button  for system user settings
export const createUserSettingsButton = (title, state, setState, data) => {
    return (<>
        <div className="table_head d-flex">
            <ul className={!data || data.length === 0 ? "btn-radius cursor user_settings_model_without_pagination" : "btn-radius cursor user_settings_model"}>
                <li className="add_users grid_search"
                    onClick={(e) => setState(!state)} title={title}>
                    <i className="fas fa-cog sys-color" aria-hidden="true"/>
                </li>
            </ul>
        </div>
    </>)
}


export function showHideSearchForm() {
    sessionStorage.setItem("searchForm", "false");
    let x = document.getElementById("grid");
    if (x !== null) {
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
}

export function searchBarWithButton() {
    let x = document.getElementById("grid");
    if (x !== null) {
        x.style.display = "block";
    }
}

export function alphabeticalOrder(arr) {
    return arr.sort((a, b) => a < b ? -1 : 1)
}

export function exemptionLabel() {
    return (<>
        <div className="form-group row"><label
            className="col-form-label col-sm-4 non-required label_bold">Exemptions</label>
            <div className="col-sm-8">
                <div className="row">
                    <div className="col-sm-5">
                        <label
                            className="col-form-label non-required label_bold">Type</label>
                    </div>
                    <div className="col-sm-3">
                        <label
                            className="col-form-label label_bold">Flat
                            Amount</label>
                    </div>
                    <div className="col-sm-3">
                        <label
                            className="col-form-label label_bold">%
                            Amount</label>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export function exemptionField(enums, name, type, setType, flatAmount, setFlatAmount, perAmount, setPerAmount, formType = null, setEditable = null, isReadOnly = false) {
    return (<>
        <div className="form-group row">
            <label className="col-form-label col-sm-4 non-required">{name}</label>

            <div className="col-sm-8">
                <div className="row">
                    <div className="col-sm-5">


                        {CommonSelectDropDownTag(Array.isArray(enums) && enums, "value", "constant", "Select Exemption...", null, false, type, setType, false, isReadOnly === true, null, (value) => {
                            if (formType != null && formType === "Update") {
                                setEditable(true);
                            }
                            if (value === 'NONE') {
                                setFlatAmount("");
                                setPerAmount("");
                            } else if (value === 'FLAT') {
                                setPerAmount("");
                            } else if (value === 'PERCENTAGE') {
                                setFlatAmount("");
                            }
                            setType(value);
                        })}


                    </div>
                    <div className="col-sm-3">

                        <NumberFormat placeholder='...'
                                      className="form-control"
                                      isNumericString={flatAmount !== null || flatAmount !== ''}
                                      value={flatAmount}
                                      prefix={flatAmount !== null && flatAmount !== '' ? "$" : ''}
                                      onKeyDown={event => {
                                          if (event.key === "." || event.key === "-") {
                                              event.preventDefault();
                                          }
                                      }}
                                      onChange={(e) => {
                                          if (formType != null && formType === "Update") {
                                              setEditable(true);
                                          }
                                          setFlatAmount(e.target.value.replace('$', '').replaceAll(',', ''))
                                      }}
                                      thousandSeparator={true}
                                      readOnly={type === 'NONE' || type === 'PERCENTAGE' || isReadOnly}

                        />

                    </div>
                    <div className="col-sm-2">
                        <NumberFormat placeholder='...'
                                      className="form-control"
                                      isNumericString={perAmount !== null || perAmount !== ''}
                                      value={isInt(perAmount) ? perAmount : perAmount * 100}
                                      onKeyDown={event => {
                                          if (event.key === "." || event.key === "-") {
                                              event.preventDefault();
                                          }
                                      }}
                                      onChange={(e) => {
                                          if (formType != null && formType === "Update") {
                                              setEditable(true);
                                          }
                                          e.target.value = e.target.value > 100 ? '' : e.target.value
                                          setPerAmount(e.target.value)
                                      }}
                                      readOnly={type === 'NONE' || type === 'FLAT' || isReadOnly}

                        />
                    </div>
                    {isReadOnly === false && <div className="col-sm-2 mt-1" title="Clear Exemption">
                        <i className="fa fa-solid fa-rotate-left m-md-0 cursor"
                           data-backdrop="static"
                           onClick={() => resetExemptionField(setType, setFlatAmount, setPerAmount)}
                        />
                    </div>}

                </div>
            </div>

        </div>
    </>)
}

function isInt(n) {
    return n % 1 === 0;
}

export function resetExemptionField(setType, setFlatAmount, setPerAmount) {
    setType("NONE");
    setFlatAmount('');
    setPerAmount('');
}


export function filterPermission(permissions, curPermission, val) {
    return permissions.filter((el) => {
        return el.name === curPermission + val;
    })[0];
}

export function isUserHaveAccess(possessedPermissions, requiredPermissions, mustMatchAll = false) {
    if (!requiredPermissions || requiredPermissions.length === 0) {
        return true;
    }

    if (!possessedPermissions || !Array.isArray(possessedPermissions) || possessedPermissions.length === 0) {
        return false;
    }

    return mustMatchAll ?
        _.intersection(possessedPermissions, requiredPermissions).length === requiredPermissions.length :
        !_.isEmpty(_.intersection(possessedPermissions, requiredPermissions));
}

// Get an icon that displays a boolean's value status and allows you to click the icon to do something
// TODO: Change this to be generic, this is a mess... DO NOT PASS SETTERS INTO UTIL FUNCTIONS!
export const getBooleanDisplayIconWithConfirmation = (value, trueTitle, falseTitle, setShowConfirmation, setUserObj, props) => {
    return (<>
        <div>

            {value ? <i title={value ? trueTitle : falseTitle}
                        className="fa fa-check-circle enabled-data align--center enabled"
                />

                : <i title={value ? trueTitle : falseTitle}
                     className="fa fa-times-circle disabled-data align--center enabled cursor"
                     onClick={(e) => {
                         e.preventDefault();
                         setUserObj(props.row.original.id);
                         setShowConfirmation(true);


                     }}
                />

            }
        </div>
    </>)
}

// Get an icon that displays a boolean's value inTraining with customer level
export const getBooleanWithDisplayIcon = (value, trueTitle, falseTitle, customerLevel) => {
    let iconColor = "gray"; // Default color is gray

    if (customerLevel === "TENANT") {
        // If user's customer level is TENANT, set icon color based on value
        iconColor = value ? "red" : "green";
    }

    return (
        <>
            <div>
                <i
                    title={value ? trueTitle : falseTitle}
                    className={value ? `fa-solid fa-graduation-cap align--center enabled ` : `fa-solid fa-graduation-cap align--center enabled`}
                    style={{"color": iconColor}}
                />
            </div>
        </>
    );
};


// Get an icon that displays a boolean's value status
export const getBooleanDisplayIcon = (value, trueTitle, falseTitle) => {
    return (<>

        <div>
            <i title={value ? trueTitle : falseTitle}
               className={value ? "fa fa-check-circle enabled-data align--center enabled default-cursor" : "fa fa-times-circle disabled-data align--center enabled default-cursor"}
            />
        </div>
    </>)
}

export const dateTimeOffset = (dateValue, dateFormat = "MM/DD/YY h:mm a") => {
    if (dateValue === null) {
        return null;
    }

    let date = new Date(dateValue.toString()).setMinutes(new Date(dateValue.toString()).getMinutes() + new Date(dateValue.toString()).getTimezoneOffset());

    return formatValueAsDate(new Date(date), dateFormat)


}

export const notificationSorting = (notificationList, sortingKeyArrayInOrder, order) => {
    return _.orderBy(notificationList, sortingKeyArrayInOrder, order);
}

// Format a string as a moment date using given dateFormat
export const formatValueAsDate = (dateValue, dateFormat = "MM/DD/YY h:mm a") => {
    if (dateValue === null || "") {
        return null;
    }

    return moment(dateValue).format(dateFormat).toUpperCase();
}

export function allPermissionChecked(permissions, curPermission) {

    let tempPer = permissions.filter((el) => {
        return el.name.split(' ')[0] === curPermission;
    });

    let checked = [];
    for (let j = 0; j < tempPer.length; j++) {
        checked.push(tempPer[j]?.isChecked || false);
    }
    return checked.includes(false);
}

export function allPermissionCheckedOnColumns(permissions, curPermission) {

    let tempPer = permissions.filter((el) => {
        return el.name.split(' ')[1] === curPermission;
    });

    let checked = [];
    for (let j = 0; j < tempPer.length; j++) {
        checked.push(tempPer[j]?.isChecked || false);
    }

    return checked.includes(false);
}

// Creates a two-dimensional array from the given array, with each inner array being of the chunkSize specified.
// The final inner array can be a smaller size.
export function splitArrayIntoChunks(value, chunkSize) {
    return value.reduce((all, one, i) => {
        const chunkIndex = Math.floor(i / chunkSize);
        all[chunkIndex] = [].concat((all[chunkIndex] || []), one);

        return all;
    }, [])
}

//create xlsx file for each api request
export function resourceToFile(response, fileName, fileExt = '.csv') {
    let instance = new Date();
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName + "_" + (instance.getMonth() + 1) + "-" + instance.getDate() + "-" + instance.getFullYear() + "_" + instance.getTime() + fileExt);
    document.body.appendChild(link);
    link.click();
}


export function resourceToFileForReport(response, fileName, fileExt = '.csv') {
    if (response['errorCode'] !== 200 || !response['id']) {
        return showErrorToast(response['message']);
    }

    let instance = new Date();
    const link = document.createElement('a');
    link.href = "/ajax/download-report-file/" + response['id'];
    link.setAttribute('download', fileName + "_" + (instance.getMonth() + 1) + "-" + instance.getDate() + "-" + instance.getFullYear() + "_" + instance.getTime() + fileExt);
    document.body.appendChild(link);
    link.click();
}

export function handleExportError(error) {
    if (error.response) {
        if (error.response.status === 504) {
            showErrorToast("The query is taking too much time to process. Consider applying filters to reduce the data size and try again.");
        } else {
            showErrorToast("An error occurred while exporting the data.");
        }
    } else {
        showErrorToast("An error occurred while exporting the data.");
    }
}


//show or hide subdivision detailPage
export function showHideSubdivisionGrid() {
    let div = document.getElementById("show_hide_subDivision_grid");
    let divShowMore = document.getElementById("show_hide_subDivision_grid_showMore");
    let divShowLess = document.getElementById("show_hide_subDivision_grid_showLess");
    if (div.style.display === "none") {
        div.style.display = "block";
        divShowMore.style.display = "none";
        divShowLess.style.display = "block"
    } else {
        div.style.display = "none";
        divShowMore.style.display = "block";
        divShowLess.style.display = "none"
    }
}

export function formatByte(bytes, precision) {
    let kilobyte = 1024;
    let megabyte = kilobyte * 1024;
    let gigabyte = megabyte * 1024;
    let terabyte = gigabyte * 1024;

    if (bytes === null || bytes === "") {
        return "-";
    }

    if ((bytes >= 0) && (bytes < kilobyte)) {
        return bytes + ' B';

    } else if ((bytes >= kilobyte) && (bytes < megabyte)) {
        return (bytes / kilobyte).toFixed(precision) + ' KB';

    } else if ((bytes >= megabyte) && (bytes < gigabyte)) {
        return (bytes / megabyte).toFixed(precision) + ' MB';

    } else if ((bytes >= gigabyte) && (bytes < terabyte)) {
        return (bytes / gigabyte).toFixed(precision) + ' GB';

    } else if (bytes >= terabyte) {
        return (bytes / terabyte).toFixed(precision) + ' TB';

    } else {
        return bytes + ' B';
    }
}

export function computeBackgroundColor(product) {
    switch (product) {
        case 'Tax':
            return '#B44646';

        case 'Tax Update':
            return '#c05f5f';

        case 'HOA':
            return '#FFB631';

        case 'HOA Update':
            return '#e5b65f';

        case 'NTP':
            return '#4682B4';

        case 'NTP Update':
            return '#5d92be';

        default:
            return '#6855ff';
    }
}

export function computeDefaultStartDateForOrderCountPicker(period) {
    const defaultDate = new Date();

    switch (period) {
        case 'WEEKS':
            return defaultDate.setDate(defaultDate.getDate() - 30);

        case 'MONTHS':
            return defaultDate.setFullYear(defaultDate.getFullYear() - 1);

        default:
            return new Date(defaultDate.getFullYear(), defaultDate.getMonth(), 1, 0, 0, 0);
    }
}

export function computeDefaultEndDateForOrderCountPicker(period) {
    const defaultDate = new Date();

    switch (period) {
        case 'DAYS':
            return new Date(defaultDate.getFullYear(), defaultDate.getMonth() + 1, 0, 23, 59, 59);
        default:
            return defaultDate;
    }
}


export function computeDefaultStartDateForPickerAutoComplete(period) {
    const defaultDate = new Date();
    const lastDayOfPreviousMonth = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), 0);
    const givenDate = new Date(lastDayOfPreviousMonth);

    switch (period) {
        case 'MONTHS': {
            return new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1);
        }

        default:
            return new Date(givenDate.getFullYear(), givenDate.getMonth(), 1);
    }
}

export function computeDefaultEndDateForPickerAutoComplete() {
    const currentDate = new Date();
    return new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

}

export function computeLabelAndCount(period, dailyCounts, productTypes, onlyUpdates, onlyLabels = false) {
    let valueToReturn = [];

    dailyCounts.length > 0 &&
    dailyCounts.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        .map(cur => {
            if (onlyLabels) {

                computeBarChartLabels(cur, valueToReturn, period);

            } else {

                let productCountItems = cur.productCountItems.filter(p => productTypes.includes(p.productType) && p.reorder === onlyUpdates);
                let total = productCountItems.map(p => p.count).reduce((a, b) => a + b, 0);

                valueToReturn.push(total);
            }
        });

    return valueToReturn;
}

export function computeAutomatedOrderPercentLabelAndCount(period, dailyCounts, productTypes, onlyUpdates, onlyLabels = false) {
    let valueToReturn = [];

    dailyCounts.length > 0 &&
    dailyCounts.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        .map(cur => {
            if (onlyLabels) {

                computeBarChartLabels(cur, valueToReturn, period);

            } else {

                let productCountItems = cur?.productPercentItems.filter(p => productTypes.includes(p.productType) && p.reorder === onlyUpdates);
                let total = productCountItems.map(p => p?.percentCount).reduce((a, b) => a + b, 0);

                valueToReturn.push(total);
            }
        });

    return valueToReturn;
}

export function computeBarChartLabels(cur, valueToReturn, period) {

    const dateString = cur.zonedDateTime.toString();

    const firstDay = dateString.substring(0, 7); // Get "YYYY-MM" or "YYYY-MM-DD"
    // const day = dateString.substring(8, 10); // Get "DD" if available

    if (period === 'MONTHS') {
        valueToReturn.push(formatMonthYear(firstDay));
    } else if (period === 'DAYS') {
        valueToReturn.push(formatMonthDay(dateString));
    } else {
        const seventhDay = formatMonthDay(addDays(dateString, 7));
        valueToReturn.push(`${formatMonthDay(dateString)} - ${seventhDay}`);

    }
}

function formatMonthYear(date) {
    const [year, month] = date.split('-');
    return `${getMonthName(month)} ${year}`;
}

function formatMonthDay(date) {
    const [year, month, day] = date.split('-');
    return `${getMonthName(month)} ${parseInt(day)}${getDaySuffix(parseInt(day))}`;
}

function addDays(dateString, daysToAdd) {
    const date = new Date(dateString);
    date.setDate(date.getDate() + daysToAdd);
    return date.toISOString().split('T')[0];
}

function getMonthName(month) {
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return months[parseInt(month) - 1];
}

function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
        return 'th';
    }
    const lastDigit = day % 10;
    switch (lastDigit) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
}


/**************Data table search form array all common component code and grid starts here***********/


//creating query for dashboard by merging query .
export function createMergedDashboardQuery(objectToMerged = null) {

    let defaultQuery = {"pageNumber": 0, "pageSize": 15, "sort": "creationDate", "sortOrder": "DESC"}
    if (objectToMerged === null) {
        return defaultQuery;
    }
    return {...defaultQuery, ...objectToMerged};

}


export function defaultDateParamIfNotProvided(queryObj) {

    const creationDateFromKey = 'createdDateFrom';
    const today = new Date();
    const oneMonthAgo = new Date();
    const creationDateFromValue = moment(oneMonthAgo.setMonth(today.getMonth() - 1)).format("yyyy-MM-DD HH:mm:ss").toUpperCase();

    const creationDateToKey = 'createdDateTo';
    const creationDateToValue = moment().format("yyyy-MM-DD HH:mm:ss").toUpperCase();


    queryObj[creationDateFromKey] === undefined && _.setWith(queryObj, creationDateFromKey, creationDateFromValue, (existingValue, key, obj) => {
        if (_.has(obj, key)) {
            return existingValue;
        } else {
            return creationDateFromValue;
        }

    });

    queryObj[creationDateToKey] === undefined && _.setWith(queryObj, creationDateToKey, creationDateToValue, (existingValue, key, obj) => {
        if (_.has(obj, key)) {
            return existingValue;
        } else {
            return creationDateToValue;
        }

    });


    return queryObj;

}

export const computeArePropertiesSame = (listToCompare, comparedList, keysToCompare) => {
    let listToCompareCloned = JSON.parse(JSON.stringify(listToCompare)).filter(cur => cur.propertyRequestSource !== 'CERT_ACCOUNT');
    let listToBeComparedCloned = JSON.parse(JSON.stringify(comparedList)).filter(cur => cur.propertyRequestSource !== 'CERT_ACCOUNT');

    if (listToCompareCloned.length !== listToBeComparedCloned.length) {
        return false;
    }

    function isBlankHandler(value1, value2) {
        if ((value1 === null || _.trim(value1) === '') && (value2 === null || _.trim(value2) === '')) {
            return true;
        }
    }

    const isEqual = listToCompareCloned.every((objToCompare, index) =>
        _.isEqualWith(
            _.pick(objToCompare, keysToCompare),
            _.pick(listToBeComparedCloned[index], keysToCompare),
            (obj1, obj2) => {
                const trimmedObj1 = _.mapValues(obj1, (value) => (_.isString(value) ? _.trim(value) : value));
                const trimmedObj2 = _.mapValues(obj2, (value) => (_.isString(value) ? _.trim(value) : value));

                return _.isEqualWith(trimmedObj1, trimmedObj2, isBlankHandler);

            }
        )
    );
    return isEqual;
};


//persisting the same order in table as we have in grid file.
export function persistGridOrder(userSelectedColumns, allColumns) {

    return userSelectedColumns.sort((a, b) => {
        return allColumns.findIndex(obj => obj.Header === a.Header) - allColumns.findIndex(obj => obj.Header === b.Header);
    })
}


// TODO: MOVE CONTAINED THINGS TO NEW UTILS
export function SearchSelectWithIndeterminateBoolean(placeholderText, accessor, trueLabel = "True", falseLabel = "False",quickFilters = null, isConstantData = true) {
    return SearchSelectWithList([
        {label: trueLabel, value: true},
        {label: falseLabel, value: false}
    ], "label", "value", placeholderText, false, accessor, null, quickFilters, isConstantData);
}


export function SearchSelectWithEnum(enumMap, placeholderText, isMulti, accessor, defaultValue = null, quickFilters = null, isConstantData = false) {
    return SearchSelectWithList(enumMap, "value", "constant", placeholderText, isMulti, accessor, defaultValue, quickFilters, isConstantData);
}

// Label/value can be either a Function or a string
// This is useful when the label/value are built based on other things, like 'CustomerName (CustomerNumber)'
export function SearchSelectWithList(itemMap, label, value, placeholderText, isMulti, accessor, defaultValue = null, quickFilters = null, isConstantData = false) {
    const options = itemMap && Array.isArray(itemMap) && itemMap?.map(item => {
        return {
            value: value instanceof Function ? value(item) : item[value],
            label: label instanceof Function ? label(item) : item[label]
        }
    });

    return <SearchSelectWrapper
        classNamePrefix="select"
        accessor={accessor}
        isConstantData={isConstantData}
        isMulti={isMulti}
        menuShouldBlockScroll
        menuShouldScrollIntoView
        tabSelectsValue
        quickFilters={quickFilters}

        options={options}
        placeholder={placeholderText}
        defaultValue={defaultValue}
    />;
}


export function getObjectiveDataWithMatchedData(isMulti, options, name, valueToReturn) {

    if (name === null || name === "") {
        return null;
    }

    if (isMulti && Array.isArray(options) && options.length > 0 && Array.isArray(name)) {
        return options.filter(cur => name.indexOf(cur[valueToReturn]) >= 0).length === 0 ? null : options.filter(cur => name.indexOf(cur[valueToReturn]) >= 0);
    }

    if (!isMulti && Array.isArray(options) && options.length > 0 && name !== null) {
        return options.find(cur => cur[valueToReturn] === name);
    }

}

export function getNotificationIcon(type) {
    switch (type) {
        case 'SCRAPER':
            return 'fa fa-globe cursor icon_size';
        case 'ASSIGNED':
            return 'fa fa-briefcase cursor icon_size';
        case 'MESSAGE':
            return 'fa fa-message icon_size';
        case 'MAINTENANCE':
            return 'fa fa-wrench icon_size';
        case 'BOND':
            return 'cursor fa fa-tasks icon_size';
        case 'AVANTA':
            return 'cursor fa fa-tasks-alt icon_size';
        default:
            return 'fa fa-clock cursor icon_size';
    }
}

// startDate and endDate where each is a moment(). Examples of how to get that: "moment(new Date())" or "moment()" or "moment("2023-01-10 14:55")"
export function getHumanReadableDateDifference(startDate, endDate = moment()) {
    let duration = moment.duration(endDate.diff(startDate));
    return duration.humanize() + " ago";
}

export function getDatesDifferenceInDays(startDate, endDate) {
    if (startDate == null || endDate == null) {
        return null;
    } else {

        let duration = moment.duration(moment(endDate).diff(moment(startDate)));
        const n = 24 * 60 * 60 * 1000;
        const days = Math.floor(duration / n);
        const str = moment.utc(duration % n).format('H [h] mm [m] ss [s]');
        return `${days > 0 ? `${days} ${days === 1 ? 'd' : 'd(s)'} ` : ''}${str}`;
    }
}

// TODO: MOVE CONTAINED THINGS TO NEW UTILS

/**************Data table search form array all common component code and grid ends here***********/


/*******************************Common  Components for Note, Documents , History  Starts**************************************************/

//Note Card
export function GetNoteCardForDetailPage(storageData = null, headContent, noteData, setShowAddNoteModal, setNoteModalVisibility, createAuth, authorities, isOrderNote, showDeleteConfirm = null, setShowDeleteConfirm = null, noteId = null, setNoteId = null, setNoteObject = null, setShowNoteUpdateModel = null, certificateNote = false) {


    if (isOrderNote === true) {
        return (<div className="mt-3">
                <div className="detail-style pb-2 pt-2">
                    <div className="ml-2 mr-2 d-flex">
                        <h6>{headContent} ({(noteData.items && noteData.items.length) || 0})</h6>
                        {isUserHaveAccess(authorities, [createAuth]) &&
                            <i className="fa fa-plus-circle ml-auto pr-3 fa-icon-color cursor"
                               title="Add Note..."
                               data-toggle="modal" onClick={() => setShowAddNoteModal(true)}
                               data-keyboard="false"/>}

                    </div>
                    <div className="with-max-d">
                        {noteData.items && noteData.items.length === 0 && <div className="card card-margin ml-2 mr-2">
                            <div className="bg-white card-header  pl-3 py-2">
                                <div className="row">
                                    <div className="col-8"><span>No notes to display</span>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {noteData.items && noteData.items.length > 0 && noteData.items.map((curNote) => {
                            return (<div className="card card-margin ml-2 mr-2">
                                <div className="bg-white card-header pl-3 py-2">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="row">
                                                {showDeleteConfirm != null && setShowDeleteConfirm != null &&
                                                    <div className="col-1 cursor">
                                                                              <span> <i
                                                                                  className={curNote.deleted === false ? "fa fa-trash mr-auto" : "disable_the_btn fa fa-trash mr-auto"}
                                                                                  aria-hidden="true"
                                                                                  onClick={() => {
                                                                                      if (curNote.deleted === false) {
                                                                                          setNoteId(curNote.id);
                                                                                          setShowDeleteConfirm(true);
                                                                                      }
                                                                                  }}/>
                                                                              </span>
                                                    </div>
                                                }


                                                {setNoteModalVisibility && isUserHaveAccess(authorities, ["ONOT-U"]) &&
                                                    <div className="col-2">
                                                        <div className='card_icons'>
                                                            <i onClick={() => setNoteModalVisibility(curNote.id, curNote.external)}
                                                               className={curNote.external === false ? "fa fa-eye-slash fa-icon-color" : "fa fa-eye fa-icon-color"}
                                                               aria-hidden="true"/>
                                                        </div>
                                                    </div>}


                                                {setNoteModalVisibility && storageData?.items &&
                                                    <div
                                                        className={isValidCustomer(storageData) ? "col-10" : "col-12"}>
                                                        <span
                                                            className={curNote?.noteType === "CUSTOMER" ? 'error' : ''}>{curNote.noteBody}</span>
                                                    </div>}

                                                {certificateNote === false && !setNoteModalVisibility &&
                                                    <div
                                                        className={isValidCustomer(storageData) ? "col-10" : "col-12"}>
                                                        <span>{curNote.message}</span>
                                                    </div>}

                                                {certificateNote && <div
                                                    className={isValidCustomer(storageData) ? "col-10" : "col-12"}>
                                                    <span>{curNote.comment}</span>
                                                </div>}

                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div
                                                title={`Modified by ${curNote.modifiedBy} on ${formatValueAsDate(curNote.modifiedDate)}`}>
                                                {getActionTextForCard("Created by ", curNote.createdBy, curNote.creationDate)}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>)
                        })}
                    </div>

                </div>
            </div>

        )
    } else {
        return (<div className="mt-3">
            <div className="detail-style pb-2 pt-2">
                <div className="ml-2 mr-2 d-flex">
                    <h6>{headContent} ({noteData.items && noteData.items.notes.length || 0})</h6>
                    {isUserHaveAccess(authorities, [createAuth]) &&
                        <i className="fa fa-plus-circle ml-auto pr-3 fa-icon-color cursor"
                           title="Add Note..."
                           data-toggle="modal" onClick={() => setShowAddNoteModal(true)}
                           data-keyboard="false"/>}

                </div>
                <hr/>

                <div className="with-max-d">
                    {noteData.items && noteData.items.notes.length === 0 &&
                        <div className="card card-margin ml-2 mr-2">
                            <div className="bg-white card-header pl-3 py-2">
                                <div className="row">
                                    <div className="col-8"><span>No notes to display</span>
                                    </div>
                                </div>
                            </div>
                        </div>}

                    {noteData.items && noteData.items.notes.length > 0 && noteData.items.notes.map((curNote) => {
                        return (<div className="card card-margin ml-2 mr-2">
                            <div className="bg-white card-header pl-3 py-2">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="row">

                                            {setNoteModalVisibility && isUserHaveAccess(authorities, ["ONOT-R"]) &&
                                                <div className="col-2">
                                                    <div className='card_icons'>
                                                        <i onClick={() => setNoteModalVisibility(curNote.id, curNote.external)}
                                                           className={curNote.external === false ? "fa fa-eye-slash fa-icon-color" : "fa fa-eye fa-icon-color"}
                                                           aria-hidden="true"/>
                                                    </div>
                                                </div>}


                                            {setNoteModalVisibility &&
                                                <div
                                                    className={isValidCustomer(storageData) ? "col-10" : "col-12"}>
                                                    <span>{curNote.noteBody}</span>
                                                </div>}

                                            {!setNoteModalVisibility && <div className="col-11">
                                                <span>{curNote.message}</span>
                                            </div>}

                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div
                                            title={`Modified by ${curNote.modifiedBy} on ${formatValueAsDate(curNote.modifiedDate)}`}>
                                            {getActionTextForCard("Created by ", curNote.createdBy, curNote.creationDate)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    })}
                </div>
            </div>
        </div>)

    }


}

export function detailPageLabel(label, col, isNotRequired = false) {
    return (<div className={col}>
        <div className="from-group">
            <label className={!isNotRequired ? "form-label label" : "form-label label non-required"}>
                <span className={"bold"}>{label}</span>
            </label>
        </div>
    </div>)
}

export function handleEnterKey(e) {
    if (e.keyCode === 13) {
        e.preventDefault();
    }
}


export const formatPhone = (input) => {

    if (!input){
        return "";
    }
    // Remove non-numeric characters from input
    const cleaned = ('' + input).replace(/\D/g, '');

    // Format the phone number (###) ###-####
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return input;
};

export function selectDocument(props, setData, setProps) {
    return (<>
        <a
            className={'active cursor'}
            onClick={(e) => {
                e.preventDefault();
                setData(props.data);
                setProps(props.row.original.id);
                loadPdf(props.row.original.id);
            }}
        >{props.value}
        </a>
    </>)
}

export function loadPdf(id) {
    const request = new Request(`/ajax/view-file/` + id, {
        method: "GET", mode: "cors", cache: "default",
    });

    fetch(request)
        .then((response) => response.blob())
        .then((blob) => {
            const file = window.URL.createObjectURL(blob);
            const iframe = document.querySelector("iframe");
            if (iframe?.src) iframe.src = file;
        });
}


//Document Card
export function GetDocumentCardForDetailPage(storageData = null, headContent, documentsData, setShowUploadDocModal, setDocModalVisibility, createAuth, path, authorities, setFileId, DocumentTypeEnums = null, isOrderDocument = false, orderDocumentFilterObject = null, shareMultiDocModal = null, setShareMultiDocModal = null, setShowDeleteFileConfirm = false, markedFileList = null, toggleDocuments = null, setToggleDocuments = null, setShowRestoreFileConfirm = null, setShowCombinePdfModal = null) {
    return (

        <div className="mt-3">
            <div className="detail-style pb-2 pt-2">
                <div className="ml-2 mr-2 d-flex">
                    <h6>{headContent} ({documentsData.items && documentsData.items.length || 0})</h6>
                    {isOrderDocument === true && DocumentTypeEnums !== null &&
                        <i className="fa fa-filter ml-2 pr-3 fa-icon-color cursor_hover mt-1"
                           title="Filter Order Documents..."
                           onClick={() => orderDocumentFilterObject.setShowDocumentFilterTag(!orderDocumentFilterObject.showDocumentFilterTag)}
                           data-toggle="modal" data-keyboard="false"/>}

                    <div className={"ml-auto"}>


                        {setShowCombinePdfModal != null &&
                            <i title={"Combine Pdf"}
                               className={"fa fa-code-merge fa-solid pr-5 toggle_css"}
                               onClick={() => {
                                   setShowCombinePdfModal(true);
                               }
                               }
                            >
                            </i>
                        }

                        {toggleDocuments != null && setToggleDocuments != null &&
                            <i title={toggleDocuments ? "Hide deleted" : "Show deleted"}
                               className={toggleDocuments ? "fa-solid fa-toggle-on toggle_css pr-5" : "fa-solid fa-toggle-off toggle_css pr-5"}
                               onClick={() => {
                                   setToggleDocuments(!toggleDocuments);
                               }}
                            >
                            </i>
                        }

                        {isOrderDocument === true && isUserHaveAccess(authorities, ["FILEDELIVER-V"]) &&
                            <i className="cursor_hover fa fa-icon-color fa-share-alt fas mt-1 pr-5"
                               title="Deliver Documents..."
                               data-toggle="modal" onClick={() => setShareMultiDocModal(true)}
                               data-keyboard="false"/>}
                        {isUserHaveAccess(authorities, ["FILEUPLOAD-V"]) &&
                            <i className={isOrderDocument === true ? "fa fa-upload pr-3 fa-icon-color cursor_hover mt-1" : "ml-auto fa fa-upload pr-3 fa-icon-color cursor_hover mt-1"}
                               title="Upload Document..."
                               data-toggle="modal" onClick={() => setShowUploadDocModal(true)}
                               data-keyboard="false"/>}
                    </div>
                </div>
                {isOrderDocument === true && DocumentTypeEnums !== null && orderDocumentFilterObject.showDocumentFilterTag === true &&
                    <div className="multi_select_order_log ml-2 mr-2">
                        {CommonSelectDropDownTag(Array.isArray(DocumentTypeEnums) && DocumentTypeEnums, "value", "constant", "Search Document Type...", null, true, orderDocumentFilterObject.documentTypeList, orderDocumentFilterObject.setDocumentTypeList, false, false, null, null)}
                    </div>}
                <hr/>
                <div className="with-max-document">
                    {documentsData.items && documentsData.items.length === 0 &&
                        <div className="card card-margin ml-2 mr-2">
                            <div className="bg-white card-header pl-3 py-2">
                                <div className="row">
                                    <div className="col-8"><span>No documents to display</span>
                                    </div>
                                </div>
                            </div>
                        </div>}

                    {documentsData.items && documentsData.items.length > 0 && documentsData.items.map((curDoc) => {
                        return (<div className="card card-margin ml-2 mr-2">
                            <div
                                className={curDoc.delivered !== undefined ? (curDoc.delivered === true ? "bg-white card-header pl-3 py-2 order-doc-deliver" : (markedFileList?.includes(curDoc?.id) ? "bg-white card-header pl-3 py-2 order-doc-marked" : "bg-white card-header pl-3 py-2 order-doc-normal")) : (markedFileList?.includes(curDoc?.id) ? "bg-white card-header pl-3 py-2 order-doc-marked" : "bg-white card-header pl-3 py-2")}>
                                <div className={curDoc.deleted === false ? "row" : "disabled_field row"}>
                                    <div className="col-6">
                                        <div className="row">
                                            {setDocModalVisibility && isValidCustomer(storageData) &&
                                                <div className="col-1 mt-2">
                                                    <div className='card_icons'>
                                                        <i onClick={() => setDocModalVisibility(curDoc.id, curDoc.visibility)}
                                                           className={curDoc.visibility === false ? "fa fa-eye-slash fa-icon-color" : "fa fa-eye fa-icon-color"}
                                                           aria-hidden="true"/>

                                                    </div>
                                                </div>}

                                            <div className="col-2">
                                                <img loading="lazy" alt="" className="img_style"
                                                     src={(curDoc.documentType === 'PDF' || curDoc.documentType === 'pdf') ? pdfIcon : fileIcon}/>
                                            </div>

                                            {setDocModalVisibility !== null && <div
                                                className={isValidCustomer(storageData) ? "col-7 text_wrap" : "col-10 text_wrap"}>
                                                                        <span className="cursor text-color"
                                                                              title={curDoc.filename}
                                                                              onClick={() => {
                                                                                  const _newWindow = window.open(path + curDoc.id);
                                                                                  if (_newWindow) {
                                                                                      const titleElement = document.createElement('title');
                                                                                      titleElement.appendChild(document.createTextNode(curDoc.filename));
                                                                                      _newWindow.document.getElementsByTagName('head')[0].appendChild(titleElement);
                                                                                  }
                                                                              }}>{curDoc.filename}</span>

                                                <br/>


                                                <span>
                                                        {`${curDoc.fileType || curDoc.fileDataType} (${formatByte(curDoc.fileSize, 1)})`}
                                                </span>
                                                <br/>
                                                <span>
                                                    <a href={path + curDoc.id} download>
                                                                 <span className={"fa fa-download fa-icon-color "}/>
                                                    </a>
                                                </span>
                                                {setShowDeleteFileConfirm && isUserHaveAccess(authorities, ["FILE-U", "FILE-V", "FILE-D"], true) &&
                                                    (isValidCustomer(storageData) ||
                                                        (storageData?.items?.customerLevel === "CUSTOMER" &&
                                                            curDoc.creatorCustomerId === storageData?.items?.customer_Id
                                                        ))&&
                                                    <span className={"ml-2"}> <i
                                                        className={curDoc.deleted === false && curDoc.delivered === false ? "fa fa-trash mr-auto fa-icon-color" : "fa fa-trash-restore mr-auto"}
                                                        aria-hidden="true"
                                                        onClick={() => {
                                                            setFileId(curDoc.id);
                                                            if (curDoc.deleted === false) {
                                                                setShowDeleteFileConfirm(true);
                                                            } else {
                                                                setShowRestoreFileConfirm(true);
                                                            }
                                                        }}/>
                                                    </span>
                                                }
                                            </div>}

                                            {setDocModalVisibility === null && <div className="col-10 text_wrap">
                                                <a href={headContent === "Collector Documents" ? path + curDoc.id + "/" + curDoc.fileSize + "?fileName=" + curDoc?.filename : path + curDoc.id}
                                                   download>
                                                    <span className="text-color"
                                                          title={curDoc.filename}>{curDoc.filename}</span>
                                                </a>
                                                <br/>
                                                <span>
                                                        {`${curDoc.fileType || curDoc.fileDataType || ''} (${formatByte(curDoc.fileSize, 1)})`}
                                                </span>
                                                <br/>
                                                <span>
                                                    <a href={headContent === "Collector Documents" ? path + curDoc.id + "/" + curDoc.fileSize + "?fileName=" + curDoc?.filename : path + curDoc.id}
                                                       download>
                                                                 <span className={"fa fa-download fa-icon-color mr-2"}/>
                                                    </a>
                                                </span>

                                                {curDoc.filename.slice(-3).toUpperCase() === 'ZIP' && curDoc.tgxFileType === "COLLECTOR_FILE" && curDoc.fileDataType?.includes("_ROLL") && <>
                                                    <a onClick={(e) => {
                                                        setFileId(curDoc.id)
                                                    }}>
                                                        <i className="fa fa-solid fa-file-import fa-icon-color cursor"
                                                           title="Job Schedule..."
                                                           data-toggle="modal"
                                                           data-keyboard="false"
                                                        />
                                                    </a>
                                                </>}

                                            </div>}
                                        </div>
                                    </div>

                                    <div className="col-6 fonts-data">
                                        <div
                                            title={`Modified by ${curDoc.modifiedBy} on ${formatValueAsDate(curDoc.modifiedDate)}`}>
                                            {getActionTextForCard("Created by ", curDoc.createdBy, curDoc.creationDate)}
                                        </div>

                                        {curDoc.delivered !== undefined && curDoc.delivered === true &&
                                            getActionTextForCard("Delivered to ", curDoc.deliveredTo.join(", "), curDoc.dateDelivered)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>)
                    })}
                </div>
            </div>
        </div>)
}

export function getActionTextForCard(actionText, actor, actionDate) {
    return (<>
        <span>{actionText}</span>
        <span style={{fontStyle: "italic"}}>{actor}</span>
        <span> on </span>
        <span style={{fontStyle: "italic"}}>
            {formatValueAsDate(actionDate)}
        </span>
    </>)
}

//History Log Card
export function getHistoryLogForDetailPage(headerContent, logsData, isOrderHistoryLogs, LogTypeEnums = null, orderLogFilterObject = null, setResolveModalVisibility = null, authorities = null, setOrderLogId = null) {
    return (<div className="mt-3">
        <div className="detail-style pb-2 pt-2">
            <div className="ml-2 mr-2">
                <h6>{headerContent} ({logsData.items && logsData.items.length || 0})
                    {isOrderHistoryLogs === true && LogTypeEnums !== null &&
                        <i className="fa fa-filter ml-2 pr-3 fa-icon-color cursor_hover mt-1"
                           title="Filter Order Logs..."
                           onClick={(e) => orderLogFilterObject.setShowHistoryFilterTag(!orderLogFilterObject.showHistoryFilterTag)}
                           data-toggle="modal" data-keyboard="false"/>}
                </h6>

                {isOrderHistoryLogs === true && LogTypeEnums !== null && orderLogFilterObject.showHistoryFilterTag === true &&
                    <div className="multi_select_order_log">
                        {CommonSelectDropDownTag(Array.isArray(LogTypeEnums) && LogTypeEnums, "value", "constant", "Search Log Type...", null, true, orderLogFilterObject.logTypeList, orderLogFilterObject.setLogTypeList, false, false, null, null)}
                    </div>}

            </div>
            <hr/>
            <div className="with-max-document">
                {logsData.items && logsData.items.length === 0 && <div className="card card-margin ml-2 mr-2">
                    <div className="bg-white card-header pl-3 py-2">
                        <div className="row">
                            <div className="col-8">
                                <span>No order history to display</span>
                            </div>
                        </div>
                    </div>
                </div>}
                {logsData.items && logsData.items.length > 0 && logsData.items.map((curLogs) => {
                    return (<div className="card card-margin ml-2 mr-2">

                        <div
                            className={(curLogs.logType === 'RESOLVED' || curLogs.logType === 'RESOLVED') ? " card-header pl-3 py-2 resolved" : "bg-white card-header pl-3 py-2 "}>
                            <div className="row">
                                {isOrderHistoryLogs && <div className="col-6">

                                        {(curLogs.logType === 'INFO' || curLogs.logType === 'Info') &&
                                            <>
                                                <span className="badge badge-primary">{curLogs.logType} </span><br/>
                                            </>
                                        }

                                        {(curLogs.logType === 'debug' || curLogs.logType === 'DEBUG') &&
                                            <>
                                                <span className="badge bg-info text-white ">{curLogs.logType} </span><br/>
                                            </>
                                        }

                                        {(curLogs.logType === 'Error' || curLogs.logType === 'ERROR') &&
                                            <>
                                                <span className="badge badge-danger">{curLogs?.logType} </span> &nbsp;
                                                {isUserHaveAccess(authorities, ["ERRLOG-U"]) ?
                                                    <>
                                                    <span className="badge badge-primary resolve-span"
                                                          onClick={(e) => {
                                                              if(isOrderHistoryLogs){
                                                                  setOrderLogId(curLogs?.id)
                                                                  setResolveModalVisibility()
                                                              }
                                                          }}>Resolve </span>
                                                        <br/>
                                                    </>
                                                    : <br/>
                                                }
                                            </>

                                        }

                                        {(curLogs.logType === 'WARN' || curLogs.logType === 'WARN') &&
                                            <>
                                                <span className="badge badge-warning">{curLogs.logType} </span> <br/>
                                            </>
                                        }

                                        {(curLogs.logType === 'RESOLVED' || curLogs.logType === 'RESOLVED') &&
                                            <>
                                                <span className="badge badge-primary resolve-span">{curLogs.logType} </span><br/>
                                            </>
                                        }

                                        <span
                                            title={curLogs.detailedLogMessage === "" ? "" : curLogs.detailedLogMessage}>{curLogs.logMessage}</span>

                                    </div>

                                    ||

                                    <div className="col-6">
                                                    <span
                                                        className={(curLogs.dataFileType === 'APPRAISAL_ROLL' || curLogs.dataFileType === 'Appraisal Roll' || curLogs.dataFileType === 'OTHER' || curLogs.dataFileType === 'Other' || curLogs.dataFileType === 'TAX_ROLL' || curLogs.dataFileType === 'Tax Roll' || curLogs.dataFileType === 'LAYOUT' || curLogs.dataFileType === 'Layout') ? 'badge badge-primary' : "badge badge-danger"}>{curLogs.dataFileType}
                                                    </span>
                                        {curLogs.dataFileType && <br/>}
                                        <span>{curLogs.message}</span>
                                    </div>

                                }
                                <div className="col-6">
                                    <div
                                        title={`Modified by ${curLogs.modifiedBy} on ${formatValueAsDate(curLogs.modifiedDate)}`}>
                                        {getActionTextForCard("Created by ", curLogs.createdBy, curLogs.creationDate)}
                                        {curLogs.count > 1 && <>
                                            <br/>
                                            <span className="label">Occurrences: {curLogs.count}</span>
                                        </>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>)
                })}
            </div>
        </div>
    </div>)

}

export default function skellTonConstantView(thead) {
    return (<>
        <tr>
            {thead.map(() => {
                return (<td><SkeletonCell/></td>)
            })}
        </tr>
        <tr>
            {thead.map(() => {
                return (<td><SkeletonCell/></td>)
            })}
        </tr>
        <tr>
            {thead.map(() => {
                return (<td><SkeletonCell/></td>)
            })}
        </tr>
        <tr>
            {thead.map(() => {
                return (<td><SkeletonCell/></td>)
            })}
        </tr>
        <tr>
            {thead.map(() => {
                return (<td><SkeletonCell/></td>)
            })}
        </tr>
        <tr>
            {thead.map(() => {
                return (<td><SkeletonCell/></td>)
            })}
        </tr>
        <tr>
            {thead.map(() => {
                return (<td><SkeletonCell/></td>)
            })}
        </tr>
        <tr>
            {thead.map(() => {
                return (<td><SkeletonCell/></td>)
            })}
        </tr>
        <tr>
            {thead.map(() => {
                return (<td><SkeletonCell/></td>)
            })}
        </tr>
        <tr>
            {thead.map(() => {
                return (<td><SkeletonCell/></td>)
            })}
        </tr>
    </>)
}


export const getAssignmentLogTable = (assignmentLogData, AssignmentLogTypeEnum) => {
    return (<>

        <div className="detail-style pb-2 pt-2 mt-3">
            <div className="ml-2 mr-2 d-flex">
                <h6>Assignment Logs ({(assignmentLogData.items && assignmentLogData.items.length || 0)})</h6>
            </div>

            <hr/>
            <div className='parent_account association_fee_overflow_x'>
                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Product Type</th>
                            <th scope="col">Assigned To</th>
                            <th scope="col">Assigned By</th>
                            <th scope="col">Assigned On</th>
                            <th scope="col">Reason</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr>
                            {assignmentLogData.items ? assignmentLogData.items && assignmentLogData.items.length === 0 &&
                                <td colSpan={5}>There are no assignment logs to display</td> : <SkeletonCell/>}
                        </tr>

                        {assignmentLogData?.items ? assignmentLogData?.items?.map((cur) => {
                            return (<>
                                <tr>
                                    <td>{cur.productId}</td>
                                    <td> {cur.assigneeId == null || cur.assigneeId === "" ? '-' : `${cur.assigneeId} (${getEnumValueByConstant(AssignmentLogTypeEnum, cur.assigneeType)})`}</td>
                                    <td> {cur.assignedBy}</td>
                                    <td>
                                        {createFormattedDate(cur.creationDate)}
                                    </td>
                                    <td>
                                        {cur.reason == null || cur.reason === "" ? '-' : cur.reason}
                                    </td>
                                </tr>
                            </>)

                        }) : <SkeletonCell/>}


                        </tbody>


                    </table>
                </div>

            </div>
        </div>

    </>);
}


export function getProductStatusDotInfo(productStatus) {
    const statusMap = {
        NEW: {color: '#96938e', title: 'New'},
        PENDING: {color: '#FFB631', title: 'Pending'},
        SAVED: {color: '#FFB631', title: 'Pending'},
        COMPLETED: {color: '#4682B4', title: 'Completed'},
        QC: {color: '#bf00ff', title: 'QC'},
        DELIVERED: {color: '#73B446', title: 'Delivered'}
    };

    const errorInfo = {color: '#B44646', title: 'Error'};

    return statusMap[productStatus] || {...errorInfo, title: productStatus};
}


export function sortPropertiesBySourceType(properties) {

    //setting priority order where null values occurs first.
    const priorityOrder = [null, 'CUSTOMER', 'PICKER', 'LOCATE', 'EDITOR', 'CERT_ACCOUNT'];

    return _.sortBy(properties, (property) => {
        const propertyIndex = priorityOrder.indexOf(property?.propertyRequestSource);
        return propertyIndex === -1 ? Infinity : propertyIndex;
    });

}


export function returnNestedKeyValues(obj, path) {

    if (obj === null || obj === undefined) {
        return "";
    }

    const keys = path.split(".");
    const currentKey = keys[0];
    const remainingKeys = keys.slice(1);

    if (remainingKeys.length === 0) {
        return obj[currentKey];
    }
    const nestedObj = obj[currentKey];
    if (nestedObj == null) {
        return "";
    }

    return returnNestedKeyValues(nestedObj, remainingKeys.join("."));

}

export const isNotEmptyAndNull = (val) => {
    return !_.isEmpty(val);
}

// TODO: Add hooks to own Util file
export function useEnumByName(enumName, arrayToFilter = []) {
    const selector = useSelector((state) => state.fetchEnumsReducer)?.items || [];
    const array = selector && enumName in selector ? selector[enumName] : [];

    return array.filter(cur => !arrayToFilter.includes(cur.constant));
}

// INFO: Return Cacheable data From reducer;
export function GetCachedResponseFromReducer(reducer) {
    return store.getState()[reducer].items;
}

export function useAuthorities() {
    let authArray = useSelector((state) => state.fetchAuthoritiesReducer)?.items || [];
    return authArray.length > 0 && authArray;
}

export function getEnumValueByConstant(enumPairs, constantValue) {
    if (constantValue) {
        return Array.isArray(enumPairs) && enumPairs.find(enumPair => constantValue === enumPair.constant)?.value;
    }
}

export function computeOrderStatusForCustomer(value) {
    if (value === "NEW" || value === "AWAITING_PARTNER" || value === "AWAITING_LOCATE" || value === "AWAITING_FULFILLMENT" || value === "PENDING" || value === "ISSUE") {
        return "PENDING"
    } else {
        return value
    }
}


export const computeAssociationVerifiedByColor = (verifiedDate, associationVerificationDays) => {
    if (verifiedDate === null) {
        return "darkorange";
    }

    const diffInMs = Math.abs(new Date(formatValueAsDate(verifiedDate, "MM/DD/YYYY")) - new Date());
    let computedDays = diffInMs / (1000 * 60 * 60 * 24);
    switch (computedDays < associationVerificationDays) {
        case true:
            return "blue";
        case false:
            return "red";
        default:
            return "darkorange"
    }

}

export function groupBy(array, property) {
    let hash = {};
    for (let i = 0; i < array.length; i++) {
        if (!hash[array[i][property]]) hash[array[i][property]] = [];
        hash[array[i][property]].push(array[i]);
    }
    return hash;
}


// TODO: Make this its own component file instead of here in utils
export const CommonSubmitLoader = (disable, formType, className = null, isValidSubmission = null, title = "") => {
    return (<>
        {disable === true ?
            <button disabled={true}
                    className={className !== null ? className + " btn_success_spinner" : "cm_btn btn_success_spinner"}>
                <>
                    {formType}...
                    <span className="spinner"/>
                </>
            </button>
            :
            <button disabled={isValidSubmission !== null && isValidSubmission === false}
                    className={className !== null ? className : "cm_btn ml-0"}
                    style={isValidSubmission !== null && isValidSubmission === false ? {opacity: 0.5} : {}}
                    title = {isValidSubmission === false ? title :""}>{formType}</button>
        }
    </>)
}

export function onDraggableStart(event, uiData, draggleRef, bounds, setBounds) {
    const {clientWidth, clientHeight} = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();

    bounds.left = -targetRect?.left + uiData?.x;
    bounds.right = clientWidth - (targetRect?.right - uiData?.x);

    setBounds(bounds);

}

export const getFilterModalKeysValue = (valToFilter, key) => {

    if (Array.isArray(valToFilter)) {
        return valToFilter.find(cur => cur.showModal === true)?.[key];
    } else {
        return valToFilter?.[key];
    }

}


export function returnAddNewPropertyOption(options) {

    if (Array.isArray(options) && options.length === 0) {
        options.unshift(addNewDefaultSelectorValue)
        return options;
    }

    if (Array.isArray(options) && options[0].id === addNewDefaultSelectorValue.id) {
        return options;
    } else {
        options.unshift(addNewDefaultSelectorValue)
        return options;
    }

}

export function appendNewOption(options, addNewDefaultSelectorValue) {


    if (Array.isArray(options) && options.length === 0) {
        options.unshift(addNewDefaultSelectorValue)
        return options;
    }

    if (Array.isArray(options) && options[0].id === addNewDefaultSelectorValue.id) {
        return options;
    } else {
        options.unshift(addNewDefaultSelectorValue)
        return options;
    }

}


export function removeAnyBadChar(val, isEditorPropertyPicker) {
    if (!isEditorPropertyPicker) {
        return val;
    }
    if (val === null || val === "" || val === undefined) {
        return val;
    }

    let emptyValue = val.replace(/\d+/g, '').replace(/\./g, "").trim();

    return val.includes("Add New") ? val.replace(/\./g, "") : (emptyValue === null || emptyValue === "") ? val + "Unknown" : val;
}

export const CommonSelectDropDownTag = (itemMap, label, value, placeholderText, className = "select", isMulti, name, setName, isRequired, isDisabled = false, dependentStates, callBackFunction = null, isClearable = true, isEditorPropertyPicker = false, disabledValue = null) => {

    // Generate options array based on itemMap, label, and value
    const options = itemMap && Array.isArray(itemMap) && itemMap?.map(item => ({
        value: typeof value === 'function' ? value(item) : item[value],
        label: typeof label === 'function' ? removeAnyBadChar(label(item), isEditorPropertyPicker) : removeAnyBadChar(item[label], isEditorPropertyPicker),
        isDisabled: typeof label !== 'function' && removeAnyBadChar(item[label], isEditorPropertyPicker) === disabledValue
    }));

    // Function to handle change in selection
    const handleChange = (selectedValue) => {
        // Reset dependent states if provided
        if (dependentStates && Array.isArray(dependentStates)) {
            dependentStates.forEach(setState => setState(null));
        }

        // Call callback function or update state name
        const selectedValues = Array.isArray(selectedValue) ? selectedValue.map(subValue => subValue.value) : selectedValue?.value;
        callBackFunction !== null ? callBackFunction(selectedValues) : setName(selectedValues);
    };

    return (
        <Select
            name={name}
            classNamePrefix={className === null ? "select" : className}
            isClearable={isClearable}
            isMulti={isMulti}
            options={options}
            placeholder={placeholderText}
            onChange={handleChange}
            isDisabled={!options || !options.length || isDisabled}
            value={getObjectiveDataWithMatchedData(isMulti, options, name, "value")} // Adjust as per your implementation
            required={isRequired}
            menuPlacement={"auto"}
        />
    );
};

export const removeInvalidAndTrimList = (items) => {
    return _.compact(_.map(items, _.trim))
}

//return shown columns on grid.
export function getShownColumns(allColumns, userSelectedColumns) {
    if (!userSelectedColumns || userSelectedColumns.length === 0) {
        return allColumns;
    }

    let keysToBeFiltered = allColumns?.filter(el => {
        return !userSelectedColumns.find(element => {
            return element.key === (el.Header instanceof Object ? el.Header.props.title : el.Header);
        });
    });

    let userSelectedColumn = [];
    allColumns?.map(cur => {
        for (let i = 0; i < userSelectedColumns.length; i++) {
            if (userSelectedColumns[i].key === (cur.Header instanceof Object ? cur.Header.props.title : cur.Header) && userSelectedColumns[i].value === "show") {
                userSelectedColumn.push(cur);
                break;
            }
        }
    })

    keysToBeFiltered.length > 0 && keysToBeFiltered.map(cur => {
        userSelectedColumn.push(cur);
    })


    return persistGridOrder(userSelectedColumn, allColumns);
}


//return userSettings listed object
export function getUserSettingListAll(headerGroupArray, grid, pageNumber = null, pageControl = null) {
    let userSettingsArray = [
        {
            group: grid,
            key: "pageNumber",
            value: pageNumber !== null ? pageNumber.toString() : "15"
        },
        {
            group: grid,
            key: "pageControl",
            value: pageControl !== null ? pageControl.toString() : PageControl.find(cur => cur.constant === 'BOTTOM')?.constant
        }

    ]

    headerGroupArray.map((curHeader) => {
        userSettingsArray.push({
            group: grid,
            key: curHeader.Header instanceof Object ? curHeader.Header.props.title : curHeader.Header,
            value: !(curHeader?.defaultHidden && curHeader?.canBeHidden)? "show" : "hide",
            checked: !(curHeader?.defaultHidden && curHeader?.canBeHidden),
        });
    })

    return userSettingsArray;


}
export const datePicker = (periodValue, setVal, isReadOnly = false) => {
    const val = periodValue ? moment.utc(periodValue).toDate() : null;

    return (
        <>
            <DatePicker
                onChange={(value) => {
                    if (value) {
                        const selectedMoment = moment(value);
                        setVal(selectedMoment);
                    } else {
                        setVal(null);
                    }
                }}
                value={val}
                dayPlaceholder={"dd"}
                monthPlaceholder={"mm"}
                format={"MM-dd"}
                onKeyDown={(e) => {
                    e.preventDefault();
                }}
                disabled={isReadOnly}
            />
        </>
    );
};

//serialize the object (use this only for search purpose)
export function serializeObject(input) {
    const params = new URLSearchParams();

    for (const key in input) {
        if (Array.isArray(input[key])) {
            input[key].forEach(val => {
                params.append(key + '[]', val)
            })
        } else {
            params.append(key, input[key]);
        }
    }

    return '?' + params.toString();
}


export function serialize(obj) {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}


//deserialize the object (use this only for search purpose)
export function deserializeObject(input) {
    const payload = {};
    const params = new URLSearchParams(input);

    for (let [key, val] of params.entries()) {
        if (key.endsWith('[]')) {
            key = key.replace(/\[\]$/, '');
            if (payload[key]) {
                payload[key].push(val);
            } else {
                payload[key] = [val]
            }
        } else {
            if (val === 'true' || val === 'false') {
                payload[key] = val === 'true';
            } else {
                payload[key] = val;
            }
        }
    }

    return payload;
}


export function prePopulateFilterTagFromUrl(filterModalOptions) {
    if (window.location.search && window.location.search !== "") {
        if (deserializeObject(window.location.search) instanceof Object && Object.keys(deserializeObject(window.location.search, true)).length > 0) {
            let urlObj = deserializeObject(window.location.search);
            filterModalOptions.map((cur) => {
                Object.keys(urlObj).map((cur1) => {
                    if (cur.filterModalKeyAccessor === cur1) {
                        cur?.setShowModalValue(urlObj[cur1])
                    }
                    if (cur.filterModalKeyAccessor.toString() + "ShowValue" === cur1) {
                        cur?.setShowModalTagValue(urlObj[cur1])
                    }
                })
            })
        }
    }

}


export function computeRowTextColor(redColor, bold) {
    const classNames = [];

    if (redColor === true) {
        classNames.push('red_text');
    }
    if (bold === true) {
        classNames.push('bold');
    }

    return _.join(_.compact(classNames), ' ');
}

export function changeUserSettingPageSize(userSettingsData, pageNumber) {
    let pageNumberExists = false;

    // Check if pageNumber attribute already exists
    for (let i = 0; i < userSettingsData.length; i++) {
        if (userSettingsData[i].key === 'pageNumber') {
            userSettingsData[i].value = pageNumber;
            pageNumberExists = true;
            break;
        }
    }

    // If pageNumber attribute doesn't exist, add it
    if (!pageNumberExists) {
        userSettingsData.push({ group: userSettingsData[0]?.group, key: 'pageNumber', value: pageNumber });
    }

    return userSettingsData;
}



export function computePageControl(userSettings, type) {
    if (userSettings.error === undefined) {
        let value = userSettings?.find(cur => cur.key === 'pageControl');
        if (value == null && type === 'TOP') {
            return false
        }

        if (value == null) {
            return true
        }

        if (value.value.toUpperCase() === 'BOTH') {
            return true;
        }
        if (value.value.toUpperCase() === 'TOP' && type === 'TOP') {
            return true;
        }
        if (value.value.toUpperCase() === 'BOTTOM' && type === 'BOTTOM') {
            return true;
        }

        if (value.value.toUpperCase() === 'BOTTOM' && type === 'TOP') {
            return false;
        }

        return !(value.value.toUpperCase() === 'TOP' && type === 'BOTTOM');
    }

}

//return userSettings listed object


export function getUserSettingList(settingList, headerGroups, grid, name = null, checked = null, pageNumber = null, pageControl = null, linkOpeningStyle = null) {
    const userSettingsArray = [];

    const existingColumnKeys = settingList.map(el => el.key);
    const newColumns = headerGroups.filter(el => !existingColumnKeys.includes(el.Header instanceof Object ? el.Header.props.title : el.Header));

    headerGroups.forEach(curHeader => {
        const headerTitle = curHeader.Header instanceof Object ? curHeader.Header.props.title : curHeader.Header;
        const headerNotInSettingList = !settingList.some(item => item.key === headerTitle);
        const canHide = curHeader.canBeHidden !== false;

        if (headerNotInSettingList && canHide) {
            userSettingsArray.push({ group: grid, key: headerTitle, value: curHeader?.defaultHidden ? "hide" : "show", checked: !curHeader?.defaultHidden });
        }
    });

    const defaultValues = {
        pageControl: "BOTTOM",
        linkOpeningStyle: "CURRENT TAB"
    };
    Object.keys(defaultValues).forEach(key => {
        if (!existingColumnKeys.includes(key)) {
            settingList.push({group: grid, key, value: defaultValues[key]});
        }
    });

    [...settingList, ...newColumns].forEach(curHeader => {
        if (name !== null && checked !== null && curHeader.key === name) {
            userSettingsArray.push({group: grid, key: curHeader.key, value: checked ? "show" : "hide", checked});
        } else if (curHeader.key === "pageNumber") {
            userSettingsArray.push({group: grid, key: "pageNumber", value: pageNumber});
        } else if (curHeader.key === "linkOpeningStyle") {
            userSettingsArray.push({group: grid, key: "linkOpeningStyle", value: linkOpeningStyle});
        } else if (curHeader.key === "pageControl") {
            userSettingsArray.push({group: grid, key: "pageControl", value: pageControl});
        } else {
            userSettingsArray.push({
                group: grid, key: curHeader.key, value: curHeader.value, checked: curHeader.value === "show"
            });
        }
    });

    return userSettingsArray.filter(cur => cur.key !== undefined);
}


export const isMatchSubdivisionName = (subdivisionName) => {
    const subdivisionNameArray = subdivisionName.split(" ");
    const variations = ["UNIT", "PHASE", "SECTION", "PH", "SEC", "CITY"];
    return _.some(subdivisionNameArray, value => _.includes(variations, value.toUpperCase()));
};

export function computeRegex(result) {

    const labels = ["Property County", "Owner Name", "Buyer/Borrower Name", "Property Address", "Closing Date", "File Number"];
    const indexes = labels.map(label => result.indexOf(label));

    const minIndex = _.minBy(indexes, index => (index === -1 ? Infinity : index));

    const conditions = [
        [minIndex === indexes[0], /(Short Legal|CAD Parcel Number|Property County)/g],
        [minIndex === indexes[1], /(Short Legal|CAD Parcel Number|Owner Name)/g],
        [minIndex === indexes[2], /(Short Legal|CAD Parcel Number|Buyer)/g],
        [minIndex === indexes[3], /(Short Legal|CAD Parcel Number|Property Address)/g],
        [minIndex === indexes[4], /(Short Legal|CAD Parcel Number|Closing Date)/g],
        [minIndex === indexes[5], /(Short Legal|CAD Parcel Number|File Number)/g]
    ];

    return _.find(conditions, condition => condition[0])[1];


}

export const removeCertainKeys = (obj, keysToRemove) => {
    _.forEach(obj, (value, key) => {
        if (_.includes(keysToRemove, key)) {
            delete obj[key];
        } else if (_.isObject(value)) {
            removeCertainKeys(value, keysToRemove);
        }
    });

    return obj;
}


export const sortOrderResult = (obj) => {
    if (Array.isArray(obj)) {
        for (const item of obj) {
            sortOrderResult(item);
        }
    } else if (typeof obj === 'object' && obj !== null) {
        for (const key in obj) {

            if (obj.hasOwnProperty(key) && Array.isArray(obj[key])) {

                if (obj[key] && computeOrderResultSortedKeyValue.hasOwnProperty(key)) {
                    obj[key] = applyEditorSort(key, obj[key]);
                }
            }
            sortOrderResult(obj[key]);
        }
    }
    return obj;
}

export function trimSpace(val) {
    return val != null && val !== "" ? val.trim() : val;
}

export function getReportDefaultUrl(authorities) {
    const formattedStartDate = getCurrentMonthStartDate();

    if (isUserHaveAccess(authorities, ["TRNREPO-V"])) {
        return `/turn-around-report?updateInclusion=All&includeOpenOrders=false&creationDateFrom=${formattedStartDate}`;
    }

    if (isUserHaveAccess(authorities, ["REVREPO-V"])) {
        return `/revenue-report?deliveredDateFrom=${formattedStartDate}`;
    }

    if (isUserHaveAccess(authorities, ["CTYREPO-V"])) {
        return `/county-report?createdDateFrom=${formattedStartDate}&state=TX`;
    }
    if (isUserHaveAccess(authorities, ["AGGUSRREPO-V"])) {
        return `/aggregate-user-efficiency-report?createdDateFrom=${formattedStartDate}`;
    }
    if (isUserHaveAccess(authorities, ["DETUSRREPO-V"])) {
        return `/detailed-user-efficiency-report?createdDateFrom=${formattedStartDate}`;
    }
    if (isUserHaveAccess(authorities, ["AUTMUNREPO-V"])) {
        return `/auto-vs-manual-report?createdDateFrom=${formattedStartDate}&state=TX`;
    }
    if (isUserHaveAccess(authorities, ["ACCRECREPO-V"])) {
        return `/accounts-receivable-reports?deliveredDateFrom=${formattedStartDate}`;
    }
    if (isUserHaveAccess(authorities, ["FCANDR-V"])) {
        return `/fulfillment-candidate-report?deliveredDateFrom=${formattedStartDate}`;
    }
    if (isUserHaveAccess(authorities, ["FCUSR-V"])) {
        return `/fulfillment-customer-report?deliveredDateFrom=${formattedStartDate}`;
    }
    if (isUserHaveAccess(authorities, ["DLYCUNTREPO-V"])) {
        return "/daily-order-count-report?period=DAYS";
    }
}

export function formatBuyerSellerNames(names) {
    if (!names || names.trim() === '') {
        return '';
    }
    const nameArray = names.split('||').map(name => name.trim());
    return formatNames(nameArray);
}

export function formatNames(list) {
    if (list.length === 0) {
        return ""; // return empty string if the list is empty
    }
    if (list.length === 1) {
        return list[0];
    }
    return list.join(" & ");
}


export async function CalculatedDueValue(totalTaxCalculatorObj, setRender, list, index) {
    try {
        const response = await axios.post(`/ajax/estimate-total-calculated-due`, totalTaxCalculatorObj);
        list[index].calculatedDue = response.data;
        setRender(new Date().getMilliseconds());
        return response.data;
    } catch (error) {
        console.log(error);
        // Optionally handle error here, e.g., show an error message to the user
    }
}
export function getCurrentMonthStartDate() {
    const currentDate = new Date();
    const currentMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const formattedStartDate = `${currentMonthStart.getFullYear()}-${(currentMonthStart.getMonth() + 1).toString().padStart(2, '0')}-01`;
    return `${formattedStartDate}+00%3A00%3A00`;
}
/*******************************Common  Components for Note, Documents , History  ENDS**************************************************/

export async function populateStateAndCounty(collectorId,isNeedCountyList = true) {
    try {
        const response = await axios.get(`/ajax/populate-state-county/${collectorId}/${isNeedCountyList}`);
        const { state, countyName, countyData } = response.data;
        return { state, countyName, countyData: countyData };
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export function getStateValueFromParam(window,paramName = "state"){
    // Get the URLSearchParams object from the current URL
    const urlParams = new URLSearchParams(window.location.search);
    // Get the value of the "state" parameter
    return  urlParams.get(paramName);
}

export function isValidCustomer(storageData) {
    const items = storageData?.items;
    return items?.customerLevel !== "CUSTOMER" ||
        (items?.customerLevel === "CUSTOMER" && items?.fulfillmentCandidate === true);
}
