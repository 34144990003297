
export const Routes = {
    // pages
    Default: {path: "/"},
    NotFound: {path: "/notfound/404"},
    UnAuthorized: {path: "/unauthorized/401"},
    AccessDenied: {path: "/access-denied/403"},
    Login: {path: "/login"},


    //forgot password all the routes.
    ForgotPassword: {path: "/forgot-password"},
    ForgotPasswordCheck: {path: "/forgot-password-check"},
    CreatePasswordForm: {path: "/create-password"},
    RestPasswordForm: {path: "/reset-password"},


    Home: {path: "/home"},

    Orders: {path: "/orders"},
    OrderForm: {path: "/orders/create"},
    OrderUpdateForm: {path: "/orders/re-order/:orderId"},
    OrderDetailPage: {path: "/orders/:orderId"},
    taxCertEditorPage: {path: "/orders/taxCertEditor/:orderId"},
    taxCertNationalEditorPage: {path: "/orders/national/taxCertEditor/:orderId"},
    hoaFullEditor: {path: "/orders/hoaFullEditor/:orderId"},
    hoaContactEditor: {path: "/orders/hoaContactEditor/:orderId"},


    Customers: {path: "/customers"},
    CustomerAddForm: {path: "/customers/create"},
    CustomerDetailPage: {path: "/customers/:customerId"},
    BranchDetailPage: {path: "/customers/:customerId/:branchId"},
    FNFTransactionPage: {path: "/fnf-transactions"},
    Accounting: {path: "/accounting"},
    RevenueEvent: {path: "/revenue-event"},
    CustomerPricing: {path: "/accounting/customer-pricing"},
    PartnerPricing: {path: "/accounting/partner-pricing"},
    VendorPricing: {path: "/accounting/vendor-pricing"},
    FulfillmentPricing: {path: "/accounting/fulfillment-pricing"},


    TurnAroundReport: {path: "/turn-around-report"},
    AccountsReceivableReport: {path: "/accounts-receivable-report"},
    RevenueReport: {path: "/revenue-report"},
    CountyReport: {path: "/county-report"},
    AutovsManualReport: {path: "/auto-vs-manual-report"},
    AggregateUserEfficiencyReport: {path: "/aggregate-user-efficiency-report"},
    DailyOrderCountReport: {path: "/daily-order-count-report"},
    DetailedUserEfficiencyReport: {path: "/detailed-user-efficiency-report"},
    FulfillmentCandidateReport: {path: "/fulfillment-candidate-report"},
    FulfillmentCustomerReport: {path: "/fulfillment-customer-report"},


    ReportHistory: {path: "/report-history"},


    Users: {path: "/users"},
    UserForm: {path: "/users/create"},
    UpdateUserForm: {path: "/users/:userId"},


    RoleForm: {path: "/roles/create"},
    UpdateRoleForm: {path: "/roles/:roleId"},


    Collectors: {path: "/collectors"},
    CollectorForm: {path: "/collectors/create"},
    CollectorDetailsForm: {path: "/collectors/:collectorId"},


    CollectorLinks: {path: "/collector-links"},
    AddCollectorLinkForm: {path: "/collector-links/create"},
    UpdateCollectorLinkForm: {path: "/collector-links/:collectorLinkId"},


    Counties: {path: "/counties"},
    CountyForm: {path: "/counties/create"},
    UpdateCountyForm: {path: "/counties/:countyId"},

    HighRiskAccountVerified: {path: "/high-risk-account-verified"},

    HighRiskAccount: {path: "/high-risk-account"},

    Jurisdictions: {path: "/jurisdictions"},
    JurisdictionForm: {path: "/jurisdictions/create"},
    UpdateJurisdictionForm: {path: "/jurisdictions/:jurisdictionId"},


    States: {path: "/states"},
    StateForm: {path: "/states/create"},
    UpdateStateForm: {path: "/states/:stateId"},

    StateCodes: {path: "/state-codes"},
    StateCodeForm: {path: "/state-codes/create"},
    UpdateStateCodeForm: {path: "/state-codes/:stateCodeId"},

    Roles: {path: "/roles"},

    TaxRoll: {path: "/tax-rolls"},
    updateTaxRoll: {path: "/tax-rolls/:id"},

    AppraisalRoll: {path: "/appraisal-rolls"},
    AppraisalRollDetailPage: {path: "/appraisal-rolls/:id"},

    SubDivision: {path: "/subdivisions"},
    SubDivisionForm: {path: "/subdivisions/create"},
    SubDivisionDetailsForm: {path: "/subdivisions/:subdivisionId"},

    SystemProperty: {path: "/system-properties"},
    UpdateSystemProperty: {path: "/system-properties/:systemPropertyId"},


    Profile: {path: "/profile"},

    MetroArea: {path: "/metro-areas"},
    MetroAreaForm: {path: "/metro-areas/create"},
    MetroAreaUpdateForm: {path: "/metro-areas/:msaId"},

    ManagementCompany: {path: "/management-companies"},
    ManagementCompanyForm: {path: "/management-companies/create"},
    UpdateManagementCompany: {path: "/management-companies/:managementCompanyId"},

    AutoHoa: {path: "/auto-hoa"},
    AutoHoaForm: {path: "/auto-hoa/create"},
    UpdateAutoHoa: {path: "/auto-hoa/:autoHoaId"},

    Associations: {path: "/associations"},
    AssociationForm: {path: "/associations/create"},
    AssociationDetailsForm: {path: "/associations/:associationId"},

    Teams: {path: "/teams"},
    TeamForm: {path: '/teams/create'},
    TeamDetailPage: {path: '/teams/:teamId'},

    ImportVersion: {path: "/import-versions"},

    RegisteredScrapers: {path: "/registered-scrapers"},

    Codes: {path: "/codes"},
    CodeForm: {path: '/codes/create'},
    UpdateCodeFrom: {path: '/codes/:codeId'},

    QcRules: {path: "/qc-logics"},
    QcRuleForm: {path: '/qc-logics/create'},
    UpdateQcRule: {path: '/qc-logics/:qcLogicId'},

    NoteRules: {path: "/note-logics"},
    AddNoteRule: {path: '/note-logics/create'},
    UpdateNoteRule: {path: '/note-logics/:noteLogicId'},

    ScraperModule: {path: '/scrapers-modules'},
    AddScraperModule: {path: '/scrapers-modules/create'},
    UpdateScraperModule: {path: '/scrapers-modules/:scrapersModuleId'},

    Payments: {path: '/payments'},
    OrderEvents: {path: '/order-events'},

    HoaFeetype:{path: '/hoa-fee-types'},

    AccountTranslation: {path: '/account-translations'},
    AddAccountTranslation: {path: '/account-translations/create'},
    UpdateAccountTranslation: {path: '/account-translations/:accountTranslationId'},

    RecordAudit: {path: '/record-audit'},
    RecordAuditDetail: {path: '/record-audit/:auditId'},


    ErrorLogs: {path: '/error-Logs'},

    CannedNotes: {path: '/canned-notes'},
    CreateCannedNotes: {path: '/canned-notes/create'},
    UpdateCannedNotes: {path: '/canned-notes/:cannedId'},


    FollowUpTypes: {path: '/follow-up-types'},
    CreateFollowUpTypes: {path: '/follow-up-types/create'},
    UpdateFollowUpTypes: {path: '/follow-up-types/:typeId'},

    LinkedAccount: {path: '/linked-accounts'},
    AddLinkedAccountForm: {path: '/linked-accounts/create'},
    UpdateLinkedAccountForm: {path: '/linked-accounts/:linkedAccountId'},

    BillRelease: {path: '/bill-releases'},
    AddBillReleaseForm: {path: '/bill-releases/create'},
    UpdateBillReleaseForm: {path: '/bill-releases/:billReleaseId'},

    ExternalBillRelease: {path: '/external/bill-releases'},

    ThirdPartyProviders:{path : '/third-party-providers'},
    AddThirdPartyProviderForm:{path : '/third-party-providers/create'},
    ThirdPartyProviderDetailPage:{path : '/third-party-providers/:thirdPartyProviderId'},

    TaxRoundingConfigurations:{path : '/tax-rounding-configurations'},
    AddTaxRoundingConfigurationForm:{path : '/tax-rounding-configurations/create'},
    UpdateTaxRoundingConfigurationForm:{path : '/tax-rounding-configurations/:configId'}
}