import axios from "axios";
import {showErrorToast, showInfoToast, showSuccessToast} from "../../common/components/Toast";
import {history} from "../../common/helpers/history";
import {alertActions} from "../alert/alert";
import exceptionHandler, {responseDTOExceptionHandler} from "../../common/helpers/exceptionHandler";
import {
    getPrimitiveArray,
    handleExportError,
    isUserHaveAccess,
    loadPdf,
    removeCertainKeys,
    resourceToFile,
    serialize,
    sortOrderResult
} from "../../common/helpers/utils";
import {errorLogActions} from "../admin/error/errorLogs";
import {homeActions} from "../home/home";

//export actions
export const orderActions = {
    getAllData,
    getCloneAccountsData,
    getProductTaskListAction,
    isPdfExistAction,
    getAllFileDataNew,
    getAllFileData,
    getAllPdfDocumentForCombiningAction,
    getAllFileDataOld,
    submitOrderAction,
    getOrderByIdAction,
    getOrderNotesAction,
    getOrderResultByOrderIdAction,
    getHoaOrderResultByOrderIdAction,
    getOrderLogsAction,
    getOrderNotesByTypeAction,
    getAssignmentLogAction,
    generateTgxPdfAction,
    getOrderListByCustomerIdAction,
    getOrderLatestPdfAction,
    updateNoteVisibilityAction,
    updatePropertySkipAutomationAction,
    createOrderNoteAction,
    saveOrderResultAction,
    saveHoaOrderResultAction,
    getOrderDocumentListAction,
    updateDocVisibilityAction,
    uploadMultipleOrderFilesAction,
    updateOrderStatusAction,
    exportData,
    getTaxSuitCollectorData,
    generateHoaPdfAction,
    cancelOrderOnVendorAction,
    deleteNoteAction,
    getSimilarTaxAccountAction,
    getSimilarHoaAccountAction,
    deliverPdfAction,
    combineFilesAction,
    sendOrderToVendorAction,
    updateProductStatusAction,
    updateVendorPropertyResultAction,
    updateTaxAccountsAction,
    refreshRollAction,
    updateProductsStatusAction,
    computeOrderStatusAction,
    updateSubdivisionNameAction,
    resolveOrderLogAction,
    getProductQcLogicResultListAction,
    getPropertyPickerResultAction,
    populateCadInformationAction,
    updatePropertyResultsAction,
    checkTaxOrderResultValidAction,
    checkHoaOrderResultValidAction,
    checkPrimaryCollectorExistAction,
    cancelOrderOnLocateAction,
    addParentAccountsForSubdivisionAction,
    acknowledgeQcLogicAction,
    cancelOrderAction,
    markIssueOrderAction,
    startProductFollowUpAction,
    endProductFollowUpAction,
    updateProductOverridePriceAction,
    markAsRushOrderAction,
    parseImportedDataAction,
    getContactEmailAction,
    markedProductFilesAction,
    unMarkedProductFilesAction,
    getCertificateOptionsByOrderIdAction,
    createMultipleOrderAction,
    findOrderResultHistoryByProductIdAction,
    getLinkedAccountsAction,
    deleteFileAction,
    getOrderSelectedDocumentListAction,
    updateUnLockedProductsFromCustomerAction,
    generateSOAPdfAction,
    sendSOAPdfByEmailAction,
    markAsCustomerRetrievedAction,
    createToDoAction,
    updateToDoAction,
    updateCustomerHoldAction,
    updateToDoCompletionStatusAction,
    getOrderToDoListAction,
    removeToDoAction,
    getCustomerUploadedOrderDocumentListAction


}

//export constants
export const orderConstants = {

    GET_ALL_ORDERS_REQUEST: 'GET_ALL_ORDERS_REQUEST',
    GET_ALL_ORDERS_SUCCESS: 'GET_ALL_ORDERS_SUCCESS',
    GET_ALL_ORDERS_FAILURE: 'GET_ALL_ORDERS_FAILURE',

    GET_ALL_NEW_DOCUMENTS_REQUEST: 'GET_ALL_NEW_DOCUMENTS_REQUEST',
    GET_ALL_NEW_DOCUMENTS_SUCCESS: 'GET_ALL_NEW_DOCUMENTS_SUCCESS',
    GET_ALL_NEW_DOCUMENTS_FAILURE: 'GET_ALL_NEW_DOCUMENTS_FAILURE',
    GET_ALL_NEW_DOCUMENTS_REST: 'GET_ALL_NEW_DOCUMENTS_REST',

    GET_ALL_OLD_DOCUMENTS_REQUEST: 'GET_ALL_OLD_DOCUMENTS_REQUEST',
    GET_ALL_OLD_DOCUMENTS_SUCCESS: 'GET_ALL_OLD_DOCUMENTS_SUCCESS',
    GET_ALL_OLD_DOCUMENTS_FAILURE: 'GET_ALL_OLD_DOCUMENTS_FAILURE',
    GET_ALL_OLD_DOCUMENTS_REST: 'GET_ALL_OLD_DOCUMENTS_REST',

    GET_ALL_DOCUMENTS_REQUEST: 'GET_ALL_DOCUMENTS_REQUEST',
    GET_ALL_DOCUMENTS_SUCCESS: 'GET_ALL_DOCUMENTS_SUCCESS',
    GET_ALL_DOCUMENTS_FAILURE: 'GET_ALL_DOCUMENTS_FAILURE',
    GET_ALL_DOCUMENTS_REST: 'GET_ALL_DOCUMENTS_REST',


    GET_ALL_DOCUMENTS_FOR_COMBINE_REQUEST: 'GET_ALL_DOCUMENTS_FOR_COMBINE_REQUEST',
    GET_ALL_DOCUMENTS_FOR_COMBINE_SUCCESS: 'GET_ALL_DOCUMENTS_FOR_COMBINE_SUCCESS',
    GET_ALL_DOCUMENTS_FOR_COMBINE_FAILURE: 'GET_ALL_DOCUMENTS_FOR_COMBINE_FAILURE',
    GET_ALL_DOCUMENTS_FOR_COMBINE_REST: 'GET_ALL_DOCUMENTS_FOR_COMBINE_REST',

    CREATE_ORDER_REQUEST: 'CREATE_ORDER_REQUEST',
    CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
    CREATE_ORDER_FAILURE: 'CREATE_ORDER_FAILURE',

    GET_ORDER_BY_ID_REQUEST: 'GET_ORDER_BY_ID_REQUEST',
    GET_ORDER_BY_ID_SUCCESS: 'GET_ORDER_BY_ID_SUCCESS',
    GET_ORDER_BY_ID_FAILURE: 'GET_ORDER_BY_ID_FAILURE',
    ORDER_BY_ID_RESET: 'ORDER_BY_ID_RESET',

    GET_ORDER_NOTES_REQUEST: 'GET_ORDER_NOTES_REQUEST',
    GET_ORDER_NOTES_SUCCESS: 'GET_ORDER_NOTES_SUCCESS',
    GET_ORDER_NOTES_FAILURE: 'GET_ORDER_NOTES_FAILURE',

    GET_ORDER_NOTES_BY_TYPE_REQUEST: 'GET_ORDER_NOTES_BY_TYPE_REQUEST',
    GET_ORDER_NOTES_BY_TYPE_SUCCESS: 'GET_ORDER_NOTES_BY_TYPE_SUCCESS',
    GET_ORDER_NOTES_BY_TYPE_FAILURE: 'GET_ORDER_NOTES_BY_TYPE_FAILURE',

    GET_ORDER_LOGS_REQUEST: 'GET_ORDER_LOGS_REQUEST',
    GET_ORDER_LOGS_SUCCESS: 'GET_ORDER_LOGS_SUCCESS',
    GET_ORDER_LOGS_FAILURE: 'GET_ORDER_LOGS_FAILURE',

    GET_ORDER_ASSIGNMENT_LOGS_REQUEST: 'GET_ORDER_ASSIGNMENT_LOGS_REQUEST',
    GET_ORDER_ASSIGNMENT_LOGS_SUCCESS: 'GET_ORDER_ASSIGNMENT_LOGS_SUCCESS',
    GET_ORDER_ASSIGNMENT_LOGS_FAILURE: 'GET_ORDER_ASSIGNMENT_LOGS_FAILURE',

    GET_ORDER_LIST_BY_CUSTOMER_ID_REQUEST: 'GET_ORDER_LIST_BY_CUSTOMER_ID_REQUEST',
    GET_ORDER_LIST_BY_CUSTOMER_ID_SUCCESS: 'GET_ORDER_LIST_BY_CUSTOMER_ID_SUCCESS',
    GET_ORDER_LIST_BY_CUSTOMER_ID_FAILURE: 'GET_ORDER_LIST_BY_CUSTOMER_ID_FAILURE',

    UPDATE_NOTE_VISIBILITY_REQUEST: 'UPDATE_NOTE_VISIBILITY_REQUEST',
    UPDATE_NOTE_VISIBILITY_SUCCESS: 'UPDATE_NOTE_VISIBILITY_SUCCESS',
    UPDATE_NOTE_VISIBILITY_FAILURE: 'UPDATE_NOTE_VISIBILITY_FAILURE',

    CREATE_ORDER_NOTE_REQUEST: 'CREATE_ORDER_NOTE_REQUEST',
    CREATE_ORDER_NOTE_SUCCESS: 'CREATE_ORDER_NOTE_SUCCESS',
    CREATE_ORDER_NOTE_FAILURE: 'CREATE_ORDER_NOTE_FAILURE',

    GET_ORDER_DOCUMENT_LIST_REQUEST: 'GET_ORDER_DOCUMENT_LIST_REQUEST',
    GET_ORDER_DOCUMENT_LIST_SUCCESS: 'GET_ORDER_DOCUMENT_LIST_SUCCESS',
    GET_ORDER_DOCUMENT_LIST_FAILURE: 'GET_ORDER_DOCUMENT_LIST_FAILURE',

    UPDATE_DOC_VISIBILITY_REQUEST: 'UPDATE_DOC_VISIBILITY_REQUEST',
    UPDATE_DOC_VISIBILITY_SUCCESS: 'UPDATE_DOC_VISIBILITY_SUCCESS',
    UPDATE_DOC_VISIBILITY_FAILURE: 'UPDATE_DOC_VISIBILITY_FAILURE',

    UPLOAD_DOCUMENT_REQUEST: 'UPLOAD_DOCUMENT_REQUEST',
    UPLOAD_DOCUMENT_SUCCESS: 'UPLOAD_DOCUMENT_SUCCESS',
    UPLOAD_DOCUMENT_FAILURE: 'UPLOAD_DOCUMENT_FAILURE',
    UPLOAD_DOCUMENT_RESET: 'UPLOAD_DOCUMENT_RESET',

    GET_ORDERS_RESULT_REQUEST: 'GET_ORDERS_RESULT_REQUEST',
    GET_ORDERS_RESULT_SUCCESS: 'GET_ORDERS_RESULT_SUCCESS',
    GET_ORDERS_RESULT_FAILURE: 'GET_ORDERS_RESULT_FAILURE',

    CREATE_ORDER_RESULT_REQUEST: 'CREATE_ORDER_RESULT_REQUEST',
    CREATE_ORDER_RESULT_SUCCESS: 'CREATE_ORDER_RESULT_SUCCESS',
    CREATE_ORDER_RESULT_FAILURE: 'CREATE_ORDER_RESULT_FAILURE',

    CREATE_HOA_ORDER_RESULT_REQUEST: 'CREATE_HOA_ORDER_RESULT_REQUEST',
    CREATE_HOA_ORDER_RESULT_SUCCESS: 'CREATE_HOA_ORDER_RESULT_SUCCESS',
    CREATE_HOA_ORDER_RESULT_FAILURE: 'CREATE_HOA_ORDER_RESULT_FAILURE',

    EXPORT_ORDER_DATA_REQUEST: 'EXPORT_ORDER_DATA_REQUEST',
    EXPORT_ORDER_DATA_SUCCESS: 'EXPORT_ORDER_DATA_SUCCESS',
    EXPORT_ORDER_DATA_FAILURE: 'EXPORT_ORDER_DATA_FAILURE',

    GENERATE_TGX_PDF_REQUEST: 'GENERATE_TGX_PDF_REQUEST',
    GENERATE_TGX_PDF_SUCCESS: 'GENERATE_TGX_PDF_SUCCESS',
    GENERATE_TGX_PDF_FAILURE: 'GENERATE_TGX_PDF_FAILURE',

    GENERATE_SOA_PDF_REQUEST: 'GENERATE_SOA_PDF_REQUEST',
    GENERATE_SOA_PDF_SUCCESS: 'GENERATE_SOA_PDF_SUCCESS',
    GENERATE_SOA_PDF_FAILURE: 'GENERATE_SOA_PDF_FAILURE',


    UPDATE_ORDER_STATUS_REQUEST: 'UPDATE_ORDER_STATUS_REQUEST',
    UPDATE_ORDER_STATUS_SUCCESS: 'UPDATE_ORDER_STATUS_SUCCESS',
    UPDATE_ORDER_STATUS_FAILURE: 'UPDATE_ORDER_STATUS_FAILURE',

    UPDATE_PRODUCT_STATUS_REQUEST: 'UPDATE_PRODUCT_STATUS_REQUEST',
    UPDATE_PRODUCT_STATUS_SUCCESS: 'UPDATE_PRODUCT_STATUS_SUCCESS',
    UPDATE_PRODUCT_STATUS_FAILURE: 'UPDATE_PRODUCT_STATUS_FAILURE',

    GET_HOA_ORDERS_RESULT_REQUEST: 'GET_HOA_ORDERS_RESULT_REQUEST',
    GET_HOA_ORDERS_RESULT_SUCCESS: 'GET_HOA_ORDERS_RESULT_SUCCESS',
    GET_HOA_ORDERS_RESULT_FAILURE: 'GET_HOA_ORDERS_RESULT_FAILURE',

    GET_ORDER_LATEST_PDF_REQUEST: 'GET_ORDER_LATEST_PDF_REQUEST',
    GET_ORDER_LATEST_PDF_SUCCESS: 'GET_ORDER_LATEST_PDF_SUCCESS',
    GET_ORDER_LATEST_PDF_FAILURE: 'GET_ORDER_LATEST_PDF_FAILURE',

    GET_COLLECTOR_LIST_REQUEST: 'GET_COLLECTOR_LIST_REQUEST',
    GET_COLLECTOR_LIST_SUCCESS: 'GET_COLLECTOR_LIST_SUCCESS',
    GET_COLLECTOR_LIST_FAILURE: 'GET_COLLECTOR_LIST_FAILURE',

    GET_SIMILAR_TAX_ACCOUNTS_LIST_REQUEST: 'GET_SIMILAR_TAX_ACCOUNTS_LIST_REQUEST',
    GET_SIMILAR_TAX_ACCOUNTS_LIST_SUCCESS: 'GET_SIMILAR_TAX_ACCOUNTS_LIST_SUCCESS',
    GET_SIMILAR_TAX_ACCOUNTS_LIST_FAILURE: 'GET_SIMILAR_TAX_ACCOUNTS_LIST_FAILURE',

    GET_SIMILAR_HOA_ACCOUNTS_LIST_REQUEST: 'GET_SIMILAR_HOA_ACCOUNTS_LIST_REQUEST',
    GET_SIMILAR_HOA_ACCOUNTS_LIST_SUCCESS: 'GET_SIMILAR_HOA_ACCOUNTS_LIST_SUCCESS',
    GET_SIMILAR_HOA_ACCOUNTS_LIST_FAILURE: 'GET_SIMILAR_HOA_ACCOUNTS_LIST_FAILURE',


    GET_CLONE_TAX_ACCOUNTS_LIST_REQUEST: 'GET_CLONE_TAX_ACCOUNTS_LIST_REQUEST',
    GET_CLONE_TAX_ACCOUNTS_LIST_SUCCESS: 'GET_CLONE_TAX_ACCOUNTS_LIST_SUCCESS',
    GET_CLONE_TAX_ACCOUNTS_LIST_FAILURE: 'GET_CLONE_TAX_ACCOUNTS_LIST_FAILURE',

    GENERATE_HOA_PDF_REQUEST: 'GENERATE_HOA_PDF_REQUEST',
    GENERATE_HOA_PDF_SUCCESS: 'GENERATE_HOA_PDF_SUCCESS',
    GENERATE_HOA_PDF_FAILURE: 'GENERATE_HOA_PDF_FAILURE',

    DELETE_NOTE_REQUEST: 'DELETE_NOTE_REQUEST',
    DELETE_NOTE_SUCCESS: 'DELETE_NOTE_SUCCESS',
    DELETE_NOTE_FAILURE: 'DELETE_NOTE_FAILURE',

    DELETE_ORDER_ON_VENDOR_REQUEST: 'DELETE_ORDER_ON_VENDOR_REQUEST',
    DELETE_ORDER_ON_VENDOR_SUCCESS: 'DELETE_ORDER_ON_VENDOR_SUCCESS',
    DELETE_ORDER_ON_VENDOR_FAILURE: 'DELETE_ORDER_ON_VENDOR_FAILURE',

    DELETE_FILE_REQUEST: 'DELETE_FILE_REQUEST',
    DELETE_FILE_SUCCESS: 'DELETE_FILE_SUCCESS',
    DELETE_FILE_FAILURE: 'DELETE_FILE_FAILURE',

    PDF_DELIVERED_REQUEST: 'PDF_DELIVERED_REQUEST',
    PDF_DELIVERED_SUCCESS: 'PDF_DELIVERED_SUCCESS',
    PDF_DELIVERED_FAILURE: 'PDF_DELIVERED_FAILURE',

    COMBINE_FILES_REQUEST: 'COMBINE_FILES_REQUEST',
    COMBINE_FILES_SUCCESS: 'COMBINE_FILES_SUCCESS',
    COMBINE_FILES_FAILURE: 'COMBINE_FILES_FAILURE',

    SUBMIT_VENDOR_LIST_REQUEST: 'SUBMIT_VENDOR_LIST_REQUEST',
    SUBMIT_VENDOR_LIST_SUCCESS: 'SUBMIT_VENDOR_LIST_SUCCESS',
    SUBMIT_VENDOR_LIST_FAILURE: 'SUBMIT_VENDOR_LIST_FAILURE',

    UPDATE_VENDOR_PROPERTY_RESULT_RESPONSE_REQUEST: 'UPDATE_VENDOR_PROPERTY_RESULT_RESPONSE_REQUEST',
    UPDATE_VENDOR_PROPERTY_RESULT_RESPONSE_SUCCESS: 'UPDATE_VENDOR_PROPERTY_RESULT_RESPONSE_SUCCESS',
    UPDATE_VENDOR_PROPERTY_RESULT_RESPONSE_FAILURE: 'UPDATE_VENDOR_PROPERTY_RESULT_RESPONSE_FAILURE',

    UPDATE_TAX_ACCOUNTS_REQUEST: 'UPDATE_TAX_ACCOUNTS_REQUEST',
    UPDATE_TAX_ACCOUNTS_SUCCESS: 'UPDATE_TAX_ACCOUNTS_SUCCESS',
    UPDATE_TAX_ACCOUNTS_FAILURE: 'UPDATE_TAX_ACCOUNTS_FAILURE',

    REFRESH_ROLL_REQUEST: 'REFRESH_ROLL_REQUEST',
    REFRESH_ROLL_SUCCESS: 'REFRESH_ROLL_SUCCESS',
    REFRESH_ROLL_FAILURE: 'REFRESH_ROLL_FAILURE',

    UPDATE_PRODUCTS_STATUS_REQUEST: 'UPDATE_PRODUCTS_STATUS_REQUEST',
    UPDATE_PRODUCTS_STATUS_SUCCESS: 'UPDATE_PRODUCTS_STATUS_SUCCESS',
    UPDATE_PRODUCTS_STATUS_FAILURE: 'UPDATE_PRODUCTS_STATUS_FAILURE',

    COMPUTE_ORDER_STATUS_REQUEST: 'COMPUTE_ORDER_STATUS_REQUEST',
    COMPUTE_ORDER_STATUS_SUCCESS: 'COMPUTE_ORDER_STATUS_SUCCESS',
    COMPUTE_ORDER_STATUS_FAILURE: 'COMPUTE_ORDER_STATUS_FAILURE',

    UPDATE_SUBDIVISION_NAME_REQUEST: 'UPDATE_SUBDIVISION_NAME_REQUEST',
    UPDATE_SUBDIVISION_NAME_SUCCESS: 'UPDATE_SUBDIVISION_NAME_SUCCESS',
    UPDATE_SUBDIVISION_NAME_FAILURE: 'UPDATE_SUBDIVISION_NAME_FAILURE',

    GET_PRODUCT_QC_LOGIC_RESULT_REQUEST: 'GET_PRODUCT_QC_LOGIC_RESULT_REQUEST',
    GET_PRODUCT_QC_LOGIC_RESULT_SUCCESS: 'GET_PRODUCT_QC_LOGIC_RESULT_SUCCESS',
    GET_PRODUCT_QC_LOGIC_RESULT_FAILURE: 'GET_PRODUCT_QC_LOGIC_RESULT_FAILURE',

    RESOLVE_ORDER_LOG_REQUEST: 'RESOLVE_ORDER_LOG_REQUEST',
    RESOLVE_ORDER_LOG_SUCCESS: 'RESOLVE_ORDER_LOG_SUCCESS',
    RESOLVE_ORDER_LOG_FAILURE: 'RESOLVE_ORDER_LOG_FAILURE',

    GET_PROPERTY_PICKER_RESULT_REQUEST: 'GET_PROPERTY_PICKER_RESULT_REQUEST',
    GET_PROPERTY_PICKER_RESULT_SUCCESS: 'GET_PROPERTY_PICKER_RESULT_SUCCESS',
    GET_PROPERTY_PICKER_RESULT_FAILURE: 'GET_PROPERTY_PICKER_RESULT_FAILURE',

    GET_CAD_INFORMATION_RESULT_REQUEST: 'GET_CAD_INFORMATION_RESULT_REQUEST',
    GET_CAD_INFORMATION_RESULT_SUCCESS: 'GET_CAD_INFORMATION_RESULT_SUCCESS',
    GET_CAD_INFORMATION_RESULT_FAILURE: 'GET_CAD_INFORMATION_RESULT_FAILURE',

    UPDATE_PROPERTY_RESULT_REQUEST: 'UPDATE_PROPERTY_RESULT_REQUEST',
    UPDATE_PROPERTY_RESULT_SUCCESS: 'UPDATE_PROPERTY_RESULT_SUCCESS',
    UPDATE_PROPERTY_RESULT_FAILURE: 'UPDATE_PROPERTY_RESULT_FAILURE',

    CHECK_VALID_TAX_CERT_ORDER_RESULT_REQUEST: 'CHECK_VALID_TAX_CERT_ORDER_RESULT_REQUEST',
    CHECK_VALID_TAX_CERT_ORDER_RESULT_SUCCESS: 'CHECK_VALID_TAX_CERT_ORDER_RESULT_SUCCESS',
    CHECK_VALID_TAX_CERT_ORDER_RESULT_FAILURE: 'CHECK_VALID_TAX_CERT_ORDER_RESULT_FAILURE',

    CHECK_VALID_HOA_CERT_ORDER_RESULT_REQUEST: 'CHECK_VALID_HOA_CERT_ORDER_RESULT_REQUEST',
    CHECK_VALID_HOA_CERT_ORDER_RESULT_SUCCESS: 'CHECK_VALID_HOA_CERT_ORDER_RESULT_SUCCESS',
    CHECK_VALID_HOA_CERT_ORDER_RESULT_FAILURE: 'CHECK_VALID_HOA_CERT_ORDER_RESULT_FAILURE',

    IS_PRIMARY_COLLECTOR_EXIST_REQUEST: 'IS_PRIMARY_COLLECTOR_EXIST_REQUEST',
    IS_PRIMARY_COLLECTOR_EXIST_SUCCESS: 'IS_PRIMARY_COLLECTOR_EXIST_SUCCESS',
    IS_PRIMARY_COLLECTOR_EXIST_FAILURE: 'IS_PRIMARY_COLLECTOR_EXIST_FAILURE',

    GET_PRODUCT_TASK_LIST_REQUEST: 'GET_PRODUCT_TASK_LIST_REQUEST',
    GET_PRODUCT_TASK_LIST_SUCCESS: 'GET_PRODUCT_TASK_LIST_SUCCESS',
    GET_PRODUCT_TASK_LIST_FAILURE: 'GET_PRODUCT_TASK_LIST_FAILURE',

    CANCEL_ORDER_ON_LOCATE_REQUEST: 'CANCEL_ORDER_ON_LOCATE_REQUEST',
    CANCEL_ORDER_ON_LOCATE_SUCCESS: 'CANCEL_ORDER_ON_LOCATE_SUCCESS',
    CANCEL_ORDER_ON_LOCATE_FAILURE: 'CANCEL_ORDER_ON_LOCATE_FAILURE',

    ACKNOWLEDGED_QC_LOGIC_REQUEST: 'ACKNOWLEDGED_QC_LOGIC_REQUEST',
    ACKNOWLEDGED_QC_LOGIC_SUCCESS: 'ACKNOWLEDGED_QC_LOGIC_SUCCESS',
    ACKNOWLEDGED_QC_LOGIC_FAILURE: 'ACKNOWLEDGED_QC_LOGIC_FAILURE',

    ADD_PARENT_ACCOUNT_REQUEST: 'ADD_PARENT_ACCOUNT_REQUEST',
    ADD_PARENT_ACCOUNT_SUCCESS: 'ADD_PARENT_ACCOUNT_SUCCESS',
    ADD_PARENT_ACCOUNT_FAILURE: 'ADD_PARENT_ACCOUNT_FAILURE',

    IS_PDF_EXIST_REQUEST: 'IS_PDF_EXIST_REQUEST',
    IS_PDF_EXIST_SUCCESS: 'IS_PDF_EXIST_SUCCESS',
    IS_PDF_EXIST_FAILURE: 'IS_PDF_EXIST_FAILURE',

    CANCEL_ORDER_REQUEST: 'CANCEL_ORDER_REQUEST',
    CANCEL_ORDER_SUCCESS: 'CANCEL_ORDER_SUCCESS',
    CANCEL_ORDER_FAILURE: 'CANCEL_ORDER_FAILURE',

    MARKS_ISSUE_ORDER_REQUEST: 'MARKS_ISSUE_ORDER_REQUEST',
    MARKS_ISSUE_ORDER_SUCCESS: 'MARKS_ISSUE_ORDER_SUCCESS',
    MARKS_ISSUE_ORDER_FAILURE: 'MARKS_ISSUE_ORDER_FAILURE',

    PARSE_IMPORT_DATA_REQUEST: 'PARSE_IMPORT_DATA_REQUEST',
    PARSE_IMPORT_DATA_SUCCESS: 'PARSE_IMPORT_DATA_SUCCESS',
    PARSE_IMPORT_DATA_FAILURE: 'PARSE_IMPORT_DATA_FAILURE',

    START_FOLLOW_UP_REQUEST: 'START_FOLLOW_UP_REQUEST',
    START_FOLLOW_UP_SUCCESS: 'START_FOLLOW_UP_SUCCESS',
    START_FOLLOW_UP_FAILURE: 'START_FOLLOW_UP_FAILURE',

    END_FOLLOW_UP_REQUEST: 'END_FOLLOW_UP_REQUEST',
    END_FOLLOW_UP_SUCCESS: 'END_FOLLOW_UP_SUCCESS',
    END_FOLLOW_UP_FAILURE: 'END_FOLLOW_UP_FAILURE',

    UPDATE_PRODUCT_OVERRIDE_PRICE_REQUEST: 'UPDATE_PRODUCT_OVERRIDE_PRICE_REQUEST',
    UPDATE_PRODUCT_OVERRIDE_PRICE_SUCCESS: 'UPDATE_PRODUCT_OVERRIDE_PRICE_SUCCESS',
    UPDATE_PRODUCT_OVERRIDE_PRICE_FAILURE: 'UPDATE_PRODUCT_OVERRIDE_PRICE_FAILURE',

    MARK_AS_RUSH_ORDERS_REQUEST: 'MARK_AS_RUSH_ORDERS_REQUEST',
    MARK_AS_RUSH_ORDERS_SUCCESS: 'MARK_AS_RUSH_ORDERS_SUCCESS',
    MARK_AS_RUSH_ORDERS_FAILURE: 'MARK_AS_RUSH_ORDERS_FAILURE',

    MARK_PRODUCT_FILES_ORDERS_REQUEST: 'MARK_PRODUCT_FILES_ORDERS_REQUEST',
    MARK_PRODUCT_FILES_ORDERS_SUCCESS: 'MARK_PRODUCT_FILES_ORDERS_SUCCESS',
    MARK_PRODUCT_FILES_ORDERS_FAILURE: 'MARK_PRODUCT_FILES_ORDERS_FAILURE',

    UNMARK_PRODUCT_FILES_ORDERS_REQUEST: 'UNMARK_PRODUCT_FILES_ORDERS_REQUEST',
    UNMARK_PRODUCT_FILES_ORDERS_SUCCESS: 'UNMARK_PRODUCT_FILES_ORDERS_SUCCESS',
    UNMARK_PRODUCT_FILES_ORDERS_FAILURE: 'UNMARK_PRODUCT_FILES_ORDERS_FAILURE',

    GET_CONTACT_EMAIL_REQUEST: 'GET_CONTACT_EMAIL_REQUEST',
    GET_CONTACT_EMAIL_SUCCESS: 'GET_CONTACT_EMAIL_SUCCESS',
    GET_CONTACT_EMAIL_FAILURE: 'GET_CONTACT_EMAIL_FAILURE',
    GET_CONTACT_EMAIL_RESET: 'GET_CONTACT_EMAIL_RESET',

    GET_CERTIFICATE_OPTIONS_BY_ORDER_ID_REQUEST: 'GET_CERTIFICATE_OPTIONS_BY_ORDER_ID_REQUEST',
    GET_CERTIFICATE_OPTIONS_BY_ORDER_ID_SUCCESS: 'GET_CERTIFICATE_OPTIONS_BY_ORDER_ID_SUCCESS',
    GET_CERTIFICATE_OPTIONS_BY_ORDER_ID_FAILURE: 'GET_CERTIFICATE_OPTIONS_BY_ORDER_ID_FAILURE',

    CREATE_MULTIPLE_ORDER_REQUEST: 'CREATE_MULTIPLE_ORDER_REQUEST',
    CREATE_MULTIPLE_ORDER_SUCCESS: 'CREATE_MULTIPLE_ORDER_SUCCESS',
    CREATE_MULTIPLE_ORDER_FAILURE: 'CREATE_MULTIPLE_ORDER_FAILURE',
    CREATE_MULTIPLE_ORDER_RESET: 'CREATE_MULTIPLE_ORDER_RESET',

    GET_ORDER_RESULT_HISTORY_BY_PRODUCT_REQUEST: 'GET_ORDER_RESULT_HISTORY_BY_PRODUCT_REQUEST',
    GET_ORDER_RESULT_HISTORY_BY_PRODUCT_SUCCESS: 'GET_ORDER_RESULT_HISTORY_BY_PRODUCT_SUCCESS',
    GET_ORDER_RESULT_HISTORY_BY_PRODUCT_FAILURE: 'GET_ORDER_RESULT_HISTORY_BY_PRODUCT_FAILURE',


    GET_LINKED_ACCOUNTS_REQUEST: 'GET_LINKED_ACCOUNTS_REQUEST',
    GET_LINKED_ACCOUNTS_SUCCESS: 'GET_LINKED_ACCOUNTS_SUCCESS',
    GET_LINKED_ACCOUNTS_FAILURE: 'GET_LINKED_ACCOUNTS_FAILURE',
    GET_LINKED_ACCOUNTS_RESET: 'GET_LINKED_ACCOUNTS_RESET',

    GET_ORDER_SELECTED_FILES_REQUEST: 'GET_ORDER_SELECTED_FILES_REQUEST',
    GET_ORDER_SELECTED_FILES_SUCCESS: 'GET_ORDER_SELECTED_FILES_SUCCESS',
    GET_ORDER_SELECTED_FILES_FAILURE: 'GET_ORDER_SELECTED_FILES_FAILURE',
    GET_ORDER_SELECTED_FILES_RESET: 'GET_ORDER_SELECTED_FILES_RESET',

    UNLOCKED_FROM_CUSTOMER_REQUEST: 'UNLOCKED_FROM_CUSTOMER_REQUEST',
    UNLOCKED_FROM_CUSTOMER_SUCCESS: 'UNLOCKED_FROM_CUSTOMER_SUCCESS',
    UNLOCKED_FROM_CUSTOMER_FAILURE: 'UNLOCKED_FROM_CUSTOMER_FAILURE',

    SEND_SOA_REQUEST: 'SEND_SOA_REQUEST',
    SEND_SOA_SUCCESS: 'SEND_SOA_SUCCESS',
    SEND_SOA_FAILURE: 'SEND_SOA_FAILURE',

    PROPERTY_SKIP_AUTOMATION_REQUEST: 'PROPERTY_SKIP_AUTOMATION_REQUEST',
    PROPERTY_SKIP_AUTOMATION_SUCCESS: 'PROPERTY_SKIP_AUTOMATION_SUCCESS',
    PROPERTY_SKIP_AUTOMATION_FAILURE: 'PROPERTY_SKIP_AUTOMATION_FAILURE',

    MARK_AS_RETRIEVED_REQUEST: 'MARK_AS_RETRIEVED_REQUEST',
    MARK_AS_RETRIEVED_SUCCESS: 'MARK_AS_RETRIEVED_SUCCESS',
    MARK_AS_RETRIEVED_FAILURE: 'MARK_AS_RETRIEVED_FAILURE',

    ADD_TODO_ITEM_REQUEST: 'ADD_TODO_ITEM_REQUEST',
    ADD_TODO_ITEM_SUCCESS: 'ADD_TODO_ITEM_SUCCESS',
    ADD_TODO_ITEM_FAILURE: 'ADD_TODO_ITEM_FAILURE',

    REMOVE_TODO_ITEM_REQUEST: 'REMOVE_TODO_ITEM_REQUEST',
    REMOVE_TODO_ITEM_SUCCESS: 'REMOVE_TODO_ITEM_SUCCESS',
    REMOVE_TODO_ITEM_FAILURE: 'REMOVE_TODO_ITEM_FAILURE',

    UPDATE_TODO_COMPLETION_STATUS_REQUEST: 'UPDATE_TODO_COMPLETION_STATUS_REQUEST',
    UPDATE_TODO_COMPLETION_STATUS_SUCCESS: 'UPDATE_TODO_COMPLETION_STATUS_SUCCESS',
    UPDATE_TODO_COMPLETION_STATUS_FAILURE: 'UPDATE_TODO_COMPLETION_STATUS_FAILURE',

    UPDATE_TODO_ITEM_REQUEST: 'UPDATE_TODO_ITEM_REQUEST',
    UPDATE_TODO_ITEM_SUCCESS: 'UPDATE_TODO_ITEM_SUCCESS',
    UPDATE_TODO_ITEM_FAILURE: 'UPDATE_TODO_ITEM_FAILURE',

    GET_TODOS_BY_ORDER_ID_REQUEST: 'GET_TODOS_BY_ORDER_ID_REQUEST',
    GET_TODOS_BY_ORDER_ID_SUCCESS: 'GET_TODOS_BY_ORDER_ID_SUCCESS',
    GET_TODOS_BY_ORDER_ID_FAILURE: 'GET_TODOS_BY_ORDER_ID_FAILURE',

    UPDATE_CUSTOMER_HOLD_REQUEST: 'UPDATE_CUSTOMER_HOLD_REQUEST',
    UPDATE_CUSTOMER_HOLD_SUCCESS: 'UPDATE_CUSTOMER_HOLD_SUCCESS',
    UPDATE_CUSTOMER_HOLD_FAILURE: 'UPDATE_CUSTOMER_HOLD_FAILURE',

    GET_CUSTOMER_UPLOADED_ORDER_DOCUMENT_LIST_REQUEST: 'GET_CUSTOMER_UPLOADED_ORDER_DOCUMENT_LIST_REQUEST',
    GET_CUSTOMER_UPLOADED_ORDER_DOCUMENT_LIST_SUCCESS: 'GET_CUSTOMER_UPLOADED_ORDER_DOCUMENT_LIST_SUCCESS',
    GET_CUSTOMER_UPLOADED_ORDER_DOCUMENT_LIST_FAILURE: 'GET_CUSTOMER_UPLOADED_ORDER_DOCUMENT_LIST_FAILURE',

}

//export service
export const orderService = {
    getAllOrders,
    getCloneAccountsOrders,
    getAllDocumentNew,
    isPdfExist,
    getAllDocument,
    getAllPdfDocumentForCombining,
    getAllDocumentOld,
    submitOrder,
    saveOrderResultService,
    saveHoaOrderResultService,
    getOrderResultByOrderIdService,
    getHoaOrderResultByOrderIdService,
    getOrderByIdService,
    getOrderNotesService,
    getOrderNotesByTypeService,
    getOrderLogsService,
    getSimilarTaxAccountInfoService,
    getSimilarHoaAccountInfoService,
    getOrderAssignmentLogService,
    generateTgxPdfService,
    generateSOAPdfService,
    generateHoaPdfService,
    getOrderListByCustomerIdService,
    updateNoteVisibilityService,
    updatePropertySkipAutomationService,
    updateTaxAccountsService,
    deleteNoteService,
    deleteFileService,
    createOrderNoteService,
    getOrderDocumentListService,
    getOrderLatestPdfService,
    updateDocVisibilityService,
    uploadDocumentService,
    exportOrderData,
    updateOrderStatusService,
    getTaxSuitCollector,
    deliverPdf,
    combineFiles,
    sendOrderToVendor,
    cancelOrderOnVendor,
    updateProductStatus,
    updateVendorPropertyResultService,
    refreshRoll,
    updateProductsStatusService,
    computeOrderStatusService,
    updateSubdivisionNameService,
    getProductQcLogicResultListService,
    updateResolveLogService,
    getPropertyPickerResultService,
    getCadInformationService,
    updatePropertyResultsService,
    checkTaxOrderResultValidService,
    checkHoaOrderResultValidService,
    checkPrimaryCollectorExistService,
    getProductTaskListsService,
    cancelOrderOnLocateService,
    acknowledgeQcLogicService,
    addParentAccountsForSubdivisionService,
    cancelOrderService,
    markIssueOrderService,
    createProductFollowUpService,
    endFollowUpService,
    updateProductOverridePriceService,
    markRushOrderService,
    parseImportedDataService,
    getContactEmail,
    markedProductFilesService,
    unMarkedProductFilesService,
    getCertificateOptionsByOrderIdService,
    submitMultipleOrderOrderService,
    findOrderResultHistoryByProductIdService,
    linkedAccountsService,
    getOrderSelectedFilesService,
    updateUnLockedProductsFromCustomerService,
    sendSOAPdfByEmailService,
    markAsRetrievedService,
    createToDoService,
    updateToDoService,
    updateCustomerHoldService,
    updateToDoCompletionStatusService,
    getOrderToDoListService,
    removeToDoService,
    getCustomerUploadedOrderDocumentListService
};

{/**************** Actions started here. **********************/
}

function getCertificateOptionsByOrderIdAction(orderId) {
    return dispatch => {
        dispatch(request());

        orderService.getCertificateOptionsByOrderIdService(orderId)
            .then(certificateOptions => dispatch(success(certificateOptions)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_CERTIFICATE_OPTIONS_BY_ORDER_ID_REQUEST}
    }

    function success(certificateOptions) {
        return {type: orderConstants.GET_CERTIFICATE_OPTIONS_BY_ORDER_ID_SUCCESS, certificateOptions}
    }

    function failure(error) {
        return {type: orderConstants.GET_CERTIFICATE_OPTIONS_BY_ORDER_ID_FAILURE, error}
    }
}

function parseImportedDataAction(importData, setDisable, setShow, setImportedData, setIsDataImported, setImportedDataCountyList) {
    return dispatch => {
        dispatch(request());
        orderService.parseImportedDataService(importData)
            .then(data => {
                if (data['errorCode'] === 200) {
                    showSuccessToast("Import Succeeded");
                    setImportedDataCountyList(data['orderRequest']['countyData']);
                    setImportedData(data['orderRequest']['orderRequest']);
                    setIsDataImported(true);
                    dispatch(success(data));
                } else {
                    responseDTOExceptionHandler(data);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            })
            .finally(() => {
                setDisable(false);
                setShow(false);
            });
    };


    function request() {
        return {type: orderConstants.PARSE_IMPORT_DATA_REQUEST}
    }

    function success(data) {
        return {type: orderConstants.PARSE_IMPORT_DATA_SUCCESS, data}
    }

    function failure(error) {
        return {type: orderConstants.PARSE_IMPORT_DATA_FAILURE, error}
    }
}


function getContactEmailAction(order, isReset = false, setLoader = null) {

    return dispatch => {
        if (isReset === false) {
            dispatch(request());
            orderService.getContactEmail(order)
                .then(emails => {
                    if (setLoader !== null) {
                        setLoader(false);
                    }
                    dispatch(success(emails));
                }, error => dispatch(failure(error.toString())));
        } else {
            dispatch(rest());
        }
    };

    function request() {
        return {type: orderConstants.GET_CONTACT_EMAIL_REQUEST}
    }

    function success(emails) {
        return {type: orderConstants.GET_CONTACT_EMAIL_SUCCESS, emails}
    }

    function failure(error) {
        return {type: orderConstants.GET_CONTACT_EMAIL_FAILURE, error}
    }

    function rest() {
        return {type: orderConstants.GET_CONTACT_EMAIL_RESET}
    }

}


function markAsRushOrderAction(orderId, setDisable, setShow) {

    return dispatch => {
        dispatch(request());
        orderService.markRushOrderService(orderId)
            .then(order => {
                if (order['errorCode'] === 200) {
                    showSuccessToast("Order has been marked as an rush successfully!");
                    dispatch(success(order));
                    dispatch(orderActions.getOrderByIdAction(orderId, null, false))
                } else {
                    responseDTOExceptionHandler(order);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            })
            .finally(() => {
                setDisable(false);
                setShow(false);
            });
    };


    function request() {
        return {type: orderConstants.MARK_AS_RUSH_ORDERS_REQUEST}
    }

    function success(orders) {
        return {type: orderConstants.MARK_AS_RUSH_ORDERS_SUCCESS, orders}
    }

    function failure(error) {
        return {type: orderConstants.MARK_AS_RUSH_ORDERS_FAILURE, error}
    }
}

function markIssueOrderAction(orderId, setDisable, setShow) {

    return dispatch => {
        dispatch(request());
        orderService.markIssueOrderService(orderId)
            .then(order => {
                if (order['errorCode'] === 200) {
                    showSuccessToast("Order has been marked as an issue successfully!");
                    dispatch(success(order));
                    dispatch(orderActions.getOrderByIdAction(orderId, null, false))
                } else {
                    responseDTOExceptionHandler(order);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            })
            .finally(() => {
                setDisable(false);
                setShow(false);
            });
    };


    function request() {
        return {type: orderConstants.MARKS_ISSUE_ORDER_REQUEST}
    }

    function success(orders) {
        return {type: orderConstants.MARKS_ISSUE_ORDER_SUCCESS, orders}
    }

    function failure(error) {
        return {type: orderConstants.MARKS_ISSUE_ORDER_FAILURE, error}
    }
}


//get all order action
function getAllData(query) {
    return dispatch => {
        dispatch(request());

        orderService.getAllOrders(query)
            .then(orders => dispatch(success(orders)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_ALL_ORDERS_REQUEST}
    }

    function success(orders) {
        return {type: orderConstants.GET_ALL_ORDERS_SUCCESS, orders}
    }

    function failure(error) {
        return {type: orderConstants.GET_ALL_ORDERS_FAILURE, error}
    }
}


function getCloneAccountsData(query) {
    return dispatch => {
        dispatch(request());

        orderService.getCloneAccountsOrders(query)
            .then(orders => dispatch(success(orders)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_CLONE_TAX_ACCOUNTS_LIST_REQUEST}
    }

    function success(orders) {
        return {type: orderConstants.GET_CLONE_TAX_ACCOUNTS_LIST_SUCCESS, orders}
    }

    function failure(error) {
        return {type: orderConstants.GET_CLONE_TAX_ACCOUNTS_LIST_FAILURE, error}
    }
}

function getProductTaskListAction(productId) {

    return dispatch => {
        dispatch(homeActions.resetReducerAction("getProductTaskListReducer"));
        dispatch(request());
        orderService.getProductTaskListsService(productId)
            .then(tasks => dispatch(success(tasks)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_PRODUCT_TASK_LIST_REQUEST}
    }

    function success(tasks) {
        return {type: orderConstants.GET_PRODUCT_TASK_LIST_SUCCESS, tasks}
    }

    function failure(error) {
        return {type: orderConstants.GET_PRODUCT_TASK_LIST_FAILURE, error}
    }
}


function getAllPdfDocumentForCombiningAction(query, isReset = false) {

    return dispatch => {
        if (isReset === false) {
            dispatch(request());

            orderService.getAllPdfDocumentForCombining(query)
                .then(documents => {
                    dispatch(success(documents))
                }, error => dispatch(failure(error.toString())));
        } else {
            dispatch(rest());
        }
    };

    function request() {
        return {type: orderConstants.GET_ALL_DOCUMENTS_FOR_COMBINE_REQUEST}
    }

    function success(documents) {
        return {type: orderConstants.GET_ALL_DOCUMENTS_FOR_COMBINE_SUCCESS, documents}
    }

    function failure(error) {
        return {type: orderConstants.GET_ALL_DOCUMENTS_FOR_COMBINE_FAILURE, error}
    }

    function rest() {
        return {type: orderConstants.GET_ALL_DOCUMENTS_FOR_COMBINE_REST}
    }
}


function getAllFileData(query, isReset = false) {

    return dispatch => {
        if (isReset === false) {
            dispatch(request());

            orderService.getAllDocument(query)
                .then(documents => {
                    dispatch(success(documents))
                }, error => dispatch(failure(error.toString())));
        } else {
            dispatch(rest());
        }
    };

    function request() {
        return {type: orderConstants.GET_ALL_DOCUMENTS_REQUEST}
    }

    function success(documents) {
        return {type: orderConstants.GET_ALL_DOCUMENTS_SUCCESS, documents}
    }

    function failure(error) {
        return {type: orderConstants.GET_ALL_DOCUMENTS_FAILURE, error}
    }

    function rest() {
        return {type: orderConstants.GET_ALL_DOCUMENTS_REST}
    }
}

function getAllFileDataNew(query, isReset = false) {

    return dispatch => {
        if (isReset === false) {
            dispatch(request());

            orderService.getAllDocumentNew(query)
                .then(documents => dispatch(success(documents)), error => dispatch(failure(error.toString())));
        } else {
            dispatch(rest());
        }
    };

    function request() {
        return {type: orderConstants.GET_ALL_NEW_DOCUMENTS_REQUEST}
    }

    function success(documentsNew) {
        return {type: orderConstants.GET_ALL_NEW_DOCUMENTS_SUCCESS, documentsNew}
    }

    function failure(error) {
        return {type: orderConstants.GET_ALL_NEW_DOCUMENTS_FAILURE, error}
    }

    function rest() {
        return {type: orderConstants.GET_ALL_NEW_DOCUMENTS_REST}
    }
}


function isPdfExistAction(query) {

    return dispatch => {
        dispatch(request());

        orderService.isPdfExist(query)
            .then(isExist => dispatch(success(isExist)), error => dispatch(failure(error.toString())));

    };

    function request() {
        return {type: orderConstants.IS_PDF_EXIST_REQUEST}
    }

    function success(isExist) {
        return {type: orderConstants.IS_PDF_EXIST_SUCCESS, isExist}
    }

    function failure(error) {
        return {type: orderConstants.IS_PDF_EXIST_FAILURE, error}
    }

}


function getAllFileDataOld(query, isReset = false) {

    return dispatch => {
        if (isReset === false) {
            dispatch(request());

            orderService.getAllDocumentOld(query)
                .then(documents => dispatch(success(documents)), error => dispatch(failure(error.toString())));
        } else {
            dispatch(rest());
        }
    };

    function request() {
        return {type: orderConstants.GET_ALL_OLD_DOCUMENTS_REQUEST}
    }

    function success(documentsOld) {
        return {type: orderConstants.GET_ALL_OLD_DOCUMENTS_SUCCESS, documentsOld}
    }

    function failure(error) {
        return {type: orderConstants.GET_ALL_OLD_DOCUMENTS_FAILURE, error}
    }

    function rest() {
        return {type: orderConstants.GET_ALL_OLD_DOCUMENTS_REST}
    }
}


function getTaxSuitCollectorData(query) {
    return dispatch => {
        dispatch(request());

        orderService.getTaxSuitCollector(query)
            .then(collector => dispatch(success(collector)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_COLLECTOR_LIST_REQUEST}
    }

    function success(collector) {
        return {type: orderConstants.GET_COLLECTOR_LIST_SUCCESS, collector}
    }

    function failure(error) {
        return {type: orderConstants.GET_COLLECTOR_LIST_FAILURE, error}
    }
}

// generateTgx -> Pdf

function generateTgxPdfAction(orderId, customerId, tenant, setDisable, fileType, setActiveTaskConfirmation, checkValidation, cancelExistingTask) {
    return dispatch => {
        dispatch(request());
        orderService.generateTgxPdfService(orderId, customerId, tenant, checkValidation, cancelExistingTask)
            .then(result => {
                if (result['errorCode'] === 200) {
                    setActiveTaskConfirmation(false);
                    showSuccessToast("Pdf created successfully!");
                    dispatch(success(result));
                    setDisable(false);
                    let customFilter = {
                        'orderId': orderId, 'orderFileType': [fileType, "CADPrintout"], 'type': 'New'
                    }
                    dispatch(orderActions.isPdfExistAction(customFilter));
                    dispatch(orderActions.getOrderResultByOrderIdAction(orderId));
                    window.open('/ajax/view-file/' + result['id'], '_blank');
                } else {
                    if (result['message'] === "Can not generate PDF while task is incomplete!") {
                        setActiveTaskConfirmation(true);
                    } else {
                        responseDTOExceptionHandler(result);
                    }
                    setDisable(false);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisable(false);
            });
    };

    function request() {
        return {type: orderConstants.GENERATE_TGX_PDF_REQUEST}
    }

    function success() {
        return {type: orderConstants.GENERATE_TGX_PDF_SUCCESS}
    }

    function failure(error) {
        return {type: orderConstants.GENERATE_TGX_PDF_FAILURE, error}
    }

}


function generateHoaPdfAction(orderId, customerId, tenant, setDisable, fileType, setActiveTaskConfirmation, checkValidation, cancelExistingTask, setShowAccount = null, setIsGeneratedSubmit) {
    return dispatch => {
        dispatch(request());
        orderService.generateHoaPdfService(orderId, customerId, tenant, checkValidation, cancelExistingTask)
            .then(result => {
                if (result['errorCode'] === 200) {
                    setActiveTaskConfirmation(false);
                    showSuccessToast("Pdf created successfully!");
                    dispatch(success(result));
                    let customFilter = {
                        'orderId': orderId, 'orderFileType': [fileType, "CADPrintout"], 'type': 'New'
                    }
                    if (setShowAccount !== null) {
                        setShowAccount(null);
                    }
                    setIsGeneratedSubmit(false);
                    dispatch(orderActions.isPdfExistAction(customFilter));
                    dispatch(orderActions.getHoaOrderResultByOrderIdAction(orderId));

                    for (let i = 0; i < result['orderFileDTOList'].length; i++) {
                        window.open('/ajax/view-file/' + result['orderFileDTOList'][i]['id'], '_blank');
                    }
                } else {
                    if (result['message'] === "Can not generate PDF while task is incomplete!") {
                        setActiveTaskConfirmation(true);
                    } else {
                        responseDTOExceptionHandler(result);
                    }
                    setIsGeneratedSubmit(false);
                    setDisable(false);
                }
                setDisable(false);
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setIsGeneratedSubmit(false);
                setDisable(false);
            });
    };

    function request() {
        return {type: orderConstants.GENERATE_HOA_PDF_REQUEST}
    }

    function success() {
        return {type: orderConstants.GENERATE_HOA_PDF_SUCCESS}
    }

    function failure(error) {
        return {type: orderConstants.GENERATE_HOA_PDF_FAILURE, error}
    }

}


function generateSOAPdfAction(orderId, customerId, tenant, setDisable, tgxId, obj, setShowIframe, setFileName) {
    return dispatch => {
        dispatch(request());
        orderService.generateSOAPdfService(orderId, customerId, tenant, tgxId, obj)
            .then(result => {
                if (result['errorCode'] === 200) {
                    showSuccessToast("Pdf created successfully!");
                    dispatch(success(result));
                    setDisable(false);
                    loadPdf(result['id']);
                    setFileName(result['fileName']);
                    setShowIframe(true);
                } else {
                    responseDTOExceptionHandler(result);
                    setDisable(false);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisable(false);
            });
    };

    function request() {
        return {type: orderConstants.GENERATE_SOA_PDF_REQUEST}
    }

    function success() {
        return {type: orderConstants.GENERATE_SOA_PDF_SUCCESS}
    }

    function failure(error) {
        return {type: orderConstants.GENERATE_SOA_PDF_FAILURE, error}
    }

}


// save order Result
function saveOrderResultAction(obj, orderId, setDisable, setIsSavedResult, setUpdatedAccountIndexList,setRemovedAccountIds) {
    return dispatch => {
        dispatch(request(obj));
        orderService.saveOrderResultService(obj)
            .then(result => {
                if (result['errorCode'] === 200) {
                    showSuccessToast("Order result saved successfully!", "success1");
                    if (result['saveResultsBondStatus']) {
                        if (result['message'] === 'showBondToast') {
                            showSuccessToast("Bond order created Successfully!", "success2", "second_toast_message");
                        }
                    } else {
                        showErrorToast("Unresolved placeholder jurisdictions!", "error2", "second_toast_message");
                    }
                    setIsSavedResult(true);
                    dispatch(success(result));
                    setDisable(false);
                    setUpdatedAccountIndexList([]);
                    setRemovedAccountIds([]);
                    dispatch(orderActions.getOrderResultByOrderIdAction(orderId));
                } else {
                    responseDTOExceptionHandler(result);
                    setDisable(false);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisable(false);
            });
    };

    function request(result) {
        return {type: orderConstants.CREATE_ORDER_RESULT_REQUEST, result}
    }

    function success(result) {
        return {type: orderConstants.CREATE_ORDER_RESULT_SUCCESS, result}
    }

    function failure(error) {
        return {type: orderConstants.CREATE_ORDER_RESULT_FAILURE, error}
    }

}

function refreshRollAction(orderId, setReloadTaxRollButton, setIsSavedResult, setProductStatus) {

    return dispatch => {
        dispatch(request(orderId));
        orderService.refreshRoll(orderId)
            .then(refresh => {
                if (refresh['errorCode'] === 200) {
                    showSuccessToast("Order Result updated successfully!");
                    setIsSavedResult(true);
                    setProductStatus('SAVED');
                    dispatch(success(refresh));
                    setReloadTaxRollButton(false);
                    dispatch(orderActions.getOrderResultByOrderIdAction(orderId));
                } else {
                    responseDTOExceptionHandler(refresh);
                    setReloadTaxRollButton(false);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request() {
        return {type: orderConstants.REFRESH_ROLL_REQUEST}
    }

    function success(refresh) {
        return {type: orderConstants.REFRESH_ROLL_SUCCESS, refresh}
    }

    function failure(error) {
        return {type: orderConstants.REFRESH_ROLL_FAILURE, error}
    }

}

function updateTaxAccountsAction(taxAccounts, orderId, setPropertyResultTaxList, setTaxTaxCheckedList, setReloadTaxButton, productId, orderResultData, setIsValidDataForPdfGenerate, taxYear) {
    return dispatch => {
        dispatch(request(taxAccounts));
        orderService.updateTaxAccountsService(orderId, taxAccounts, productId, taxYear)
            .then(result => {
                if (result['errorCode'] === 200) {
                    showSuccessToast("Started Tax Update Task");
                    dispatch(success(result));
                    setReloadTaxButton(false);
                    dispatch(getProductTaskListAction(productId));
                    dispatch(checkTaxOrderResultValidAction(orderResultData.items, setIsValidDataForPdfGenerate));
                    dispatch(getCertificateOptionsByOrderIdAction(orderId))
                } else {
                    setReloadTaxButton(false);
                    responseDTOExceptionHandler(result);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request(result) {
        return {type: orderConstants.UPDATE_TAX_ACCOUNTS_REQUEST, result}
    }

    function success(result) {
        return {type: orderConstants.UPDATE_TAX_ACCOUNTS_SUCCESS, result}
    }

    function failure(error) {
        return {type: orderConstants.UPDATE_TAX_ACCOUNTS_FAILURE, error}
    }

}

function saveHoaOrderResultAction(obj, orderId, setDisable, setIsSavedResult, setProductStatus, setShowAccount, setUpdatedAccountIndexList) {
    return dispatch => {
        dispatch(request(obj));
        orderService.saveHoaOrderResultService(obj, orderId)
            .then(result => {
                if (result['errorCode'] === 200) {
                    showSuccessToast("Order Result created successfully!");
                    dispatch(success(result));
                    setDisable(false);
                    setIsSavedResult(true);
                    setProductStatus('SAVED');
                    setShowAccount(0);
                    setUpdatedAccountIndexList([]);
                    dispatch(orderActions.getHoaOrderResultByOrderIdAction(orderId));
                } else {
                    responseDTOExceptionHandler(result);
                    setDisable(false);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisable(false);
            });
    };

    function request(result) {
        return {type: orderConstants.CREATE_HOA_ORDER_RESULT_REQUEST, result}
    }

    function success(result) {
        return {type: orderConstants.CREATE_HOA_ORDER_RESULT_SUCCESS, result}
    }

    function failure(error) {
        return {type: orderConstants.CREATE_HOA_ORDER_RESULT_FAILURE, error}
    }

}

export function createMultipleOrderReducer(state = {}, action) {
    switch (action.type) {
        case  orderConstants.CREATE_MULTIPLE_ORDER_REQUEST:
            return {
                loggingIn: true,
            };
        case  orderConstants.CREATE_MULTIPLE_ORDER_SUCCESS:
            return {
                loggedIn: true, items: action.createOrderResponse
            };
        case  orderConstants.CREATE_MULTIPLE_ORDER_FAILURE:
            return {
                errorItems: action.error
            };

        case  orderConstants.CREATE_MULTIPLE_ORDER_RESET:
            return {}
        default:
            return state
    }
}

// submit multiple order action
function createMultipleOrderAction(inputOrderList, setDisable, isReset = false, responseArray = null, setResponseArray = null) {
    return dispatch => {

        if (isReset) {
            dispatch(reset());
        } else {
            dispatch(request());
            orderService.submitMultipleOrderOrderService(inputOrderList, responseArray, setResponseArray)
                .then(createOrderResponse => {
                    if (createOrderResponse['errorCode'] === 200) {
                        dispatch(success(createOrderResponse));
                    } else {
                        responseDTOExceptionHandler(createOrderResponse);
                    }
                    setDisable(false)
                }, error => {
                    setDisable(false)
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                });
        }

    }

    function request() {
        return {type: orderConstants.CREATE_MULTIPLE_ORDER_REQUEST}
    }

    function success(createOrderResponse) {
        return {type: orderConstants.CREATE_MULTIPLE_ORDER_SUCCESS, createOrderResponse}
    }

    function failure(error) {
        return {type: orderConstants.CREATE_MULTIPLE_ORDER_FAILURE, error}
    }

    function reset() {
        return {type: orderConstants.CREATE_MULTIPLE_ORDER_RESET}
    }
}


function findOrderResultHistoryByProductIdAction(params) {
    return dispatch => {
        dispatch(request());
        orderService.findOrderResultHistoryByProductIdService(params)
            .then(orderResultHistory => {
                const modifiedOrderResultHistory = orderResultHistory.data.map(item => {
                    const modifiedItem = {...item};
                    const jsonString = modifiedItem.orderResult;
                    if (jsonString) {
                        try {
                            const jsonObject = JSON.parse(jsonString);

                            const excludedKeys = ["id", "creationDate", "modifiedDate", "accountId", "createdBy", "deleted", "modifiedBy", "tenant", "managementCompanyId", "certificateId", "propertyId", "jurisdictionId", "relationalId", "collectorId", "subdivisionId"];

                            modifiedItem.orderResult = removeCertainKeys(sortOrderResult(jsonObject, true), excludedKeys);

                        } catch (error) {
                            console.error('Error parsing JSON:', error);
                        }
                    }
                    return modifiedItem;
                });

                console.log("modifiedOrderResultHistory---->", modifiedOrderResultHistory);

                dispatch(success({...orderResultHistory, data: modifiedOrderResultHistory}))

            })
            .catch(error => {
                dispatch(failure(error.toString()));
            });
    };

    function request() {
        return {type: orderConstants.GET_ORDER_RESULT_HISTORY_BY_PRODUCT_REQUEST}
    }

    function success(orderResultHistory) {
        return {type: orderConstants.GET_ORDER_RESULT_HISTORY_BY_PRODUCT_SUCCESS, orderResultHistory}
    }

    function failure(error) {
        return {type: orderConstants.GET_ORDER_RESULT_HISTORY_BY_PRODUCT_FAILURE, error}
    }
}


//submit property for order
function submitOrderAction(obj, setDisable) {
    return dispatch => {
        dispatch(request(obj));
        orderService.submitOrder(obj)
            .then(order => {
                if (order['errorCode'] === 200) {
                    showSuccessToast("Order submitted successfully!");
                    dispatch(success(order));
                    history.push("/orders/" + order['id']);
                } else {
                    setDisable(false);
                    responseDTOExceptionHandler(order);
                }
            }, error => {
                setDisable(false);
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    }


    function request(order) {
        return {type: orderConstants.CREATE_ORDER_REQUEST, order}
    }

    function success(order) {
        return {type: orderConstants.CREATE_ORDER_SUCCESS, order}
    }

    function failure(error) {
        return {type: orderConstants.CREATE_ORDER_FAILURE, error}
    }
}

function getOrderByIdAction(orderId, formType, isReset = false) {
    return dispatch => {
        if (isReset === false) {
            dispatch(request());
            orderService.getOrderByIdService(orderId, formType).then(order => dispatch(success(order)), error => dispatch(failure(error.toString())));
        } else {
            dispatch(reset())
        }

    };

    function request() {
        return {type: orderConstants.GET_ORDER_BY_ID_REQUEST}
    }

    function success(order) {
        return {type: orderConstants.GET_ORDER_BY_ID_SUCCESS, order}
    }

    function failure(error) {
        return {type: orderConstants.GET_ORDER_BY_ID_FAILURE, error}
    }

    function reset() {
        return {type: orderConstants.ORDER_BY_ID_RESET}
    }

}


function getOrderLatestPdfAction(orderId, fileType) {
    return dispatch => {
        dispatch(request());

        orderService.getOrderLatestPdfService(orderId, fileType)
            .then(pdf => dispatch(success(pdf)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_ORDER_LATEST_PDF_REQUEST}
    }

    function success(pdf) {
        return {type: orderConstants.GET_ORDER_LATEST_PDF_SUCCESS, pdf}
    }

    function failure(error) {
        return {type: orderConstants.GET_ORDER_LATEST_PDF_FAILURE, error}
    }

}

function getProductQcLogicResultListAction(productId) {
    return dispatch => {
        dispatch(request());

        orderService.getProductQcLogicResultListService(productId)
            .then(list => dispatch(success(list)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_PRODUCT_QC_LOGIC_RESULT_REQUEST}
    }

    function success(list) {
        return {type: orderConstants.GET_PRODUCT_QC_LOGIC_RESULT_SUCCESS, list}
    }

    function failure(error) {
        return {type: orderConstants.GET_PRODUCT_QC_LOGIC_RESULT_FAILURE, error}
    }

}

function getOrderNotesAction(orderId) {
    return dispatch => {
        dispatch(request());

        orderService.getOrderNotesService(orderId)
            .then(notes => dispatch(success(notes)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_ORDER_NOTES_REQUEST}
    }

    function success(notes) {
        return {type: orderConstants.GET_ORDER_NOTES_SUCCESS, notes}
    }

    function failure(error) {
        return {type: orderConstants.GET_ORDER_NOTES_FAILURE, error}
    }

}


function getOrderNotesByTypeAction(orderId, types) {
    return dispatch => {
        dispatch(request());
        orderService.getOrderNotesByTypeService(orderId, types)
            .then(notes => dispatch(success(notes)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_ORDER_NOTES_BY_TYPE_REQUEST}
    }

    function success(notes) {
        return {type: orderConstants.GET_ORDER_NOTES_BY_TYPE_SUCCESS, notes}
    }

    function failure(error) {
        return {type: orderConstants.GET_ORDER_NOTES_BY_TYPE_FAILURE, error}
    }

}


function getAssignmentLogAction(orderId) {
    return dispatch => {
        dispatch(request());
        orderService.getOrderAssignmentLogService(orderId)
            .then(assignmentLogs => dispatch(success(assignmentLogs)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_ORDER_ASSIGNMENT_LOGS_REQUEST}
    }

    function success(assignmentLogs) {
        return {type: orderConstants.GET_ORDER_ASSIGNMENT_LOGS_SUCCESS, assignmentLogs}
    }

    function failure(error) {
        return {type: orderConstants.GET_ORDER_ASSIGNMENT_LOGS_FAILURE, error}
    }

}


function getOrderLogsAction(orderId, logTypes) {
    return dispatch => {
        dispatch(request());
        orderService.getOrderLogsService(orderId, logTypes)
            .then(logs => dispatch(success(logs)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_ORDER_LOGS_REQUEST}
    }

    function success(logs) {
        return {type: orderConstants.GET_ORDER_LOGS_SUCCESS, logs}
    }

    function failure(error) {
        return {type: orderConstants.GET_ORDER_LOGS_FAILURE, error}
    }

}


function getSimilarTaxAccountAction(orderId, propertyIds) {
    return dispatch => {
        dispatch(request());
        orderService.getSimilarTaxAccountInfoService(orderId, propertyIds)
            .then(accounts => dispatch(success(accounts)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_SIMILAR_TAX_ACCOUNTS_LIST_REQUEST}
    }

    function success(accounts) {
        return {type: orderConstants.GET_SIMILAR_TAX_ACCOUNTS_LIST_SUCCESS, accounts}
    }

    function failure(error) {
        return {type: orderConstants.GET_SIMILAR_TAX_ACCOUNTS_LIST_FAILURE, error}
    }

}


function getSimilarHoaAccountAction(orderId, propertyIds) {
    return dispatch => {
        dispatch(request());
        orderService.getSimilarHoaAccountInfoService(orderId, propertyIds)
            .then(accountData => dispatch(success(accountData)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_SIMILAR_HOA_ACCOUNTS_LIST_REQUEST}
    }

    function success(accountData) {
        return {type: orderConstants.GET_SIMILAR_HOA_ACCOUNTS_LIST_SUCCESS, accountData}
    }

    function failure(error) {
        return {type: orderConstants.GET_SIMILAR_HOA_ACCOUNTS_LIST_FAILURE, error}
    }

}

function getOrderListByCustomerIdAction(customerId, customerOrderNumber) {
    return dispatch => {
        dispatch(request());

        orderService.getOrderListByCustomerIdService(customerId, customerOrderNumber)
            .then(orderListByCustomerId => dispatch(success(orderListByCustomerId)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_ORDER_LIST_BY_CUSTOMER_ID_REQUEST}
    }

    function success(orderListByCustomerId) {
        return {type: orderConstants.GET_ORDER_LIST_BY_CUSTOMER_ID_SUCCESS, orderListByCustomerId}
    }

    function failure(error) {
        return {type: orderConstants.GET_ORDER_LIST_BY_CUSTOMER_ID_FAILURE, error}
    }

}

function createOrderNoteAction(orderId, obj, setDisable, setShow, types) {
    return dispatch => {
        dispatch(request(obj));
        orderService.createOrderNoteService(orderId, obj)
            .then(note => {
                if (note['errorCode'] === 200) {
                    showSuccessToast("Note created successfully!");
                    dispatch(success(note));
                    if (types == null) {
                        dispatch(getOrderNotesAction(orderId));
                    } else {
                        dispatch(getOrderNotesByTypeAction(orderId, types));
                    }
                } else {
                    responseDTOExceptionHandler(note);
                }
                setDisable(false);
                setShow(false);
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisable(false);
                setShow(false);
            });
    };

    function request(note) {
        return {type: orderConstants.CREATE_ORDER_NOTE_REQUEST, note}
    }

    function success(note) {
        return {type: orderConstants.CREATE_ORDER_NOTE_SUCCESS, note}
    }

    function failure(error) {
        return {type: orderConstants.CREATE_ORDER_NOTE_FAILURE, error}
    }
}

function updateNoteVisibilityAction(orderId, noteId, setDisable, setShowNoteVisibilityConfirmation, customerOrderNumber) {
    return dispatch => {
        dispatch(request());
        orderService.updateNoteVisibilityService(orderId, noteId)
            .then(note => {
                if (note['errorCode'] === 200) {
                    showSuccessToast("Note updated successfully!");
                    dispatch(success(note));
                    setDisable(false);
                    setShowNoteVisibilityConfirmation(false);
                    dispatch(getOrderNotesAction(orderId, customerOrderNumber));
                } else {
                    responseDTOExceptionHandler(note);
                    setDisable(false);
                    setShowNoteVisibilityConfirmation(false);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                setDisable(false);
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request() {
        return {type: orderConstants.UPDATE_NOTE_VISIBILITY_REQUEST}
    }

    function success(note) {
        return {type: orderConstants.UPDATE_NOTE_VISIBILITY_SUCCESS, note}
    }

    function failure(error) {
        return {type: orderConstants.UPDATE_NOTE_VISIBILITY_FAILURE, error}
    }

}
function updatePropertySkipAutomationAction(orderId, propertyResultId, setDisable, setShowSkipAutomationConfirmation) {
    return dispatch => {
        dispatch(request());
        orderService.updatePropertySkipAutomationService(propertyResultId)
            .then(property => {
                if (property['errorCode'] === 200) {
                    showSuccessToast("Skip automation updated successfully!");
                    dispatch(success(property));
                    setDisable(false);
                    setShowSkipAutomationConfirmation(false);
                    dispatch(orderActions.getOrderByIdAction(orderId, 'OTHER'));
                } else {
                    responseDTOExceptionHandler(property);
                    setDisable(false);
                    setShowSkipAutomationConfirmation(false);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                setDisable(false);
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request() {
        return {type: orderConstants.PROPERTY_SKIP_AUTOMATION_REQUEST}
    }

    function success(property) {
        return {type: orderConstants.PROPERTY_SKIP_AUTOMATION_SUCCESS, property}
    }

    function failure(error) {
        return {type: orderConstants.PROPERTY_SKIP_AUTOMATION_FAILURE, error}
    }

}

function deleteNoteAction(orderId, noteId, setDisable, setShow, type) {
    return dispatch => {
        dispatch(request());
        orderService.deleteNoteService(orderId, noteId)
            .then(note => {
                if (note['errorCode'] === 200) {
                    showSuccessToast("Note deleted successfully!");
                    dispatch(success(note));
                    setDisable(false);
                    setShow(false);
                    dispatch(getOrderNotesByTypeAction(orderId, type));
                } else {
                    responseDTOExceptionHandler(note);
                    setDisable(false);
                    setShow(false);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisable(false);
                setShow(false);
            });
    };

    function request() {
        return {type: orderConstants.DELETE_NOTE_REQUEST}
    }

    function success(note) {
        return {type: orderConstants.DELETE_NOTE_SUCCESS, note}
    }

    function failure(error) {
        return {type: orderConstants.DELETE_NOTE_FAILURE, error}
    }

}

function deleteFileAction(orderId, fileId, setDisable, setShow, setRestore, type, actionType) {
    return dispatch => {
        dispatch(request());
        orderService.deleteFileService(fileId, actionType)
            .then(file => {
                if (file['errorCode'] === 200) {

                    if (actionType === "restore") {
                        showSuccessToast("File restored successfully!");
                    } else {
                        showSuccessToast("File deleted successfully!");
                    }
                    dispatch(success(file));
                    setDisable(false);
                    setShow(false);
                    setRestore(false);
                    dispatch(getOrderDocumentListAction(orderId, type));
                } else {
                    responseDTOExceptionHandler(file);
                    setDisable(false);
                    setShow(false);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisable(false);
                setShow(false);
            });
    };

    function request() {
        return {type: orderConstants.DELETE_FILE_REQUEST}
    }

    function success(file) {
        return {type: orderConstants.DELETE_FILE_SUCCESS, file}
    }

    function failure(error) {
        return {type: orderConstants.DELETE_FILE_FAILURE, error}
    }

}

function startProductFollowUpAction(obj, productId, setShow, orderId, type, setDisable, isOrderGrid) {
    return dispatch => {
        dispatch(request());
        orderService.createProductFollowUpService(productId, obj)
            .then(followUpCreate => {
                if (followUpCreate['errorCode'] === 200) {
                    showSuccessToast(type === "Add" ? "Follow Up started successfully!" : "Follow Up updated successfully!");
                    dispatch(success(followUpCreate));
                    setDisable(false);
                    setShow(false);
                    if (isOrderGrid) {
                        window.location.reload();
                    } else {
                        dispatch(orderActions.getOrderByIdAction(orderId, null, false));
                    }
                } else {
                    responseDTOExceptionHandler(followUpCreate);
                    setDisable(false);
                    setShow(false);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                setDisable(false);
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request() {
        return {type: orderConstants.START_FOLLOW_UP_REQUEST}
    }

    function success(followUpCreate) {
        return {type: orderConstants.START_FOLLOW_UP_SUCCESS, followUpCreate}
    }

    function failure(error) {
        return {type: orderConstants.START_FOLLOW_UP_FAILURE, error}
    }
}

function endProductFollowUpAction(productId, orderId, setShow, isOrderGrid, logTypeList) {
    return dispatch => {
        dispatch(request());
        orderService.endFollowUpService(productId)
            .then(followUpCancel => {
                if (followUpCancel['errorCode'] === 200) {
                    showSuccessToast("Follow Up cancel successfully!");
                    dispatch(success(followUpCancel));
                    setShow(false);
                    if (isOrderGrid) {
                        window.location.reload();
                    } else {
                        dispatch(orderActions.getOrderByIdAction(orderId, null, false));
                        dispatch(orderActions.getOrderLogsAction(orderId, logTypeList));
                    }
                } else {
                    responseDTOExceptionHandler(followUpCancel);
                    setShow(false);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request() {
        return {type: orderConstants.END_FOLLOW_UP_REQUEST}
    }

    function success(followUpCancel) {
        return {type: orderConstants.END_FOLLOW_UP_SUCCESS, followUpCancel}
    }

    function failure(error) {
        return {type: orderConstants.END_FOLLOW_UP_FAILURE, error}
    }
}

function updateProductOverridePriceAction(productId, orderId, overridePrice, setDisable, setShow, overrideDisplayPrice, logTypeList, authorities) {
    return dispatch => {
        dispatch(request());
        orderService.updateProductOverridePriceService(productId, overridePrice, overrideDisplayPrice)
            .then(overridePrice => {
                if (overridePrice['errorCode'] === 200) {
                    showSuccessToast("Product override price updated successfully!");
                    dispatch(success(overridePrice));
                    setDisable(false);
                    setShow(false);
                    dispatch(orderActions.getOrderByIdAction(orderId, null, false));
                    if (isUserHaveAccess(authorities, ["OLOG-R"])) {
                        dispatch(orderActions.getOrderLogsAction(orderId, logTypeList));
                    }
                } else {
                    responseDTOExceptionHandler(overridePrice);
                    setDisable(false);
                    setShow(false);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisable(false);
                setShow(false);
            });
    };

    function request() {
        return {type: orderConstants.UPDATE_PRODUCT_OVERRIDE_PRICE_REQUEST}
    }

    function success(overridePrice) {
        return {type: orderConstants.UPDATE_PRODUCT_OVERRIDE_PRICE_SUCCESS, overridePrice}
    }

    function failure(error) {
        return {type: orderConstants.UPDATE_PRODUCT_OVERRIDE_PRICE_FAILURE, error}
    }
}


function cancelOrderOnLocateAction(orderId, productId, setShow, setDisable, setEditable) {
    return dispatch => {
        dispatch(request());
        orderService.cancelOrderOnLocateService(orderId, productId)
            .then(note => {
                if (note['errorCode'] === 200) {
                    showSuccessToast("Locate request canceled successfully!");
                    dispatch(success(note));
                    setDisable(false);
                    setShow(false);
                    setEditable(true);
                } else {
                    responseDTOExceptionHandler(note);
                    setDisable(false);
                    setShow(false);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisable(false);
                setShow(false);
            });
    };

    function request() {
        return {type: orderConstants.CANCEL_ORDER_ON_LOCATE_REQUEST}
    }

    function success(note) {
        return {type: orderConstants.CANCEL_ORDER_ON_LOCATE_SUCCESS, note}
    }

    function failure(error) {
        return {type: orderConstants.CANCEL_ORDER_ON_LOCATE_FAILURE, error}
    }

}

function acknowledgeQcLogicAction(qcId, productId, setQcId) {
    return dispatch => {
        dispatch(request());
        orderService.acknowledgeQcLogicService(qcId)
            .then(qcResult => {
                if (qcResult['errorCode'] === 200) {
                    showSuccessToast(qcResult['message']);
                    dispatch(success(qcResult));
                    dispatch(getProductQcLogicResultListAction(productId));
                    setQcId("");
                } else {
                    responseDTOExceptionHandler(qcResult);
                    setQcId("");
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setQcId("");
            });
    };

    function request() {
        return {type: orderConstants.ACKNOWLEDGED_QC_LOGIC_REQUEST}
    }

    function success(qcResult) {
        return {type: orderConstants.ACKNOWLEDGED_QC_LOGIC_SUCCESS, qcResult}
    }

    function failure(error) {
        return {type: orderConstants.ACKNOWLEDGED_QC_LOGIC_FAILURE, error}
    }

}

function addParentAccountsForSubdivisionAction(subdivisionId, orderId, orderResults, setDisable, setShow) {
    return dispatch => {
        dispatch(request());
        orderService.addParentAccountsForSubdivisionService(subdivisionId, orderResults)
            .then(result => {
                if (result['errorCode'] === 200) {
                    setDisable(false);
                    showSuccessToast(result['noOfAccountsAdded'] + " new Certificate Accounts has been added!");
                    setShow(false);
                    dispatch(success(result));
                    dispatch(orderActions.getOrderResultByOrderIdAction(orderId));
                } else {
                    setDisable(false);
                    setShow(false);
                    responseDTOExceptionHandler(result);
                }
            }, error => {
                setDisable(false);
                setShow(false);
                showErrorToast(error['message']);
                dispatch(failure(error));
                dispatch(alertActions.error(error.toString()));
            });
    };


    function request() {
        return {type: orderConstants.ADD_PARENT_ACCOUNT_REQUEST}
    }

    function success(result) {
        return {type: orderConstants.ADD_PARENT_ACCOUNT_SUCCESS, result}
    }

    function failure(error) {
        return {type: orderConstants.ADD_PARENT_ACCOUNT_FAILURE, error}
    }

}

function cancelOrderAction(orderId, setDisable, setShow) {
    return dispatch => {
        dispatch(request());
        orderService.cancelOrderService(orderId)
            .then(order => {
                if (order['errorCode'] === 200) {
                    showSuccessToast("Order has been canceled successfully!");
                    dispatch(success(order));
                    dispatch(orderActions.getOrderByIdAction(orderId, null, false))
                } else {
                    responseDTOExceptionHandler(order);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            })
            .finally(() => {
                setDisable(false);
                setShow(false);
            });
    };

    function request() {
        return {type: orderConstants.CANCEL_ORDER_REQUEST}
    }

    function success(order) {
        return {type: orderConstants.CANCEL_ORDER_SUCCESS, order}
    }

    function failure(error) {
        return {type: orderConstants.CANCEL_ORDER_FAILURE, error}
    }
}

function updateOrderStatusAction(orderId, orderStatus, authorities, logTypeList) {
    return dispatch => {
        dispatch(request());
        orderService.updateOrderStatusService(orderId, orderStatus)
            .then(order => {
                if (order['errorCode'] === 200) {
                    showSuccessToast("Order status updated successfully!");
                    dispatch(success(order));
                    if (isUserHaveAccess(authorities, ["OLOG-R"])) {
                        dispatch(orderActions.getOrderLogsAction(orderId, getPrimitiveArray(logTypeList, "value")));
                    }
                } else {
                    responseDTOExceptionHandler(order);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request() {
        return {type: orderConstants.UPDATE_ORDER_STATUS_REQUEST}
    }

    function success(order) {
        return {type: orderConstants.UPDATE_ORDER_STATUS_SUCCESS, order}
    }

    function failure(error) {
        return {type: orderConstants.UPDATE_ORDER_STATUS_FAILURE, error}
    }

}


function updateProductStatusAction(orderId, productId, productStatus, setDisableMark, setProductStatus, isHoa, setShowAccount = null) {
    return dispatch => {
        dispatch(request());
        orderService.updateProductStatus(productId, productStatus)
            .then(order => {
                if (order['errorCode'] === 200) {
                    showSuccessToast("Mark as completed successfully!");
                    dispatch(success(order));
                    setProductStatus("COMPLETED");
                    setDisableMark(false);
                    if (setShowAccount !== null) {
                        setShowAccount(null);
                    }
                    if (isHoa === true) {
                        dispatch(getHoaOrderResultByOrderIdAction(orderId));
                    } else {
                        dispatch(getOrderResultByOrderIdAction(orderId));
                    }
                } else {
                    responseDTOExceptionHandler(order);
                    setDisableMark(false);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisableMark(false);
            });
    };

    function request() {
        return {type: orderConstants.UPDATE_PRODUCT_STATUS_REQUEST}
    }

    function success(order) {
        return {type: orderConstants.UPDATE_PRODUCT_STATUS_SUCCESS, order}
    }

    function failure(error) {
        return {type: orderConstants.UPDATE_PRODUCT_STATUS_FAILURE, error}
    }

}

function getOrderDocumentListAction(orderId, orderFileTypes) {
    return dispatch => {
        dispatch(request());

        orderService.getOrderDocumentListService(orderId, orderFileTypes)
            .then(documents => dispatch(success(documents)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_ORDER_DOCUMENT_LIST_REQUEST}
    }

    function success(documents) {
        return {type: orderConstants.GET_ORDER_DOCUMENT_LIST_SUCCESS, documents}
    }

    function failure(error) {
        return {type: orderConstants.GET_ORDER_DOCUMENT_LIST_FAILURE, error}
    }

}

function getOrderSelectedDocumentListAction(orderId, formType) {
    return dispatch => {
        dispatch(request());

        orderService.getOrderSelectedFilesService(orderId, formType)
            .then(files => dispatch(success(files)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_ORDER_SELECTED_FILES_REQUEST}
    }

    function success(files) {
        return {type: orderConstants.GET_ORDER_SELECTED_FILES_SUCCESS, files}
    }

    function failure(error) {
        return {type: orderConstants.GET_ORDER_SELECTED_FILES_FAILURE, error}
    }

}


function updateDocVisibilityAction(docId, setDisable, setShowDocVisibilityConfirmation, orderId, orderFileTypes) {
    return dispatch => {
        dispatch(request());
        orderService.updateDocVisibilityService(docId)
            .then(doc => {
                if (doc['errorCode'] === 200) {
                    showSuccessToast("Document updated successfully!");
                    dispatch(success(doc));
                    setDisable(false);
                    setShowDocVisibilityConfirmation(false);
                    dispatch(getOrderDocumentListAction(orderId, orderFileTypes));
                } else {
                    responseDTOExceptionHandler(doc);
                    setDisable(false);
                    setShowDocVisibilityConfirmation(false);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request() {
        return {type: orderConstants.UPDATE_DOC_VISIBILITY_REQUEST}
    }

    function success(doc) {
        return {type: orderConstants.UPDATE_DOC_VISIBILITY_SUCCESS, doc}
    }

    function failure(error) {
        return {type: orderConstants.UPDATE_DOC_VISIBILITY_FAILURE, error}
    }

}

function resolveOrderLogAction(orderId, orderLogId, setDisable, setShowResolveVisibilityConfirmation, logTypeList) {
    return dispatch => {
        dispatch(request());
        orderService.updateResolveLogService(orderId, orderLogId)
            .then(doc => {
                if (doc['errorCode'] === 200) {
                    dispatch(success(doc));
                    showSuccessToast("Error is resolved successfully!");
                    setDisable(false);
                    setShowResolveVisibilityConfirmation(false);
                    if (logTypeList !== null) {
                        dispatch(getOrderLogsAction(orderId, logTypeList));
                    } else {
                        dispatch(errorLogActions.getAllErrorLogAction({}));
                    }
                } else {
                    responseDTOExceptionHandler(doc);
                    setDisable(false);
                    setShowResolveVisibilityConfirmation(false);
                }
            }, error => {
                setShowResolveVisibilityConfirmation(false)
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request() {
        return {type: orderConstants.RESOLVE_ORDER_LOG_REQUEST}
    }

    function success(orders) {
        return {type: orderConstants.RESOLVE_ORDER_LOG_SUCCESS, orders}
    }

    function failure(error) {
        return {type: orderConstants.RESOLVE_ORDER_LOG_FAILURE, error}
    }

}

// Get CertificateOptions By OrderId Reducer
export function getCertificateOptionsByOrderIdReducer(state = {}, action) {
    switch (action.type) {
        case  orderConstants.GET_CERTIFICATE_OPTIONS_BY_ORDER_ID_REQUEST:
            return {
                loggedIn: true,
            };
        case  orderConstants.GET_CERTIFICATE_OPTIONS_BY_ORDER_ID_SUCCESS:
            return {
                loggedIn: true, items: action.certificateOptions
            };
        case orderConstants.GET_CERTIFICATE_OPTIONS_BY_ORDER_ID_FAILURE:
            return {};

        default:
            return state
    }

}

// getOrderResult By OrderId
function getOrderResultByOrderIdAction(orderId) {
    return dispatch => {
        dispatch(request());

        orderService.getOrderResultByOrderIdService(orderId)
            .then(order => dispatch(success(order)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_ORDERS_RESULT_REQUEST}
    }

    function success(orders) {
        return {type: orderConstants.GET_ORDERS_RESULT_SUCCESS, orders}
    }

    function failure(error) {
        return {type: orderConstants.GET_ORDERS_RESULT_FAILURE, error}
    }

}


function getHoaOrderResultByOrderIdAction(orderId) {
    return dispatch => {
        dispatch(request());

        orderService.getHoaOrderResultByOrderIdService(orderId)
            .then(order => dispatch(success(order)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_HOA_ORDERS_RESULT_REQUEST}
    }

    function success(order) {
        return {type: orderConstants.GET_HOA_ORDERS_RESULT_SUCCESS, order}
    }

    function failure(error) {
        return {type: orderConstants.GET_HOA_ORDERS_RESULT_FAILURE, error}
    }

}

function uploadMultipleOrderFilesAction(uploadPromises, orderId, setDisable, isReset = false, responseArray = null, setResponseArray = null) {
    return dispatch => {
        if (isReset) {
            dispatch(reset())
        } else {

            dispatch(request());
            orderService.uploadDocumentService(uploadPromises, orderId, responseArray, setResponseArray)
                .then(multiUploadRes => {
                    if (multiUploadRes['errorCode'] === 200) {
                        dispatch(success(multiUploadRes));
                    } else {
                        responseDTOExceptionHandler(multiUploadRes);
                    }
                    setDisable(false);
                }, error => {
                    setDisable(false);
                    showErrorToast(error['message']);
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    setDisable(false);
                });

        }

    };

    function request() {
        return {type: orderConstants.UPLOAD_DOCUMENT_REQUEST}
    }

    function success(multiUploadRes) {
        return {type: orderConstants.UPLOAD_DOCUMENT_SUCCESS, multiUploadRes}
    }

    function failure(error) {
        return {type: orderConstants.UPLOAD_DOCUMENT_FAILURE, error}
    }

    function reset() {
        return {type: orderConstants.UPLOAD_DOCUMENT_RESET}
    }

}


//export order Data
function exportData(query) {
    return dispatch => {
        dispatch(request());
        orderService.exportOrderData(query)
            .then(orderData => dispatch(success(orderData)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.EXPORT_ORDER_DATA_REQUEST}
    }

    function success(orderData) {
        query.setExportBtn(false);
        return {type: orderConstants.EXPORT_ORDER_DATA_SUCCESS, orderData}
    }

    function failure(error) {
        query.setExportBtn(false);
        return {type: orderConstants.EXPORT_ORDER_DATA_FAILURE, error}
    }
}


//PDF delivery action
function deliverPdfAction(orderId, setDisable, setShow, docInstance, deliveryEmail, productId, setProductStatus, customFilterForNew, customFilterForOld, authorities = null, documentTypeList = null, logTypeList = null) {
    return dispatch => {
        dispatch(request());
        orderService.deliverPdf(docInstance, orderId, deliveryEmail, productId)
            .then(delivered => {
                if (delivered['errorCode'] === 200) {
                    showSuccessToast("Document delivered successfully!");
                    dispatch(success(delivered));
                    dispatch(orderActions.getContactEmailAction({}, true));
                    history.push("/orders/" + orderId);

                } else {
                    responseDTOExceptionHandler(delivered);
                    dispatch(orderActions.getAllFileDataNew(customFilterForNew, false));
                    dispatch(orderActions.getAllFileDataOld(customFilterForOld, false));
                }

                if (isUserHaveAccess(authorities, ["FILE-R"])) {
                    dispatch(orderActions.getOrderDocumentListAction(orderId, documentTypeList));
                }

                if (isUserHaveAccess(authorities, ["OLOG-R"])) {
                    dispatch(orderActions.getOrderLogsAction(orderId, logTypeList));
                }
                setShow(false);
                setDisable(false);
            }, error => {
                showErrorToast(error['message']);
                dispatch(orderActions.getAllFileDataNew(customFilterForNew, false));
                dispatch(orderActions.getAllFileDataOld(customFilterForOld, false));
                if (isUserHaveAccess(authorities, ["OLOG-R"])) {
                    dispatch(orderActions.getOrderLogsAction(orderId, logTypeList));
                }
                if (isUserHaveAccess(authorities, ["FILE-R"])) {
                    dispatch(orderActions.getOrderDocumentListAction(orderId, documentTypeList));
                }
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisable(false);
            });
    };

    function request() {
        return {type: orderConstants.PDF_DELIVERED_REQUEST}
    }

    function success(delivered) {
        return {type: orderConstants.PDF_DELIVERED_SUCCESS, delivered}
    }

    function failure(error) {
        return {type: orderConstants.PDF_DELIVERED_FAILURE, error}
    }
}


function sendSOAPdfByEmailAction(orderId, toAddress, filename, ccAddress, mailSubject, setDisable) {
    return dispatch => {
        dispatch(request());
        orderService.sendSOAPdfByEmailService(orderId, toAddress, ccAddress, mailSubject, filename)
            .then(send => {
                if (send['errorCode'] === 200) {
                    showSuccessToast("Document send successfully!");
                    dispatch(success(send));
                    dispatch(orderActions.getContactEmailAction({}, true));
                    history.push("/orders/" + orderId);
                }
                setDisable(false);
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisable(false);
            });
    };

    function request() {
        return {type: orderConstants.SEND_SOA_REQUEST}
    }

    function success(send) {
        return {type: orderConstants.SEND_SOA_SUCCESS, send}
    }

    function failure(error) {
        return {type: orderConstants.SEND_SOA_FAILURE, error}
    }
}
function markAsCustomerRetrievedAction(orderId, setDisable, setShow) {

    return dispatch => {
        dispatch(request());
        orderService.markAsRetrievedService(orderId)
            .then(order => {
                if (order['errorCode'] === 200) {
                    showSuccessToast("Order has been marked as Retrieved!");
                    dispatch(success(order));
                    dispatch(orderActions.getOrderByIdAction(orderId, null, false))
                } else {
                    responseDTOExceptionHandler(order);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            })
            .finally(() => {
                setDisable(false);
                setShow(false);
            });
    };


    function request() {
        return {type: orderConstants.MARK_AS_RETRIEVED_REQUEST}
    }

    function success(orders) {
        return {type: orderConstants.MARK_AS_RETRIEVED_SUCCESS, orders}
    }

    function failure(error) {
        return {type: orderConstants.MARK_AS_RETRIEVED_FAILURE, error}
    }
}

function combineFilesAction(orderId, setDisable, setShow, docInstance, documentTypeList) {
    return dispatch => {
        dispatch(request());
        orderService.combineFiles(docInstance, orderId)
            .then(combine => {
                if (combine['errorCode'] === 200) {
                    showSuccessToast("Documents combined successfully!");
                    dispatch(success(combine));
                    dispatch(orderActions.getOrderDocumentListAction(orderId, documentTypeList));
                } else {
                    responseDTOExceptionHandler(combine);
                }
                setShow(false);
                setDisable(false);
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisable(false);
            });
    };


    function request() {
        return {type: orderConstants.COMBINE_FILES_REQUEST}
    }

    function success(markedFiles) {
        return {type: orderConstants.COMBINE_FILES_SUCCESS, markedFiles}
    }

    function failure(error) {
        return {type: orderConstants.COMBINE_FILES_FAILURE, error}
    }
}

function markedProductFilesAction(productId, fileIds, setDisable) {
    return dispatch => {
        dispatch(request());
        orderService.markedProductFilesService(productId, fileIds)
            .then(markedFiles => {
                if (markedFiles['errorCode'] === 200) {
                    showSuccessToast("Marked document successfully!");
                    dispatch(success(markedFiles));
                } else {
                    responseDTOExceptionHandler(markedFiles);
                }
                window.location.reload();
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisable(false);
            });
    };

    function request() {
        return {type: orderConstants.MARK_PRODUCT_FILES_ORDERS_REQUEST}
    }

    function success(markedFiles) {
        return {type: orderConstants.MARK_PRODUCT_FILES_ORDERS_SUCCESS, markedFiles}
    }

    function failure(error) {
        return {type: orderConstants.MARK_PRODUCT_FILES_ORDERS_FAILURE, error}
    }
}

function updateUnLockedProductsFromCustomerAction(productIds, setDisable) {
    return dispatch => {
        dispatch(request());
        orderService.updateUnLockedProductsFromCustomerService(productIds)
            .then(locked => {
                if (locked['errorCode'] === 200) {
                    showSuccessToast("Locked product to customer successfully!");
                    dispatch(success(locked));
                } else {
                    responseDTOExceptionHandler(locked);
                }
                window.location.reload();
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisable(false);
            });
    };

    function request() {
        return {type: orderConstants.UNLOCKED_FROM_CUSTOMER_REQUEST}
    }

    function success(locked) {
        return {type: orderConstants.UNLOCKED_FROM_CUSTOMER_SUCCESS, locked}
    }

    function failure(error) {
        return {type: orderConstants.UNLOCKED_FROM_CUSTOMER_FAILURE, error}
    }
}

function unMarkedProductFilesAction(productId, setDisable) {
    return dispatch => {
        dispatch(request());
        orderService.unMarkedProductFilesService(productId)
            .then(unMarkedFiles => {
                if (unMarkedFiles['errorCode'] === 200) {
                    showSuccessToast("Unmarked document(s) successfully!");
                    dispatch(success(unMarkedFiles));
                } else {
                    responseDTOExceptionHandler(unMarkedFiles);
                }
                window.location.reload();
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisable(false);
            });
    };

    function request() {
        return {type: orderConstants.UNMARK_PRODUCT_FILES_ORDERS_REQUEST}
    }

    function success(unMarkedFiles) {
        return {type: orderConstants.UNMARK_PRODUCT_FILES_ORDERS_SUCCESS, unMarkedFiles}
    }

    function failure(error) {
        return {type: orderConstants.UNMARK_PRODUCT_FILES_ORDERS_FAILURE, error}
    }
}

function sendOrderToVendorAction(propertyList, productId, setDisable, setShow, setFilteredProperties, orderId) {
    return dispatch => {
        dispatch(request());
        orderService.sendOrderToVendor(propertyList, productId)
            .then(vendorList => {
                if (vendorList['errorCode'] === 200) {
                    showSuccessToast("Send to vendor successfully");
                    dispatch(success(vendorList));
                    setShow(false);
                } else {
                    setShow(false);
                    responseDTOExceptionHandler(vendorList);
                }
                dispatch(orderActions.getProductTaskListAction(productId));
                dispatch(orderActions.getOrderByIdAction(orderId, "Hoa"));
                setFilteredProperties([]);
                setDisable(false);
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                dispatch(orderActions.getProductTaskListAction(productId));
                dispatch(orderActions.getOrderByIdAction(orderId, "Hoa"));
                setFilteredProperties([]);
                setDisable(false);
                setShow(false);
            });
    };

    function request() {
        return {type: orderConstants.SUBMIT_VENDOR_LIST_REQUEST}
    }

    function success(vendorList) {
        return {type: orderConstants.SUBMIT_VENDOR_LIST_SUCCESS, vendorList}
    }

    function failure(error) {
        return {type: orderConstants.SUBMIT_VENDOR_LIST_FAILURE, error}
    }
}

function cancelOrderOnVendorAction(taskId, setDisable, setShow, orderId, type, taskHandleType, setTaskHandleType, productId, orderResultData, setIsValidDataForPdfGenerate) {
    return dispatch => {
        dispatch(request());
        orderService.cancelOrderOnVendor(taskId)
            .then(response => {
                if (response['errorCode'] === 200) {
                    if (taskHandleType === 'REFRESH') {
                        showSuccessToast("Order Refreshed successfully");
                    } else {
                        showSuccessToast("Cancel order on vendor successfully");
                    }

                    dispatch(success(response));
                    setShow(false);
                    setTaskHandleType(null);
                    if (type === "TAX") {
                        dispatch(checkTaxOrderResultValidAction(orderResultData.items, setIsValidDataForPdfGenerate));
                    } else {
                        dispatch(checkHoaOrderResultValidAction(orderResultData.items, orderId, setIsValidDataForPdfGenerate));
                    }
                    dispatch(orderActions.getProductTaskListAction(productId));
                    dispatch(orderActions.getOrderByIdAction(orderId, "Hoa"));
                } else {
                    setShow(false);
                    dispatch(orderActions.getProductTaskListAction(productId));
                    responseDTOExceptionHandler(response);
                }
                setDisable(false);
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisable(false);
            });
    };

    function request() {
        return {type: orderConstants.DELETE_ORDER_ON_VENDOR_REQUEST}
    }

    function success(response) {
        return {type: orderConstants.DELETE_ORDER_ON_VENDOR_SUCCESS, response}
    }

    function failure(error) {
        return {type: orderConstants.DELETE_ORDER_ON_VENDOR_FAILURE, error}
    }
}


function updateVendorPropertyResultAction(obj, orderId, propertyId, taskId, setShow, setDisable, productId) {
    return dispatch => {
        dispatch(request());
        orderService.updateVendorPropertyResultService(obj, orderId, propertyId, taskId)
            .then(order => {
                if (order['errorCode'] === 200) {
                    showSuccessToast("Property result updated successfully!");
                    dispatch(success(order));
                } else {
                    responseDTOExceptionHandler(order);
                }
                dispatch(getProductTaskListAction(productId));
                setShow(false);
                setDisable(false);
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setShow(false);
                setDisable(false);
            });
    };

    function request() {
        return {type: orderConstants.UPDATE_VENDOR_PROPERTY_RESULT_RESPONSE_REQUEST}
    }

    function success(order) {
        return {type: orderConstants.UPDATE_VENDOR_PROPERTY_RESULT_RESPONSE_SUCCESS, order}
    }

    function failure(error) {
        return {type: orderConstants.UPDATE_VENDOR_PROPERTY_RESULT_RESPONSE_FAILURE, error}
    }

}

function getPropertyPickerResultAction(propertyList, orderPropertyId, setDisable, setShow) {
    return dispatch => {
        dispatch(request());
        orderService.getPropertyPickerResultService(propertyList, orderPropertyId)
            .then(resultData => {
                setDisable(false);
                setShow(false);
                if (resultData.length > 0) {
                    showSuccessToast("Account list updated successfully!");
                } else {
                    showSuccessToast("No matching result found!");
                }
                dispatch(success(resultData))
            }, error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_PROPERTY_PICKER_RESULT_REQUEST}
    }

    function success(resultData) {
        return {type: orderConstants.GET_PROPERTY_PICKER_RESULT_SUCCESS, resultData}
    }

    function failure(error) {
        return {type: orderConstants.GET_PROPERTY_PICKER_RESULT_FAILURE, error}
    }
}


function checkTaxOrderResultValidAction(obj, setIsValidDataForPdfGenerate) {
    return dispatch => {
        dispatch(request());
        orderService.checkTaxOrderResultValidService(obj)
            .then(resultData => {
                setIsValidDataForPdfGenerate(resultData);
                dispatch(success(resultData))
            }, error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.CHECK_VALID_TAX_CERT_ORDER_RESULT_REQUEST}
    }

    function success(resultData) {
        return {type: orderConstants.CHECK_VALID_TAX_CERT_ORDER_RESULT_SUCCESS, resultData}
    }

    function failure(error) {
        return {type: orderConstants.CHECK_VALID_TAX_CERT_ORDER_RESULT_FAILURE, error}
    }
}

function checkHoaOrderResultValidAction(obj, orderId, setIsValidDataForPdfGenerate) {
    return dispatch => {
        dispatch(request());
        orderService.checkHoaOrderResultValidService(obj, orderId)
            .then(resultValue => {
                setIsValidDataForPdfGenerate(resultValue);
                dispatch(success(resultValue))
            }, error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.CHECK_VALID_HOA_CERT_ORDER_RESULT_REQUEST}
    }

    function success(resultValue) {
        return {type: orderConstants.CHECK_VALID_HOA_CERT_ORDER_RESULT_SUCCESS, resultValue}
    }

    function failure(error) {
        return {type: orderConstants.CHECK_VALID_HOA_CERT_ORDER_RESULT_FAILURE, error}
    }
}

function checkPrimaryCollectorExistAction(county, state, setModuleAvailableForCounty = null, setPrimaryCollectorId = null, setPrimaryCollector) {
    return dispatch => {
        dispatch(request());
        orderService.checkPrimaryCollectorExistService(county, state)
            .then(resultData => {
                setModuleAvailableForCounty !== null && setModuleAvailableForCounty(resultData != null && resultData.scraperModuleId !== null && resultData.scraperModuleId !== "");
                setPrimaryCollectorId !== null && setPrimaryCollectorId(resultData != null && resultData.id);
                setPrimaryCollector(resultData);
                dispatch(success(resultData))
            }, error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.IS_PRIMARY_COLLECTOR_EXIST_REQUEST}
    }

    function success(resultData) {
        return {type: orderConstants.IS_PRIMARY_COLLECTOR_EXIST_SUCCESS, resultData}
    }

    function failure(error) {
        return {type: orderConstants.IS_PRIMARY_COLLECTOR_EXIST_FAILURE, error}
    }
}


function updateProductsStatusAction(products, orderId, setShow, setDisable, shouldFlushResults = [], logTypeList,authorities) {
    return dispatch => {
        dispatch(request());
        orderService.updateProductsStatusService(products, shouldFlushResults)
            .then(order => {
                if (order['errorCode'] === 200) {
                    showSuccessToast("Products Status are Updated Successfully!");
                    dispatch(success(order));
                    setShow(false);
                    setDisable(false);
                    dispatch(getOrderByIdAction(orderId, "OTHER"));
                    if (isUserHaveAccess(authorities, ["OLOG-R"])) {
                        dispatch(orderActions.getOrderLogsAction(orderId, logTypeList));
                    }
                } else {
                    responseDTOExceptionHandler(order);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request() {
        return {type: orderConstants.UPDATE_PRODUCTS_STATUS_REQUEST}
    }

    function success(order) {
        return {type: orderConstants.UPDATE_PRODUCTS_STATUS_SUCCESS, order}
    }

    function failure(error) {
        return {type: orderConstants.UPDATE_PRODUCTS_STATUS_FAILURE, error}
    }

}


function computeOrderStatusAction(products) {
    return dispatch => {
        dispatch(request());
        orderService.computeOrderStatusService(products)
            .then(orderStatusData => {
                if (orderStatusData != null) {
                    dispatch(success(orderStatusData));
                } else {
                    responseDTOExceptionHandler(orderStatusData);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request() {
        return {type: orderConstants.COMPUTE_ORDER_STATUS_REQUEST}
    }

    function success(orderStatusData) {
        return {type: orderConstants.COMPUTE_ORDER_STATUS_SUCCESS, orderStatusData}
    }

    function failure(error) {
        return {type: orderConstants.COMPUTE_ORDER_STATUS_FAILURE, error}
    }
}

function updateSubdivisionNameAction(subdivision) {
    return dispatch => {
        dispatch(request());
        orderService.updateSubdivisionNameService(subdivision)
            .then(updateSubdivisionNameData => {
                if (updateSubdivisionNameData != null) {
                    dispatch(success(updateSubdivisionNameData));
                } else {
                    responseDTOExceptionHandler(updateSubdivisionNameData);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request() {
        return {type: orderConstants.UPDATE_SUBDIVISION_NAME_REQUEST}
    }

    function success(orderStatusData) {
        return {type: orderConstants.UPDATE_SUBDIVISION_NAME_SUCCESS, orderStatusData}
    }

    function failure(error) {
        return {type: orderConstants.UPDATE_SUBDIVISION_NAME_FAILURE, error}
    }
}

export function populateCadInformationAction(list, countyId, setReloadCadInformation) {
    return dispatch => {
        dispatch(request());
        orderService.getCadInformationService(list, countyId)
            .then(cadData => {
                if (cadData != null) {
                    showSuccessToast("CAD Information Successfully Updated!");
                    setReloadCadInformation(false);
                    dispatch(success(cadData));
                } else {
                    setReloadCadInformation(false);
                    responseDTOExceptionHandler(cadData);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request() {
        return {type: orderConstants.GET_CAD_INFORMATION_RESULT_REQUEST}
    }

    function success(cadData) {
        return {type: orderConstants.GET_CAD_INFORMATION_RESULT_SUCCESS, cadData}
    }

    function failure(error) {
        return {type: orderConstants.GET_CAD_INFORMATION_RESULT_FAILURE, error}
    }

}

function getLinkedAccountsAction(primaryCollectorId, accountNumbers) {
    return dispatch => {
        dispatch(request());

        orderService.linkedAccountsService(primaryCollectorId, accountNumbers)
            .then(linkedAccounts => dispatch(success(linkedAccounts)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_LINKED_ACCOUNTS_REQUEST}
    }

    function success(linkedAccounts) {
        return {type: orderConstants.GET_LINKED_ACCOUNTS_SUCCESS, linkedAccounts}
    }

    function failure(error) {
        return {type: orderConstants.GET_LINKED_ACCOUNTS_FAILURE, error}
    }

}

function createToDoAction(orderId, obj, setDisable, setShow) {
    return dispatch => {
        dispatch(request(obj));
        orderService.createToDoService(obj)
            .then(toDo => {
                if (toDo['errorCode'] === 200) {
                    showSuccessToast("ToDo created successfully!");
                    dispatch(success(toDo));
                    dispatch(getOrderToDoListAction(orderId));
                } else {
                    responseDTOExceptionHandler(toDo);
                }
                setDisable(false);
                setShow(false);
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisable(false);
                setShow(false);
            });
    };

    function request(toDo) {
        return {type: orderConstants.ADD_TODO_ITEM_REQUEST, toDo}
    }

    function success(toDo) {
        return {type: orderConstants.ADD_TODO_ITEM_SUCCESS, toDo}
    }

    function failure(error) {
        return {type: orderConstants.ADD_TODO_ITEM_FAILURE, error}
    }
}

function getOrderToDoListAction(orderId) {
    return dispatch => {
        dispatch(request());

        orderService.getOrderToDoListService(orderId)
            .then(todos => dispatch(success(todos)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_TODOS_BY_ORDER_ID_REQUEST}
    }

    function success(todos) {
        return {type: orderConstants.GET_TODOS_BY_ORDER_ID_SUCCESS, todos}
    }

    function failure(error) {
        return {type: orderConstants.GET_TODOS_BY_ORDER_ID_FAILURE, error}
    }
}
function getCustomerUploadedOrderDocumentListAction(orderId) {
    return dispatch => {
        dispatch(request());

        orderService.getCustomerUploadedOrderDocumentListService(orderId)
            .then(documents => dispatch(success(documents)), error => dispatch(failure(error.toString())));
    };

    function request() {
        return {type: orderConstants.GET_CUSTOMER_UPLOADED_ORDER_DOCUMENT_LIST_REQUEST}
    }

    function success(documents) {
        return {type: orderConstants.GET_CUSTOMER_UPLOADED_ORDER_DOCUMENT_LIST_SUCCESS, documents}
    }

    function failure(error) {
        return {type: orderConstants.GET_CUSTOMER_UPLOADED_ORDER_DOCUMENT_LIST_FAILURE, error}
    }
}

function updateToDoCompletionStatusAction(toDoId,orderId, setDisable) {
    return dispatch => {
        dispatch(request());
        orderService.updateToDoCompletionStatusService(toDoId)
            .then(toDo => {
                if (toDo['errorCode'] === 200) {
                    showSuccessToast("To-Do Status Updated successfully!");
                    dispatch(success(toDo));
                    dispatch(getOrderToDoListAction(orderId));
                    setDisable(false);

                } else {
                    responseDTOExceptionHandler(toDo);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request() {
        return {type: orderConstants.UPDATE_TODO_COMPLETION_STATUS_REQUEST}
    }

    function success(toDo) {
        return {type: orderConstants.UPDATE_TODO_COMPLETION_STATUS_SUCCESS, toDo}
    }

    function failure(error) {
        return {type: orderConstants.UPDATE_TODO_COMPLETION_STATUS_FAILURE, error}
    }
}


function removeToDoAction(orderId, toDoId, setDisable, setShow) {
    return dispatch => {
        dispatch(request());
        orderService.removeToDoService( toDoId)
            .then(toDo => {
                if (toDo['errorCode'] === 200) {
                    showSuccessToast("To-Do removed successfully!");
                    dispatch(success(toDo));
                    setDisable(false);
                    setShow(false);
                    dispatch(getOrderToDoListAction(orderId));
                } else {
                    responseDTOExceptionHandler(toDo);
                    setDisable(false);
                    setShow(false);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisable(false);
                setShow(false);
            });
    };

    function request() {
        return {type: orderConstants.REMOVE_TODO_ITEM_REQUEST}
    }

    function success(toDo) {
        return {type: orderConstants.REMOVE_TODO_ITEM_SUCCESS, toDo}
    }

    function failure(error) {
        return {type: orderConstants.REMOVE_TODO_ITEM_FAILURE, error}
    }
}


function updateToDoAction(toDoId, obj, orderId, setDisable, setShow) {
    return dispatch => {
        dispatch(request());
        orderService.updateToDoService(toDoId, obj)
            .then(toDo => {
                if (toDo['errorCode'] === 200) {
                    showSuccessToast("To-Do updated successfully!");
                    dispatch(success(toDo));
                    setDisable(false);
                    setShow(false);
                    dispatch(getOrderToDoListAction(orderId));
                } else {
                    responseDTOExceptionHandler(toDo);
                    setDisable(false);
                    setShow(false);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisable(false);
                setShow(false);
            });
    };

    function request(toDo) {
        return {type: orderConstants.UPDATE_TODO_ITEM_REQUEST,toDo}
    }

    function success(overridePrice) {
        return {type: orderConstants.UPDATE_TODO_ITEM_SUCCESS, overridePrice}
    }

    function failure(error) {
        return {type: orderConstants.UPDATE_TODO_ITEM_FAILURE, error}
    }
}
function updateCustomerHoldAction(orderId, setDisable, setShow) {
    return dispatch => {
        dispatch(request());
        orderService.updateCustomerHoldService(orderId)
            .then(response => {
                if (response['errorCode'] === 200) {
                    showSuccessToast("Customer Hold updated successfully!");
                    dispatch(success(response));
                    setDisable(false);
                    setShow(false);
                    dispatch(getOrderByIdAction(orderId , "OTHER"));
                } else {
                    responseDTOExceptionHandler(response);
                    setDisable(false);
                    setShow(false);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                setDisable(false);
                setShow(false);
            });
    };

    function request(toDo) {
        return {type: orderConstants.UPDATE_CUSTOMER_HOLD_REQUEST,toDo}
    }

    function success(overridePrice) {
        return {type: orderConstants.UPDATE_CUSTOMER_HOLD_SUCCESS, overridePrice}
    }

    function failure(error) {
        return {type: orderConstants.UPDATE_CUSTOMER_HOLD_FAILURE, error}
    }
}



/******************* Actions ended here. ***********************/


/*********** Reducers started here. ******************/


export function getLinkedAccountsReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_LINKED_ACCOUNTS_REQUEST:
            return {
                loggingIn: true,
            };
        case orderConstants.GET_LINKED_ACCOUNTS_SUCCESS:
            return {
                loggedIn: true, items: action.linkedAccounts
            };
        case orderConstants.GET_LINKED_ACCOUNTS_FAILURE:
            return {
                errorItems: action.error
            };
        case orderConstants.GET_LINKED_ACCOUNTS_RESET:
            return {};
        default:
            return state
    }
}

export function uploadMultipleDocumentOrderReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.UPLOAD_DOCUMENT_REQUEST:
            return {
                loggingIn: true,
            };
        case orderConstants.UPLOAD_DOCUMENT_SUCCESS:
            return {
                loggedIn: true, items: action.multiUploadRes
            };
        case orderConstants.UPLOAD_DOCUMENT_FAILURE:
            return {
                errorItems: action.error
            };
        case orderConstants.UPLOAD_DOCUMENT_RESET:
            return {};
        default:
            return state
    }
}


//get all user reducer
export function getAllOrdersReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_ALL_ORDERS_REQUEST:
            return {
                ...state, loading: true, loggingIn: true,
            };
        case orderConstants.GET_ALL_ORDERS_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.orders
            };
        case orderConstants.GET_ALL_ORDERS_FAILURE:
            return {};
        default:
            return state
    }
}


//get all user reducer
export function getCloneAccountsReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_CLONE_TAX_ACCOUNTS_LIST_REQUEST:
            return {
                ...state, loading: true, loggingIn: true,
            };
        case orderConstants.GET_CLONE_TAX_ACCOUNTS_LIST_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.orders
            };
        case orderConstants.GET_CLONE_TAX_ACCOUNTS_LIST_FAILURE:
            return {};
        default:
            return state
    }
}

export function getProductTaskListReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_PRODUCT_TASK_LIST_REQUEST:
            return {
                ...state, loading: true, loggingIn: true,
            };
        case orderConstants.GET_PRODUCT_TASK_LIST_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.tasks
            };
        case orderConstants.GET_PRODUCT_TASK_LIST_FAILURE:
            return {};
        default:
            return state
    }
}

export function getAllDocumentReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_ALL_DOCUMENTS_REQUEST:
            return {
                loading: true, loggingIn: true,
            };
        case orderConstants.GET_ALL_DOCUMENTS_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.documents
            };
        case orderConstants.GET_ALL_DOCUMENTS_FAILURE:
            return {};
        case orderConstants.GET_ALL_DOCUMENTS_REST:
            return {
                items: {
                    data: []
                }
            };
        default:
            return state
    }
}

export function getAllDocumentForCombineReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_ALL_DOCUMENTS_FOR_COMBINE_REQUEST:
            return {
                loading: true, loggingIn: true,
            };
        case orderConstants.GET_ALL_DOCUMENTS_FOR_COMBINE_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.documents
            };
        case orderConstants.GET_ALL_DOCUMENTS_FOR_COMBINE_FAILURE:
            return {};
        case orderConstants.GET_ALL_DOCUMENTS_FOR_COMBINE_REST:
            return {
                items: {
                    data: []
                }
            };
        default:
            return state
    }
}

export function getAllOrderSelectedDocumentReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_ORDER_SELECTED_FILES_REQUEST:
            return {
                loading: true, loggingIn: true,
            };
        case orderConstants.GET_ORDER_SELECTED_FILES_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.files
            };
        case orderConstants.GET_ORDER_SELECTED_FILES_FAILURE:
            return {};
        default:
            return state
    }
}

export function getAllDocumentReducerNew(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_ALL_NEW_DOCUMENTS_REQUEST:
            return {
                loading: true, loggingIn: true,
            };
        case orderConstants.GET_ALL_NEW_DOCUMENTS_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.documentsNew
            };
        case orderConstants.GET_ALL_NEW_DOCUMENTS_FAILURE:
            return {};
        case orderConstants.GET_ALL_NEW_DOCUMENTS_REST:
            return {
                items: {
                    data: []
                }
            };
        default:
            return state
    }
}

export function getCustomerUploadedDocumentsReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_CUSTOMER_UPLOADED_ORDER_DOCUMENT_LIST_REQUEST:
            return {
                loading: true, loggingIn: true,
            };
        case orderConstants.GET_CUSTOMER_UPLOADED_ORDER_DOCUMENT_LIST_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.documents
            };
        case orderConstants.GET_CUSTOMER_UPLOADED_ORDER_DOCUMENT_LIST_FAILURE:
            return {};
        default:
            return state
    }
}


export function isPdfExistReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.IS_PDF_EXIST_REQUEST:
            return {
                loading: true, loggingIn: true,
            };
        case orderConstants.IS_PDF_EXIST_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.isExist
            };
        case orderConstants.GET_ALL_NEW_DOCUMENTS_FAILURE:
            return {};
        default:
            return state
    }
}

export function getOrderResultHistoryByProductIdReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_ORDER_RESULT_HISTORY_BY_PRODUCT_REQUEST:
            return {
                loading: true, loggingIn: true,
            };

        case orderConstants.GET_ORDER_RESULT_HISTORY_BY_PRODUCT_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.orderResultHistory
            };
        case orderConstants.GET_ORDER_RESULT_HISTORY_BY_PRODUCT_FAILURE:
            return {};

        default:
            return state
    }
}


export function getAllDocumentReducerOld(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_ALL_OLD_DOCUMENTS_REQUEST:
            return {
                ...state, loading: true, loggingIn: true,
            };
        case orderConstants.GET_ALL_OLD_DOCUMENTS_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.documentsOld
            };
        case orderConstants.GET_ALL_OLD_DOCUMENTS_FAILURE:
            return {};
        case orderConstants.GET_ALL_OLD_DOCUMENTS_REST:
            return {
                items: {
                    data: []
                }
            };
        default:
            return state
    }
}

//get all order reducer
export function getOrderByIdReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_ORDER_BY_ID_REQUEST:
            return {};

        case orderConstants.GET_ORDER_BY_ID_SUCCESS:
            return {
                loggedIn: true, items: action.order
            };
        case orderConstants.GET_ORDER_BY_ID_FAILURE:
            return {};
        case orderConstants.ORDER_BY_ID_RESET:
            return {};

        default:
            return state
    }
}

//get order result by orderId
export function getOrderResultByOrderIdReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_ORDERS_RESULT_REQUEST:
            return {
                loggedIn: true,
            };
        case orderConstants.GET_ORDERS_RESULT_SUCCESS:
            return {
                loggedIn: true, items: action.orders
            };
        case orderConstants.GET_ORDERS_RESULT_FAILURE:
            return {};

        default:
            return state
    }
}


export function getHoaOrderResultByOrderIdReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_HOA_ORDERS_RESULT_REQUEST:
            return {};

        case orderConstants.GET_HOA_ORDERS_RESULT_SUCCESS:
            return {
                loggedIn: true, items: action.order
            };
        case orderConstants.GET_HOA_ORDERS_RESULT_FAILURE:
            return {};

        default:
            return state
    }
}

//get all order NOTE reducer
export function getOrderNotesReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_ORDER_NOTES_REQUEST:
            return {};

        case orderConstants.GET_ORDER_NOTES_SUCCESS:
            return {
                loggedIn: true, items: action.notes
            };
        case orderConstants.GET_ORDER_NOTES_FAILURE:
            return {};

        default:
            return state
    }
}


export function getOrderNotesByTypeReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_ORDER_NOTES_BY_TYPE_REQUEST:
            return {};

        case orderConstants.GET_ORDER_NOTES_BY_TYPE_SUCCESS:
            return {
                loggedIn: true, items: action.notes
            };
        case orderConstants.GET_ORDER_NOTES_BY_TYPE_FAILURE:
            return {};

        default:
            return state
    }
}

export function getOrderLogsReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_ORDER_LOGS_REQUEST:
            return {};

        case orderConstants.GET_ORDER_LOGS_SUCCESS:
            return {
                loggedIn: true, items: action.logs
            };
        case orderConstants.GET_ORDER_LOGS_FAILURE:
            return {};

        default:
            return state
    }
}

export function getOrderAssignmentLogsReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_ORDER_ASSIGNMENT_LOGS_REQUEST:
            return {
                items: []
            };

        case orderConstants.GET_ORDER_ASSIGNMENT_LOGS_SUCCESS:
            return {
                loggedIn: true, items: action.assignmentLogs
            };
        case orderConstants.GET_ORDER_ASSIGNMENT_LOGS_FAILURE:
            return {};

        default:
            return state
    }
}

export function getOrderListByCustomerIdReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_ORDER_LIST_BY_CUSTOMER_ID_REQUEST:
            return {};

        case orderConstants.GET_ORDER_LIST_BY_CUSTOMER_ID_SUCCESS:
            return {
                loggedIn: true, items: action.orderListByCustomerId
            };

        case orderConstants.GET_ORDER_LIST_BY_CUSTOMER_ID_FAILURE:
            return {};

        default:
            return state
    }
}

export function getOrderDocumentListReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_ORDER_DOCUMENT_LIST_REQUEST:
            return {};

        case orderConstants.GET_ORDER_DOCUMENT_LIST_SUCCESS:
            return {
                loggedIn: true, items: action.documents
            };
        case orderConstants.GET_ORDER_DOCUMENT_LIST_FAILURE:
            return {};

        default:
            return state
    }
}


export function getTaxSuitCollectorListReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_COLLECTOR_LIST_REQUEST:
            return {
                loading: true,
            };

        case orderConstants.GET_COLLECTOR_LIST_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.collector
            };
        case orderConstants.GET_COLLECTOR_LIST_FAILURE:
            return {};

        default:
            return state
    }
}


export function getSimilarTaxAccountReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_SIMILAR_TAX_ACCOUNTS_LIST_REQUEST:
            return {
                loading: true,
            };

        case orderConstants.GET_SIMILAR_TAX_ACCOUNTS_LIST_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.accounts
            };
        case orderConstants.GET_SIMILAR_TAX_ACCOUNTS_LIST_FAILURE:
            return {};

        default:
            return {}
    }
}

export function getSimilarHoaAccountReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_SIMILAR_HOA_ACCOUNTS_LIST_REQUEST:
            return {
                loading: true,
            };

        case orderConstants.GET_SIMILAR_HOA_ACCOUNTS_LIST_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.accountData
            };
        case orderConstants.GET_SIMILAR_HOA_ACCOUNTS_LIST_FAILURE:
            return {};

        default:
            return {}
    }
}

export function getProductQcLogicResultReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_PRODUCT_QC_LOGIC_RESULT_REQUEST:
            return {
                loading: true,
            };

        case orderConstants.GET_PRODUCT_QC_LOGIC_RESULT_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.list
            };
        case orderConstants.GET_PRODUCT_QC_LOGIC_RESULT_FAILURE:
            return {};

        default:
            return {}
    }
}

export function computeOrderStatusReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.COMPUTE_ORDER_STATUS_REQUEST:
            return {
                loading: true,
            };

        case orderConstants.COMPUTE_ORDER_STATUS_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.orderStatusData
            };
        case orderConstants.COMPUTE_ORDER_STATUS_FAILURE:
            return {};

        default:
            return state
    }
}

export function propertyPickerReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_PROPERTY_PICKER_RESULT_REQUEST:
            return {
                loading: true,
            };

        case orderConstants.GET_PROPERTY_PICKER_RESULT_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.resultData
            };
        case orderConstants.GET_PROPERTY_PICKER_RESULT_FAILURE:
            return {};

        default:
            return state
    }
}

export function getContactEmailReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_CONTACT_EMAIL_REQUEST:
            return {
                loading: true,
            };

        case orderConstants.GET_CONTACT_EMAIL_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.emails
            };
        case orderConstants.GET_CONTACT_EMAIL_FAILURE:
            return {};
        case orderConstants.GET_CONTACT_EMAIL_RESET:
            return {};

        default:
            return state
    }
}

export function populateCadInformationReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_CAD_INFORMATION_RESULT_REQUEST:
            return {
                loading: true,
            };

        case orderConstants.GET_CAD_INFORMATION_RESULT_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.cadData
            };
        case orderConstants.GET_CAD_INFORMATION_RESULT_FAILURE:
            return {};

        default:
            return state
    }
}
export function getOrderToDoListReducer(state = {}, action) {
    switch (action.type) {
        case orderConstants.GET_TODOS_BY_ORDER_ID_REQUEST:
            return {
                loading: true,
            };

        case orderConstants.GET_TODOS_BY_ORDER_ID_SUCCESS:
            return {
                loggedIn: true, loading: false, items: action.todos
            };
        case orderConstants.GET_TODOS_BY_ORDER_ID_FAILURE:
            return {};

        default:
            return state
    }
}


/*********** Reducers ended here. ******************/


/*********** Services started here. ******************/

//get all orders
export async function getAllOrders(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/orders?${paramData}`).then((response) => {
        return exceptionHandler(response);
    })
}

export async function getCloneAccountsOrders(query) {
    let paramData = serialize(query)
    return axios.post(`/editor/cloneAccounts?${paramData}`).then((response) => {
        return exceptionHandler(response);
    })
}

export async function isPdfExist(query) {
    return axios.post(`/ajax/isPdfExist`, query).then((response) => {
        return exceptionHandler(response);
    })
}

export async function getAllPdfDocumentForCombining(query) {
    return axios.post(`/ajax/get-pdf-files-for-combining`, query).then((response) => {
        return exceptionHandler(response);
    })
}

export async function getAllDocument(query) {
    return axios.post(`/ajax/document/search`, query).then((response) => {
        return exceptionHandler(response);
    })
}

export async function getAllDocumentNew(query) {
    return axios.post(`/ajax/get-document-for-delivery`, query).then((response) => {
        return exceptionHandler(response);
    })
}

export async function getAllDocumentOld(query) {
    return axios.post(`/ajax/get-document-for-delivery`, query).then((response) => {
        return exceptionHandler(response);
    })
}

//submit Order
export function submitOrder(obj) {
    return axios.post(`/ajax/submit/order`, obj).then((response) => {
        return response.data;
    })
}


export function getTaxSuitCollector(query) {
    let paramData = serialize(query)
    return axios.post(`/ajax/tax-suit-collectors?${paramData}`).then((response) => {
        return exceptionHandler(response);
    })
}

export function deliverPdf(docList, orderId, deliveryEmail, productId) {
    let paramData = serialize({
        orderId: orderId, deliveryEmail: deliveryEmail, productId: productId,
    });

    return axios.post(`/ajax/pdf-delivered?${paramData}`, docList).then((response) => {
        return exceptionHandler(response);
    })
}

export function sendSOAPdfByEmailService(orderId, toAddress, ccAddress, mailSubject, filename) {
    let paramData = serialize({
        orderId: orderId, toAddress: toAddress, ccAddress: ccAddress, mailSubject: mailSubject, filename: filename
    });

    return axios.post(`/editor/send-soa-attachment?${paramData}`).then((response) => {
        return exceptionHandler(response);
    })
}


export function combineFiles(docList, orderId) {
    let paramData = serialize({
        orderId: orderId
    });

    return axios.post(`/ajax/combine-files?${paramData}`, docList).then((response) => {
        return exceptionHandler(response);
    })
}


export function sendOrderToVendor(propertyIdList, productId) {
    return axios.post(`/ajax/send-to-vendor/${productId}`, propertyIdList).then((response) => {
        return exceptionHandler(response);
    })
}

export function updateTaxAccountsService(orderId, accounts, productId, taxYear) {
    return axios.post(`/editor/submit-tax-update/${orderId}/${productId}?taxYear=${taxYear}`, accounts).then((response) => {
        return exceptionHandler(response);
    });
}


export function cancelOrderOnVendor(taskId) {
    return axios.delete(`/ajax/cancel-order-on-vendor/${taskId}`).then((response) => {
        return exceptionHandler(response);
    })
}

export function getOrderByIdService(orderId, formType) {
    return axios.get(`/ajax/get-order-by-id/${orderId}/${formType}`).then((response) => {
        return exceptionHandler(response);
    })
}

export function saveOrderResultService(obj) {

    return axios.post(`/editor/save-order-result`, obj).then((response) => {
        return response.data;
    })

}

export function saveHoaOrderResultService(obj, orderId) {

    return axios.post(`/editor/save-hoa-order-result/${orderId}`, obj).then((response) => {
        return response.data;
    })

}

export function getCertificateOptionsByOrderIdService(orderId) {
    return axios.get(`/editor/order-results/options/${orderId}`).then((response) => {
        return exceptionHandler(response);
    })

}

export function getOrderResultByOrderIdService(orderId) {

    return axios.get(`/editor/get-order-result-by-order-id/${orderId}`).then((response) => {
        return exceptionHandler(response);
    })

}

export function getHoaOrderResultByOrderIdService(orderId) {

    return axios.get(`/editor/get-order-hoa-result-by-order-id/${orderId}`).then((response) => {
        return exceptionHandler(response);
    })

}

export function getOrderNotesService(orderId) {
    return axios.get(`/ajax/get-order-note-by-order-id/${orderId}`).then((response) => {
        return exceptionHandler(response);
    })
}

export function getOrderNotesByTypeService(orderId, noteTypes) {
    let typesList = {noteTypes: noteTypes};
    let param = serialize(typesList);
    return axios.get(`/ajax/get-order-note-by-order-type/${orderId}?${param}`).then((response) => {
        return exceptionHandler(response);
    })
}

export function getOrderLogsService(orderId, logTypes) {
    let logTypesList = {logTypes: logTypes};
    let param = serialize(logTypesList);
    return axios.get(`/ajax/get-order-logs-by-order-id/${orderId}?${param}`).then((response) => {
        return exceptionHandler(response);
    })
}


export function getSimilarTaxAccountInfoService(orderId, propertyIds) {

    let obj = {
        "orderId": orderId, "propertyIds": propertyIds
    }
    let param = serialize(obj);
    return axios.get(`/editor/default/get-similar-tax-account-info?${param}`).then((response) => {
        return exceptionHandler(response);
    })
}

export function getSimilarHoaAccountInfoService(orderId, propertyIds) {
    let obj = {
        "orderId": orderId, "propertyIds": propertyIds
    }
    let param = serialize(obj);
    return axios.get(`/editor/get-similar-hoa-account-info?${param}`).then((response) => {
        return exceptionHandler(response);
    })
}

export function getOrderAssignmentLogService(orderId) {
    return axios.get(`/ajax/get-order-assignment-logs-by-order-id/${orderId}`).then((response) => {
        return exceptionHandler(response);
    })
}

export function getOrderListByCustomerIdService(customerId, customerOrderNumber) {
    let newCustomerOrderNumber = customerOrderNumber.replace(/#/g, '%23');
    return axios.get(`/ajax/get-order-list/${newCustomerOrderNumber}/${customerId}`).then((response) => {
        return exceptionHandler(response);
    })
}

export function updateResolveLogService(orderId, orderLogId) {
    return axios.put(`/ajax/orders-log-resolve/${orderId}/${orderLogId}`).then((response) => {
        return exceptionHandler(response);
    })

}

export function generateTgxPdfService(orderId, customerId, tenant, checkValidation, cancelExistingTask) {

    return axios.post(`/editor/generate-tgx-pdf/${orderId}/${customerId}/${tenant}/${checkValidation}/${cancelExistingTask}`).then((response) => {
        return exceptionHandler(response);
    })

}

export function generateSOAPdfService(orderId, customerId, tenant, tgxId, obj) {
    let typesList = {customerId: customerId, tenant: tenant, tgxId: tgxId};
    let param = serialize(typesList);

    return axios.post(`/editor/generate-statement-of-account-pdf/${orderId}?${param}`, obj).then((response) => {
        return exceptionHandler(response);
    })

}

export function generateHoaPdfService(orderId, customerId, tenant, checkValidation, cancelExistingTask) {

    return axios.post(`/editor/generate-hoa-pdf/${orderId}/${customerId}/${tenant}/${checkValidation}/${cancelExistingTask}`).then((response) => {
        return exceptionHandler(response);
    })

}

export function createOrderNoteService(orderId, obj) {
    return axios.post(`/ajax/create-order-note/${orderId}/notes`, obj).then((response) => {
        return response.data;
    })
}

export function updateNoteVisibilityService(orderId, noteId) {
    return axios.put(`/ajax/update-note-visibility/${orderId}/${noteId}`).then((response) => {
        return exceptionHandler(response);
    })
}
export function updatePropertySkipAutomationService(propertyResultId) {
    return axios.put(`/ajax/update-property-skip-automation/${propertyResultId}`).then((response) => {
        return exceptionHandler(response);
    })
}


export function deleteNoteService(orderId, noteId) {
    return axios.delete(`/ajax/delete-note/${orderId}/${noteId}`).then((response) => {
        return exceptionHandler(response);
    })
}

export function deleteFileService(fileId, actionType) {
    return axios.delete(`/ajax/delete-file-by-id/${fileId}/${actionType}`).then((response) => {
        return exceptionHandler(response);
    })
}

export function updateDocVisibilityService(docId) {
    return axios.post(`/ajax/update-doc-visibility/${docId}`).then((response) => {
        return exceptionHandler(response);
    })
}

export function updateOrderStatusService(orderId, orderStatus) {
    return axios.put(`/ajax/changeOrderStatus/${orderId}/${orderStatus}`).then((response) => {
        return exceptionHandler(response);
    })
}

export function cancelOrderService(orderId) {
    return axios.delete(`/ajax/cancelOrder/${orderId}`).then((response) => {
        return exceptionHandler(response);
    })
}

export function markIssueOrderService(orderId) {
    return axios.put(`/ajax/changeOrderStatus/${orderId}/ISSUE`).then((response) => {
        return exceptionHandler(response);
    });
}

export function markRushOrderService(orderId) {
    return axios.put(`/ajax/${orderId}/markAsRushOrder`).then((response) => {
        return exceptionHandler(response);
    });
}
export function markAsRetrievedService(orderId) {
    return axios.put(`/ajax/${orderId}/markAsCustomerRetrieved`).then((response) => {
        return exceptionHandler(response);
    });
}


export function getOrderDocumentListService(orderId, orderFileTypes) {
    let typesList = {orderFileTypes: orderFileTypes};
    let param = serialize(typesList);
    return axios.get(`/ajax/get-order-document-list/${orderId}?${param}`).then((response) => {
        return exceptionHandler(response);
    })
}
export function getCustomerUploadedOrderDocumentListService(orderId) {
    return axios.get(`/ajax/customer-uploaded-documents/${orderId}`).then((response) => {
        return exceptionHandler(response);
    })
}

export function getOrderLatestPdfService(orderId, type) {
    return axios.get(`/ajax/latest-pdf/${orderId}/${type}`).then((response) => {
        return exceptionHandler(response);
    })
}


export function getProductQcLogicResultListService(productId) {
    return axios.get(`/editor/qc-logic-result/${productId}`).then((response) => {
        return exceptionHandler(response);
    })
}

export async function uploadDocumentService(uploadFilesDto, orderId, responseArray, setResponseArray) {

    for (let i = 0; i < uploadFilesDto.length; i++) {

        let defaultErrorMessage = {};

        const formData = new FormData();

        formData.append(`file`, uploadFilesDto[i].file);

        delete uploadFilesDto[i].file;

        formData.append(`orderFilesData`, JSON.stringify(uploadFilesDto[i]));

        await axios.post(`/ajax/upload-file/${orderId}`, formData)
            .then((response) => {

                let handledRes = exceptionHandler(response);

                if (handledRes.message === "Success") {

                    setResponseArray(previousState => {
                        return [...previousState, handledRes.multiUploadResponse]
                    });

                } else {

                    defaultErrorMessage.message = handledRes.message;
                    defaultErrorMessage.fileName = uploadFilesDto[i].filename;

                    setResponseArray(previousState => {
                        return [...previousState, defaultErrorMessage]
                    });

                }

            }).catch((error) => {

                defaultErrorMessage.message = error['message'] == null || error['message'] === "" ? "Internal server error!" : error['message'];
                defaultErrorMessage.fileName = uploadFilesDto[i].filename;

                setResponseArray(previousState => {
                    return [...previousState, defaultErrorMessage]
                });

            });
    }

    return {
        errorCode: 200, data: {id: orderId, multiUploadResponse: responseArray}, message: "Success", success: true
    };

}


// submit multiple order service
export async function submitMultipleOrderOrderService(inputOrderList, responseArray, setResponseArray) {

    for (let i = 0; i < inputOrderList.length; i++) {
        let createOrderDefaultErrorResponse = {}

        await axios.post(`/ajax/submit/order`, inputOrderList[i]).then((response) => {

            let handledRes = exceptionHandler(response);

            if (handledRes.success === true) {

                setResponseArray(previousState => {
                    return [...previousState, handledRes]
                });

            } else {

                createOrderDefaultErrorResponse.message = handledRes.message;
                createOrderDefaultErrorResponse.sequenceNumber = inputOrderList[i].sequenceNumber;

                setResponseArray(previousState => {
                    return [...previousState, createOrderDefaultErrorResponse]
                });
            }

        }).catch(error => {
            createOrderDefaultErrorResponse.message = error['message'] == null || error['message'] === "" ? "Internal server error!" : error['message'];
            createOrderDefaultErrorResponse.sequenceNumber = inputOrderList[i].sequenceNumber;

            setResponseArray(previousState => {
                return [...previousState, createOrderDefaultErrorResponse]
            });

        })

    }

    return {
        errorCode: 200, data: responseArray, message: "Success", success: true,
    };
}


export function updateProductStatus(productId, productStatus) {
    return axios.put(`/ajax/update-product-status/${productId}/${productStatus}`).then((response) => {
        return exceptionHandler(response);
    })
}


export function refreshRoll(orderId) {
    return axios.post(`/ajax/refresh-roll/${orderId}`).then((response) => {
        return exceptionHandler(response);
    })
}


//export order Data
export async function exportOrderData(query) {
    let paramData = serialize(query)
    return axios.post(`/export/order?${paramData}`, null, {
        headers: {
            'Content-Disposition': "attachment; filename=template.csv",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }, responseType: 'arraybuffer',
    })
        .then((response) => {
            console.log(response.data.byteLength);
            if (response.data.byteLength === 0) {
                showInfoToast("No data found to export!")
            } else {
                resourceToFile(response.data, "orderData");
            }
        })
        .catch(handleExportError);

}

export function updateVendorPropertyResultService(obj, orderId, propertyId, taskId) {
    return axios.put(`/ajax/update-vendor-property-result/${orderId}/${propertyId}/${taskId}`, obj).then((response) => {
        return exceptionHandler(response);
    })
}


export function updatePropertyResultsService(orderId, propertyId, propertyResult) {
    return axios.put(`/ajax/orders/property/${orderId}/${propertyId}`, propertyResult).then((response) => {
        return exceptionHandler(response);
    })
}

function updatePropertyResultsAction(orderId, propertyId, propertyResult, setShow, setDisable) {
    return dispatch => {
        dispatch(request());
        orderService.updatePropertyResultsService(orderId, propertyId, propertyResult)
            .then(propertyResult => {
                if (propertyResult['errorCode'] === 200) {
                    setShow(false);
                    setDisable(false);
                    showSuccessToast("Property Result Update successfully!");
                    dispatch(orderActions.getOrderByIdAction(orderId, 'OTHER'));
                    dispatch(success(propertyResult));
                } else {
                    setShow(false);
                    setDisable(false);
                    responseDTOExceptionHandler(propertyResult);
                }
            }, error => {
                showErrorToast(error['message']);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request() {
        return {type: orderConstants.UPDATE_PROPERTY_RESULT_REQUEST}
    }

    function success(propertyResult) {
        return {type: orderConstants.UPDATE_PROPERTY_RESULT_SUCCESS, propertyResult}
    }

    function failure(error) {
        return {type: orderConstants.UPDATE_PROPERTY_RESULT_FAILURE, error}
    }

}

/*********** Services ended here. ******************/


export function updateProductsStatusService(products, shouldFlushResults) {
    let paramData = serialize({shouldFlushResults: shouldFlushResults})
    return axios.put(`/ajax/products/updateStatuses?${paramData}`, products).then((response) => {
        return exceptionHandler(response);
    }).catch((error) => {
        console.log(error);
    });
}


export function computeOrderStatusService(products) {
    return axios.put(`/ajax/orders/computeOrderStatus`, products).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });
}

export function updateSubdivisionNameService(subdivision) {
    return axios.put(`/ajax/utils/updateSubdivisionName`, subdivision).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });
}

export function getPropertyPickerResultService(list, orderPropertyId) {
    let paramData = serialize({orderPropertyId: orderPropertyId});
    return axios.post(`/editor/property-picker-data?${paramData}`, list).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });
}

export function getCadInformationService(list, countyId) {
    return axios.post(`/editor/populateCadInfo?countyId=${countyId}`, list).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });
}

export function checkTaxOrderResultValidService(obj) {
    return axios.post(`/editor/default/validateTaxGeneratePdf`, obj).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });
}

export function checkHoaOrderResultValidService(obj, orderId) {
    return axios.post(`/editor/validateHoaGeneratePdf/${orderId}`, obj).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });
}

export function checkPrimaryCollectorExistService(county, stateAbbreviation) {
    let params = serialize({county: county, stateAbbreviation: stateAbbreviation})
    return axios.get(`/editor/primaryCollector?${params}`).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });
}

export function linkedAccountsService(primaryCollectorId, accountIds) {
    let params = serialize({primaryCollectorId: primaryCollectorId, accountIds: accountIds})
    return axios.get(`/editor/linkedAccounts?${params}`).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });
}

export function getProductTaskListsService(productId) {
    return axios.get(`/editor/taskList/${productId}`).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });
}


export function getContactEmail(orderDTO) {
    return axios.post(`/ajax/get-contact-email`, orderDTO).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });
}


export function cancelOrderOnLocateService(orderId, productId) {
    return axios.put(`/ajax/cancelOrderOnLocate/${orderId}/${productId}`).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });
}

export function acknowledgeQcLogicService(qcId) {
    return axios.put(`/editor/acknowledgeQcLogic/${qcId}`).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });
}

export function addParentAccountsForSubdivisionService(subdivisionId, orderResults) {
    return axios.put(`/editor/add-parent-account/${subdivisionId}`, orderResults).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });
}

export function createProductFollowUpService(productId, obj) {
    return axios.put(`/ajax/start-product-follow-up/${productId}`, obj).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });
}

export function parseImportedDataService(importData) {
    let params = serialize({importData: importData})
    return axios.post(`/ajax/parse-imported-data-from-email?${params}`).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });
}

export function endFollowUpService(productId) {
    return axios.put(`/ajax/end-product-follow-up/${productId}`).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });
}

export function updateProductOverridePriceService(productId, overridePrice, overrideDisplayPrice) {
    let params = serialize({overridePrice: overridePrice, overrideDisplayPrice: overrideDisplayPrice});
    return axios.put(`/ajax/update-product-override-price/${productId}?${params}`).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });
}

export function markedProductFilesService(productId, files) {
    return axios.put(`/ajax/marked-product-files/${productId}`, files).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });

}

export function unMarkedProductFilesService(productId) {
    return axios.put(`/ajax/unmarked-product-all-files/${productId}`).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });

}

export function findOrderResultHistoryByProductIdService(params) {
    let paramsData = serialize(params);
    return axios.get(`/ajax/order-result-history?${paramsData}`).then((response) => {
        return exceptionHandler(response);
    })
}

export function getOrderSelectedFilesService(orderId, formType) {
    let params = {
        orderId: orderId, formType: formType
    };
    let paramsData = serialize(params);
    return axios.get(`/ajax/selected-documents?${paramsData}`).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });

}

export function updateUnLockedProductsFromCustomerService(productIds) {
    let params = {
        productIds: productIds
    };
    let paramsData = serialize(params);
    return axios.put(`/ajax/unlock-from-customer?${paramsData}`).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log(error);
    });

}
export function createToDoService(obj) {
    return axios.post(`/ajax/create-todo-task`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function updateToDoService(toDoId, obj) {
    return axios.put(`/ajax/update-todo-task/${toDoId}`, obj)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function updateCustomerHoldService(orderId) {
    return axios.put(`/ajax/${orderId}/customer-hold`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export async function removeToDoService(toDoId) {
    return axios.delete(`/ajax/remove-todo/${toDoId}`)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        })
}

export function getOrderToDoListService(orderId) {
    return axios.get(`/ajax/get-todo-task-list/${orderId}`).then(
        (response) => {
            return exceptionHandler(response);
        })

}

export function updateToDoCompletionStatusService(toDoId) {
    return axios.put(`/ajax/update-todo-completion-status/${toDoId}`).then((response) => {
        return exceptionHandler(response);
    })
}




